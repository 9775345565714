import { FlatList } from "react-native";
import ApplyNowCtaItem from "./ApplyNowCtaItem";
import RefereAFriendCta from "./RefereAFriendCta";

const FlatListWithCta = ({ isAuthenticated, ...rest }) => {
  return (
    <FlatList
      {...rest}
      ListFooterComponent={
        isAuthenticated ? <RefereAFriendCta  headphones_img={false}/> : <ApplyNowCtaItem headphones_img={false} />
      }
    />
  );
};

export default FlatListWithCta;
