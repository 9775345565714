import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SeriesInnerPage from "../pages/Courses/SeriesInnerPage";
import SeriesPage from "../pages/Courses/SeriesPage";
import BackButton from "../components/BackButton";
import TabsHeader from "../components/TabsHeader";

const Stack = createNativeStackNavigator();

export const SeriesPageOptions = ({ navigation }) => ({
  headerStyle: {
    backgroundColor: "red",
  },
  title: "",
  headerBackTitle: () => <></>,
  headerLeft: (props) => (
    <BackButton
      backColor="white"
      navigation={navigation}
      {...props}
      replace={"SeriesPage"}
    />
  ),
});

const SeriesStackNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SeriesPage"
        component={SeriesPage}
        options={{ headerShown: false, title: "Courses - The Podcast Fellowship" }}
      />
      <Stack.Screen
        name="SeriesInnerPage"
        options={{
          headerShown: false,
          title: 'The Podcast Fellowship',
          // headerStyle: {},
          // title: "",
          // headerLeft: (props) => (
          //   <BackButton
          //     {...props}
          //     backLinkTo={"/podcasts/courses"}
          //   />
          // ),
        }}
        component={SeriesInnerPage}
      />
    </Stack.Navigator>
  );
};

export default SeriesStackNavigator;
