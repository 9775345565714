import { Text, View, StyleSheet } from "react-native";

import { Image, Svg, Defs, ClipPath, Path } from "react-native-svg";
const TopicSliderItem = ({ image, title, description }) => {
  return (
    <View style={{ display: "flex", alignItems: "center" }}>
      <View style={styles.img_container}>
        <View style={styles.back_border}></View>
        <Svg viewBox="0 0 290 190" width="290" height="190">
          <Defs>
            <ClipPath id="sliderFirst">
              <Path d="M265.933 170.647C263.7 182.453 253.384 191 241.369 191L25 191C11.1929 191 2.85606e-05 179.807 2.61464e-05 166L1.51917e-06 25.1486C-1.03675e-06 10.5304 12.4879 -0.969204 27.0565 0.233277L266.332 19.9829C281.122 21.2036 291.598 34.9635 288.84 49.5447L265.933 170.647Z"></Path>
            </ClipPath>
          </Defs>
          <Image
            style={styles.img}
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            href={image}
          />
        </Svg>
      </View>
      <Text style={styles.title} numberOfLines={1}>{title}</Text>
      <Text numberOfLines={3} ellipsizeMode="tail" style={styles.description}>
        {description}
      </Text>
    </View>
  );
};

export default TopicSliderItem;

const styles = StyleSheet.create({
  img_container: {
    position: "relative",
    width: 295,
    height: 195,
    display: "flex",
    marginBottom: 25,
  },
  back_border: {
    width: "90%",
    height: "95%",
    borderColor: "#021c3c",
    borderWidth: 1,
    borderRadius: 25,
    position: "absolute",
    left: -8,
    top: 17,
    zIndex: -1,
  },
  img: {
    clipPath: "url(#sliderFirst)",
  },
  title: {
    color: "#021c3c",
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "Poppins_700Bold",
    marginBottom: 10,
  },
  description: {
    fontSize: 15,
    fontWeight: "300",
    fontFamily: "SofiaPro",
    overflow: "hidden",
    lineHeight: 24,
    textAlign: "center",
  },
});
