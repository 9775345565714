import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import TopicInnerPage from "../pages/Topics/TopicsInnerPage";
import TopicsPage from "../pages/Topics/TopicsPage";

const Stack = createNativeStackNavigator();

const TopicsStackNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TopicsPage"
        component={TopicsPage}
        options={{ headerShown: false, title: "Topics - The Podcast Fellowship" }}
      />
      <Stack.Screen
        name="TopicsInnerPage"
        component={TopicInnerPage}
        options={{
          headerShown: false,
          title: 'The Podcast Fellowship'
        }}
      />
    </Stack.Navigator>
  );
};

export default TopicsStackNavigator;
