import { useEffect } from 'react';
import { View, Platform } from 'react-native';
import { useSpeakerAppPage } from "@podcasts/hooks";
import SpeakerBase from "../Speakers/SpeakerBase";
import BackButton from "../../components/BackButton";

export default function SavedSpeakerPage({
  route: {
    params: { slug },
  },
   navigation
}) {
  const hook = useSpeakerAppPage(slug);


  return <SpeakerBase {...hook} slug={hook?.data?.slug}  navigation={navigation}/>;
}
