import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function Library({ size, color = "#000" }) {
  return (
    <View style={[styles.library_box, { marginLeft: 3 }]}>
      <Icon name="library" size={size} color={color} />
      <View style={styles.library_after}>
        <Icon name="library-full" size={size - 3} color="#FCB912" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  library_after: {
    position: "absolute",
    top: 1,
    left: 1,
    zIndex: -1,
  },
});
