import { Platform } from "react-native";

const CustomRenderHtml = (props) => {
  let RHtml;
  if (Platform.OS === "web") {
    RHtml = require("./WebRenderHtml.js").default;
  } else {
    RHtml = require("./NativeRenderHtml.js").default;
  }
  return <RHtml {...props}></RHtml>;
};

export default CustomRenderHtml;
