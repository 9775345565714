import { useCourseAppPage } from "@podcasts/hooks";
import SeriesBase from "../Courses/SeriesBase";

export default function SavedCoursePage({
  route: {
    params: { slug },
  },
  navigation
}) {
    const hook = useCourseAppPage(slug);

  return <SeriesBase {...hook} navigation={navigation} slug={slug} backLinkTo={"/podcasts/library/courses"} />;
}
