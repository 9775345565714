import { StyleSheet, Text, View, Image, Pressable } from "react-native";
import Bookmark from "./icons/Bookmark";
import { PoppinsMediumText } from "./PopinsText";

export default function BookmarkItem({
  fname,
  lname,
  image,
  title,
  podcasts_count,
  onPress,
  onSave,
  saved,
  authenticated,
}) {
  return (
    <Pressable>
      <Pressable style={styles.bookmark_item} onPress={onPress}>
        <View style={styles.info_box}>
          <View>
            <View style={styles.info_box_img}>
              <Image
                source={{ uri: image }}
                style={styles.author_photo}
              ></Image>
            </View>
            <View style={styles.circle_after}></View>
          </View>

          <View style={styles.info_box_text}>
            <Text style={styles.author}>
              {title && `${title} `}{fname} {lname}
            </Text>
            <View style={styles.info_box_count}>
              <Text style={[styles.count, { marginRight: 5 }]}>
                {podcasts_count}
              </Text>
              <Text style={styles.count}>
                podcast{podcasts_count > 1 && "s"}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.bookmark_box}>
          {authenticated && (
            <Bookmark size={20} empty={saved} onPress={onSave} />
          )}
        </View>
      </Pressable>
      <View style={styles.orange_decor}></View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  bookmark_item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 10,
    width: "100%",
    paddingHorizontal: 14,
    paddingVertical: 14,
    backgroundColor: "#fff",
  },
  orange_decor: {
    width: "90%",
    height: 5,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    zIndex: -1,
  },
  info_box: {
    flexDirection: "row",
    alignItems: "center",
  },
  info_box_img: {
    width: 70,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#042B59",
    borderRadius: 35,
    marginRight: 18,
    overflow: "hidden",
  },
  circle_after: {
    width: 70,
    height: 70,
    borderRadius: 35,
    borderColor: "#000",
    borderWidth: 1,
    position: "absolute",
    top: 2,
    left: 3,
    zIndex: -1,
  },
  author: {
    color: "#021C3C",
    marginBottom: 2,
    fontSize: 16,
    lineHeight: 21,
    fontFamily: "Poppins_600SemiBold",
  },
  info_box_count: {
    flexDirection: "row",
  },
  count: {
    color: "#707070",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'SofiaPro'
  },
  author_photo: {
    width: "100%",
    height: "100%",
  },
});
