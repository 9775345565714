import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    SafeAreaView,
    TouchableWithoutFeedback,
    Keyboard,
  } from "react-native";
  import { useCallback } from 'react';
  import { Formik } from "formik";
  import CustomButton from "../components/CustomButton";
  import CustomInput from "../components/CustomInput";
  import { useResetPasswordMutation } from '@podcasts/hooks';
  import { useLinkTo } from "@react-navigation/native";
  import { PoppinsBoldText } from "../components/PopinsText";
  import * as yup from "yup";

  const schema = yup.object().shape({
    password: yup.string().min(8, "Password must be a minimum of 8 characters").required("Required"),
    password_confirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match").required("Please retype your password"),
  });

  export default function ResetPassword(props) {
    const token = props.route.params.token;
    const linkTo = useLinkTo();
    const mutation = useResetPasswordMutation();


    const onSubmit = useCallback(
      async (values) => {
          try {
            const { error, data: { message }} = await mutation.mutateAsync({ ...values, token });

            toast.show('Password set successfully.', {
              placement: "bottom",
              type: "success",
            });
              linkTo(`/login`);

          } catch(e) {
            toast.show('Something went wrong', { placement: "top", type: "danger" });
          }
    
    },[token])

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
        <TouchableWithoutFeedback onPress={() =>{ 
          }} >
          <View style={styles.login}>
            <PoppinsBoldText style={styles.text_welcome}>
             Set your password:
            </PoppinsBoldText>

            <Text style={{ fontFamily: 'SofiaPro'}}>
            Fill out the form below to create your password.</Text>

            <Formik
              initialValues={{ password: "", password_confirmation: ""}}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <View style={{ width: "100%" }}>
                  <View style={styles.form_control}>
                    <CustomInput
                      onChangeText={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                      error={errors.password && touched.password ? errors.password : null}
                      label={"Password *"}
                      autoCapitalize="none"
                      secureTextEntry={true}
                      placeholder={""}
                    />
                  </View>
                  <View style={styles.form_control}>
                    <CustomInput
                      onChangeText={handleChange("password_confirmation")}
                      onBlur={handleBlur("password_confirmation")}
                      value={values.password_confirmation}
                      error={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : null}
                      label={"Confirm Password *"}
                      secureTextEntry={true}
                      autoCapitalize="none"
                      placeholder={""}
                    />
                  </View>
  
                  <View style={styles.login_btn}>
                    <CustomButton
                      name={"Submit"}
                      color="blue"
                      onSubmitEditing={Keyboard.dismiss}
                      onPress={handleSubmit}
                    />
                  </View>
                </View>
              )}
            </Formik>
          </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </SafeAreaView>
    );
  }
  
  const styles = StyleSheet.create({
    login: {
      flex: 1,
      height: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "87%",
      alignSelf: "center",
      paddingBottom: 40,
      marginTop: 50,
      marginBottom: 50,
    },
    text_welcome: {
      fontSize: 22,
      textAlign: "center",
      marginTop: 40,
      marginBottom: 20,
    },
    form_control: {
      marginTop: 15,
      width: "100%",
    },
    login_btn: {
      width: "100%",
      marginTop: 35,
    },
  });
  