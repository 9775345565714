import { StyleSheet, View, Pressable } from "react-native";
import Icon from "./CustomIcon";

export default function PlayRandom() {
  return (
    <View style={styles.play_random}>
      <View style={styles.play_box}>
        <Icon name="play-emty1" size={16} color="#000" />
        <View style={styles.play_after}>
          <Icon name="play-white" size={14} color="#fff" />
        </View>
      </View>
      <View style={styles.random_box}>
        <Icon name="random" size={8} color="#fff" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  play_random: {
    paddingRight: 6,
    paddingBottom: 6,
  },
  play_after: {
    position: "absolute",
    top: 10,
    left: 11,
    zIndex: -1,
  },
  play_box: {
    width: 36,
    height: 36,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#FCB912",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 18,
  },
  random_box: {
    position: "absolute",
    top: 20,
    left: 22,
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#042B59",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
});
