import {
  useImperativeHandle,
  useContext,
  useState,
  useEffect,
  createContext,
  useMemo,
} from "react";
import { View, Keyboard, Platform } from "react-native";
import {Dimensions} from 'react-native';


export const PlayerImpContext = createContext({ current: null });
// this object is needed to mutate
import EmbeddedPlayer from "../../components/EmbeddedPlayer";

export function Player() {
  const refSearchImpApi = useContext(PlayerImpContext);
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);
  const [embeded, setEmbedded] = useState();
  const [type, setType] = useState(null);

  const height = useMemo(() => {
    if (["youtube", "vimeo"].includes(type)) {
      return Platform.OS === 'web' ? 160 : 200;
    }
    if (["apple"].includes(type)) {
      return Platform.OS === 'web' ? 150 : 120;
    }
    if (["audio"].includes(type)) {
      return Platform.OS === 'web' ? 160 : 200;
    }
    return 200;
  }, [type]);

  useImperativeHandle(refSearchImpApi, () => ({
    play: ({ embed_code, type }) => {
      setEmbedded(embed_code);
      setType(type);
    },
    stop: () => {
      setEmbedded(null);
      setType(null);
    },
  }));

  useEffect(() => {
    Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardIsVisible(true);
    });
    Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardIsVisible(false);
    });
  }, []);

 const scale = Platform.OS !== 'web' && type === 'audio' ? '.8' : '.6';

  if (Platform.OS === "ios") {
    return (
      <View
        style={{
          height,
          width: "100%",
          paddingBottom: 20,
          backgroundColor: "#042B59",
          justifyContent: "center",
        }}
      >
        {embeded && (
          <EmbeddedPlayer
            scale={scale}
            __html={embeded}
            width={"90%"}
            height={"100%"}
            style={{
              backgroundColor: "red",
              width: type === 'apple' ?  Dimensions.get('window').width * 1.4 : '100%',
              justifyContent: "center",
              alignSelf: "center",
            }}
          />
        )}
      </View>
    );
  } else {
    return (
      <View
        style={
          keyboardIsVisible
            ? { height: 0 }
            : {
               height,
                width: "100%",
                backgroundColor: "#042B59",
                padding: 5,
                overflow: 'hidden'
              }
        }
      >
        {embeded && (
          <EmbeddedPlayer
           scale={scale}
            __html={embeded}
            width={'90%'}
            height={ Platform.OS === 'web' &&  type === 'audio' ? 200 : "100%"}
            style={{
              backgroundColor: "red",
              width: type === 'apple' ?  Dimensions.get('window').width * 1.4 : '100%',
              justifyContent: "center",
              alignSelf: "center",
            }}
          />
        )}
      </View>
    );
  }
}
