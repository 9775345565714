import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import YourLibraryPage from "../pages/Library/YourLibraryPage";
import SavedPodcastsPage from "../pages/Library/SavedPodcastsPage";
import SavedSpeakersPage from "../pages/Library/SavedSpeakersPage";
import SavedTopicsPage from "../pages/Library/SavedTopicsPage";
import SavedCoursesPage from "../pages/Library/SavedCoursesPage";
import SavedSpeakerPage from '../pages/Library/SavedSpeakerPage';
import SavedTopicPage from '../pages/Library/SavedTopicPage';
import SavedCoursePage from '../pages/Library/SavedCoursePage';
import NotLoggetPage from "../pages/Library/NotLoggetPage";
import RecentlyViewedPage from "../pages/Library/RecentlyViewedPage";
import BackButton from "../components/BackButton";
import { useIsAuthenticated } from "@podcasts/hooks";
import { View, Platform } from 'react-native';
import HeaderRight from "../components/HeaderRight";

const Stack = createNativeStackNavigator();

export const LibPageOptions = ({ navigation }) => ({
  headerBackTitle: () => <></>,
  headerLeft: (props) => <BackButton navigation={navigation} {...props} />,
});

const commonOptions = (backLinkTo, title) => {
  return {
    headerStyle: {
    },
    title: Platform.OS === 'web' ? title : '',
    headerTitleStyle: { display: 'none'},
    headerShadowVisible: false,
    headerBackTitleVisible: false,
    headerRight:() => <View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'web' ? -4 : 0}}><HeaderRight /></View>,
    headerLeft: (props) => <View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0}}><BackButton {...props} backLinkTo={backLinkTo} skipCheck/></View>,
  };
};

const LibraryTabNavigator = ({ navigation }) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Stack.Navigator>
      {isAuthenticated ? (
        <>
          <Stack.Screen
            name="Library"
            component={YourLibraryPage}
            options={{ headerShown: false, title: "Library - The Podcast Fellowship" }}
          />

          <Stack.Screen
            name="SavedPodcastsPage"
            component={SavedPodcastsPage}
            options={commonOptions("/podcasts/library", "Saved Podcasts - The Podcast Fellowship")}
          />
          <Stack.Screen
            name="SavedSpeakersPage"
            component={SavedSpeakersPage}
            options={commonOptions("/podcasts/library", "Saved Speakers - The Podcast Fellowship")}
          />

        <Stack.Screen
            name="SavedSpeakerPage"
            component={SavedSpeakerPage}
            options={commonOptions("/podcasts/library/speakers",'')}
          />


          <Stack.Screen
            name="SavedTopicsPage"
            component={SavedTopicsPage}
            options={commonOptions("/podcasts/library","Saved Topics - The Podcast Fellowship")}
          />

        <Stack.Screen
            name="SavedTopicPage"
            component={SavedTopicPage}
            options={{ 
              headerShown: false
            }}
          />
        <Stack.Screen
            name="SavedCoursePage"
            component={SavedCoursePage}
            options={{ 
              headerShown: false
            }}
          />


          <Stack.Screen
            name="SavedCoursesPage"
            component={SavedCoursesPage}
            options={commonOptions("/podcasts/library")}
          />

          <Stack.Screen
            name="RecentlyViewedPage"
            component={RecentlyViewedPage}
            options={commonOptions("/podcasts/library")}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name="NotLoggetPage"
            options={{ headerShown: false, title: "Library - The Podcast Fellowship" }}
            component={NotLoggetPage}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default LibraryTabNavigator;
