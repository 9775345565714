import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Pressable,
} from "react-native";
import Bookmark from "./icons/Bookmark";
import { LinearGradient } from 'expo-linear-gradient';


export default function TopicsList({
  image = "",
  title = "",
  podcasts_count = 0,
  onPress,
  saved = false,
  onSave,
  authenticated,
  index = 0,
  totalCount = 0
}) {
  return (
    <Pressable style={[styles.cell,
     (index % 2) === 0 ? { marginRight: 4 } :  { marginLeft: 4 },
     index === 1 ? { borderTopRightRadius: 10} : { },
     index === 0 ? { borderTopLeftRadius: 10} : { },
     totalCount === (index + 1) && (index % 2) === 0? { borderBottomLeftRadius: 10} : { },
     totalCount === (index + 1) && (index % 2) === 1? { borderBottomRightRadius: 10} : { },
     totalCount === (index + 2) && (index % 2) === 0? { borderBottomLeftRadius: 10} : { }]} onPress={onPress}>
      <ImageBackground
        source={{ uri: image }}
        resizeMode="cover"
        style={styles.img}
      >
                <View
          style={styles.overlay}>
                      <View style={styles.ico_box}>
            {authenticated && (
              <Bookmark size={20} color="#fff" empty={saved} onPress={onSave} />
            )}
          </View>
        <LinearGradient
          colors={['transparent', 'rgba(2, 28, 60, .5)']} style={styles.gradient}>    
          <View>
            <Text style={styles.name}>{title}</Text>
            <View style={styles.count_box}>
              <Text style={[styles.count_text, { marginRight: 5 }]}>
                {podcasts_count}
              </Text>
              <Text style={styles.count_text}>
                podcast{podcasts_count > 1 && "s"}
              </Text>
            </View>
          </View>
          </LinearGradient>

          </View>
      </ImageBackground>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  cell: {
    flex: 1,
    marginVertical: 4,
    // borderTopRightRadius: 10,
    overflow: "hidden",
    maxWidth: "49%",
  },
  img: {
    width: "100%",
  },
  overlay: {
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    height: 160,
    flexDirection: 'column'
  },
  gradient: {
    padding: 15,
    width: '100%'
  },
  ico_box: {
    flexDirection: "row",
    justifyContent: "flex-end",
    display: "flex",
    padding: 15
  },
  name: {
    color: "#fff",
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 2,
    fontFamily: 'SofiaProMedium'
  },
  count_box: {
    flexDirection: "row",
  },
  count_text: {
    color: "#fff",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: "SofiaPro",
    fontWeight: "400",
  },
});
