import { StyleSheet, View, Pressable, Text } from "react-native";
import Icon from "./icons/CustomIcon";
// somewhere in your app
// import {
//     Menu,
//     MenuOptions,
//     MenuOption,
//     MenuTrigger,
//   } from 'react-native-popup-menu';
import { useState } from "react";

export default function BlueButton({
  size,
  icon,
  text,
  circle = false,
  paddingH = 17,
  paddingV = 8,
  color = "blue",
  onPress = () => {},
}) {
  const [opened, setOpened] = useState(false);
  return (
    <Pressable onPress={onPress}>
      <View
        style={[styles.btn, styles[color], { paddingHorizontal: paddingH, paddingVertical: paddingV, justifyContent: 'space-between' }]}
      >
        <Text
          style={[
            styles.text,
            { color: color === "white" ? "#042B59" : "#fff" },
            { marginRight: !icon ? 0 : 10 },
          ]}
        >
          {text}
        </Text>
        <View style={circle && styles.icon_circle}>
          {icon && (
            <Icon
              name={icon}
              size={size}
              color={color === "white" ? "#042B59" : "#fff"}
            />
          )}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  btn: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 1000,
    borderWidth: 1,
  },
  blue: {
    backgroundColor: "#042B59",
    borderColor: "#fff",
  },
  white: {
    backgroundColor: "#fff",
    borderColor: "#042B59",
  },
  text: {
    fontSize: 12,
    lineHeight: 20,
    color: "#fff",
    fontFamily: "SofiaProMedium",
  },
  icon_circle: {
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#fff",
  },
});
