import { StyleSheet, Text, View, FlatList } from "react-native";
// import BlueButton from '../components/BlueButton/BlueButton';
import TopicsList from "../../components/TopicsList";
import { useLinkTo } from "@react-navigation/native";
import { useSavedCoursesAppPage } from "@podcasts/hooks";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";

export default function SavedCoursesPage() {
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    order,
    toggleOrder,
    onNextPage,
    onSaveCourse,
    isSavedCourse,
    isAuthenticated,
  } = useSavedCoursesAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "title",
  });

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <PageHeaderSort
              title="Saved Courses"
              onPress={() => toggleOrder()}
              order={order}
            />
        </View>
      <View style={styles.items_wrapp}>
        <FlatList
          data={data}
          renderItem={({ item, index }) => (
            <TopicsList
              {...item}
              key={index}
              index={index}
              totalCount={data.length}
              authenticated={isAuthenticated}
              saved={isSavedCourse(item.id)}
              onSave={() => onSaveCourse(item)}
              onPress={() => {
                linkTo(`/podcasts/library/courses/${item.slug}`);
              }}
            />
          )}
          numColumns={2}
          style={{ paddingHorizontal: 20 }}
          onEndReachedThreshold={0}
          onEndReached={onNextPage}
        ></FlatList>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  items_wrapp: {
    flex: 1,
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
  },
  cell: {
    flex: 1,
    margin: 4,
    borderRadius: 10,
    overflow: "hidden",
  },
  img: {
    width: "100%",
  },
  overlay: {
    justifyContent: "space-between",
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    height: 160,
    padding: 15,
  },
  ico_box: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  name: {
    color: "#fff",
    fontSize: 14,
    lineHeight: 19,
    fontWeight: "bold",
    marginBottom: 2,
  },
  count_box: {
    flexDirection: "row",
  },
  count_text: {
    color: "#fff",
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "normal",
  },
});
