import { StyleSheet, Text, View, FlatList } from "react-native";
import * as React from "react";
import { usePaymentsPage } from "@podcasts/hooks";

export default function PaymentsPage() {
  const {
    data: { data },
    total_amount,
    last_request_on,
    onNextPage,
  } = usePaymentsPage({ per_page: 10 });

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <Text style={styles.page_title}>Payments</Text>
        <View style={styles.total_row}>
          <Text style={styles.total_text}>Total to date:</Text>
          <Text style={styles.total_summ}>${total_amount || 0}</Text>
        </View>
        {/* <BlueButton
          icon="payment-dark"
          size={16}
          text="Request a payment"
          paddingH={11}
        /> */}
        <View style={styles.date_row}>
          <Text style={styles.date}>Last request on</Text>
          <Text style={[styles.date, { marginLeft: 5 }]}>
            {last_request_on}
          </Text>
        </View>
      </View>
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <View key={index} style={styles.payment_item}>
            <Text style={styles.payment_date}>Requested: {item.created_at}</Text>
            <Text style={styles.payment_summ}>${item.amount}</Text>
            <Text style={styles.payment_name}>{item.podcast?.title}</Text>
            <Text style={styles.payment_learn}>Learned On: {item.date_learned}</Text>
          </View>
        )}
        onEndReachedThreshold={0.8}
        onEndReached={onNextPage}
      ></FlatList>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    width: "100%",
    flex: 1,
    alignSelf: "center",
  },
  page_header: {
    paddingTop: 30,
    paddingBottom: 25,
    alignItems: "center",
    borderBottomColor: "#D8D8D8",
    borderBottomWidth: 1,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 8,
    fontFamily: 'Poppins_700Bold'
  },
  total_row: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  total_summ: {
    fontFamily: 'Poppins_700Bold',
    marginLeft: 5,
    fontSize: 13,
    lineHeight: 24,
  },
  total_text: {
    fontSize: 13,
    lineHeight: 24,
    fontFamily: 'SofiaPro'
  },
  date_row: {
    flexDirection: "row",
    alignItems: "center",
    // marginTop: 5,
  },
  date: {
    fontFamily: "Poppins_300Light_Italic",
    fontSize: 13,
    color: '#000000',
    lineHeight: 24,
  },
  payment_item: {
    paddingHorizontal: 20,
    paddingVertical: 25,
    borderBottomColor: "#D8D8D8",
    borderBottomWidth: 1,
  },
  payment_date: {
    fontSize: 12,
    lineHeight: 12,
    marginBottom: 10,
    fontFamily: 'SofiaPro'
  },
  payment_summ: {
    color: "#042B59",
    fontFamily: "SofiaProBold",
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 15,
  },
  payment_name: {
    color: "#021C3C",
    fontSize: 14,
    lineHeight: 21,
    fontFamily: "SofiaProMedium",
  },
  payment_learn: {
    fontSize: 12,
    lineHeight: 12,
    marginTop: 10,
    fontFamily: 'SofiaPro'
  },
});
