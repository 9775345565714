import { StyleSheet, Text, View } from "react-native";
import RadioGroup from "react-native-radio-buttons-group";
import { find } from "lodash";

export default function CustomRadioButton({
  label,
  value,
  options,
  error,
  onChange,
  layout = "row",
}) {
  const test = options.map((d) => ({ ...d, selected: d.value === value }));
  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <RadioGroup
        layout={layout}
        radioButtons={test}
        onPress={(data) => {
          onChange(find(data, { selected: true })?.value);
        }}
      />
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
  },
});
