import React from 'react';
import { Mixpanel } from 'mixpanel-react-native';

const MixpanelContext = React.createContext();

export const useMixpanel = () => React.useContext(MixpanelContext);

export const Native = ({children}) => {
  const [mixpanel, setMixpanel] = React.useState(null);
  React.useEffect(() => {

    const trackAutomaticEvents = true;
    const mixpanelInstance = new Mixpanel(process.env.MIXPANEL_TOKEN, trackAutomaticEvents); 
          mixpanelInstance.setLoggingEnabled(true);

           mixpanelInstance.init();
    setMixpanel(mixpanelInstance);
  }, []);

  return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
};