import { useCourseAppPage } from "@podcasts/hooks";
import SeriesBase from './SeriesBase';
import { useLayoutEffect } from "react";

export default function SeriesInnerPage({
  route: {
    params: { slug },
  },
  navigation
}) {
  const hook = useCourseAppPage(slug);


  return (<SeriesBase {...hook} slug={slug} navigation={navigation}/>);
}
