import { View, Platform } from "react-native";
import { WebView } from "react-native-webview";
import moment from "moment";
const CALENDLY_URL="https://calendly.com/podcastfellow/interview"
const CALENDLY_32_PLUS_URL="https://calendly.com/dhaas-1-1-learning/15min?month=2023-07"
export default function Calendly({ dob }) {
  var born = moment(dob);
  var today = moment(new Date());

  const is32Plus = today.diff(born, 'years') >= 32;
  const src = is32Plus ? CALENDLY_32_PLUS_URL : CALENDLY_URL;

    if (Platform.OS === "web") {
    return (
      <View style={{ width: "100%" }}>
        <iframe src={src} frameBorder="0" height="820px" scrolling="no" />
      </View>
    );
  } else {
    return (
      <WebView
        javaScriptEnabled={true}
        style={{ height: 600, width: "100%" }}
        source={{ uri: src }}
      />
    );
  }
}
