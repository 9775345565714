import {
    StyleSheet,
    ScrollView,
    ImageBackground,
    SafeAreaView,
    View,
    Platform
  } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Header } from "@react-navigation/elements";
import HeaderRight from "../components/HeaderRight";
import Iframe  from '../components/Iframe';

  export default function DonatePage2() {
    const insets = useSafeAreaInsets();
    const src = "https://podcastfellowship.kindful.com/embeds/00d0f2b3-2fb6-4719-9960-2253640f37e7?p=https:&amp;embedded=true&amp;full_form=true";

    return (
      <ScrollView  style={{ flex: 1 }}>
       <ImageBackground source={require("../assets/donate_image.png")} resizeMode="cover" style={[styles.page_header, { paddingTop: insets.top }]}>
            <Header
            headerStyle={{ height: 60, backgroundColor: "rgba(0, 0, 0, 0)" }}
            layout={{ height: 100, width: 400 }}
            headerRight={() => <View style={{ marginRight: 20}}><HeaderRight   search={false} /></View>}
          />
        </ImageBackground>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, zIndex: 55, position: 'relative' }}>
         <Iframe src={src} style={{ height: 600, border: 'none', marginTop: Platform.OS === 'web' ? -40 : 0}}/>
        </View>
      </View>
      </ScrollView>
    );
  }
  
  const styles = StyleSheet.create({
    page_header: {
      height: 275,  
      position: 'relative',
      zIndex: -1 
    },
    topic_bg: {
      width: "100%",
      height: "100%",
    }
  });
  
  