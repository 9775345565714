import {
    StyleSheet,
    Text,
    View,
    Pressable,
  } from "react-native";
  import { ChevronButton } from "./ChevronButton";
  import PodcastListItem from "./PodcastListItem";
  import { useLinkTo } from "@react-navigation/native";
  import { PoppinsBoldText } from "../components/PopinsText";
  
  export default function FeaturedItem({
    podcasts,
    title,
    podcastsIds = [],
    onSavePodcast,
    authenticated,
    onViewAllPress,
    onBannerPress,
    children,
    subTitle
  }) {
    const linkTo = useLinkTo();
  
    return (
      <Pressable style={styles.container} onPress={onBannerPress}>
        <View style={styles.title_box}>
          <PoppinsBoldText style={styles.page_title}>{title}</PoppinsBoldText>
          <Pressable style={styles.view_all} onPress={onViewAllPress}>
            <Text style={styles.view_all_text}>View all</Text>
            <ChevronButton
              size={30}
              iconSize={14}
              direction="right"
              style={{ marginLeft: 10 }}
              onPress={onViewAllPress}
            />
          </Pressable>
        </View>
  
        <View style={styles.topic_header}>
            { children }
        </View>
  
        <View>
          <View style={styles.items_wrapp}>
            {podcasts.map((item, i) => (
              <PodcastListItem
                index={i}
                subTitle={subTitle}
                paddingHorizontal={35}
                key={item.id}
                onPress={() => {
                  linkTo(`/podcasts/${item.slug}`);
                }}
                onSave={() => onSavePodcast(item)}
                isAuthenticated={authenticated}
                saved={podcastsIds.includes(item.id)}
                item={item}
              />
            ))}
          </View>
        </View>
      </Pressable>
    );
  }
  
  const styles = StyleSheet.create({
    container: {},
    items_wrapp: {
    },
    page_title: {
      color: "#021C3C",
      fontSize: 16,
      lineHeight: 27,
    },
    title_box: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: 20,
      marginTop: 35,
      marginBottom: 10,
    },
    orange_decor: {
      width: "94%",
      height: 20,
      backgroundColor: "#F9BF34",
      alignSelf: "center",
      borderRadius: 10,
      position: "absolute",
      left: "3%",
      bottom: -4,
      zIndex: -1,
    },
    topic_header_img: {
      flexGrow: 1,
      height: 100,
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "grey",
    },
    topic_header: {
      paddingHorizontal: 20,
      marginBottom: 9,
    },
    topic_header_text: {
      fontSize: 16,
      lineHeight: 21,
      color: "#fff",
      fontFamily: "Poppins_600SemiBold",
    },
    view_all: {
      flexDirection: "row",
      alignItems: "center",
    },
    view_all_text: {
      color: "#042B59",
      fontWeight: "300",
      fontSize: 14,
      lineHeight: 14,
      fontFamily: "SofiaPro",
    },
    gradient: { 
      height: 65, 
      width: '100%',
      position: 'absolute', 
      left: 0, 
      bottom: 0,
      zIndex: 2,
      paddingHorizontal: 15,
      paddingTop: 20,
      paddingBottom: 15,
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between" }
  });
  