import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Platform,
  Button,
  Pressable,
} from "react-native";
import Icon from "./icons/CustomIcon";
import CircleDoneBold from "./icons/CircleDoneBold";
import moment from "moment";
import CustomRenderHtml from "../components/CustomRenderHtml";

export default function NoteItem({
  onShowActionSheet,
  is_sent,
  note,
  updated_at,
  active,
  onPress
}) {

  return (
    <Pressable style={[styles.note, styles.new_note]} onPress={onPress}>
      <View style={styles.note_header}>
        <View style={styles.time_wrapp}>
          <Text style={styles.note_time}>{moment(updated_at).fromNow()}</Text>
          {is_sent === 1 && <CircleDoneBold />}
        </View>
        <TouchableOpacity
          style={styles.action_menu}
          onPress={onShowActionSheet}
        >
          <Icon name="dots-btn-h" size={4} color="#C4C4C4" />
        </TouchableOpacity>
      </View>
      <View
        style={[
          styles.note_body,
          { backgroundColor: active ? "#FFF8E8" : "#183B64" },
        ]}
      >
        <CustomRenderHtml
          html={note}
          width={100}
          color={active ? "black" : "#fff"}
        />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  note: {
    marginBottom: 18,
  },
  note_header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  time_wrapp: {
    flexDirection: "row",
    alignItems: "center",
  },
  note_time: {
    color: "#CCCCCC",
    fontSize: 12,
    lineHeight: 18,
    fontFamily: "SofiaPro",
    marginRight: 7,
  },
  note_body: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderRadius: 5,
  },
  note_text: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: "normal",
  },
  new_note: {
    width: "100%",
    alignSelf: "flex-end",
  },
  action_menu: {
    height: 18,
    justifyContent: "center",
  },
});
