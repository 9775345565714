import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function Series({
  size,
  color = "#000",
  fullColor = "#FCB912",
}) {
  return (
    <View style={styles.series_box}>
      <Icon name="series" size={size} color={color} />
      <View style={[styles.series_dot, styles.dot_top]}>
        <Icon name="series-dot" size={size - 17} color={fullColor} />
      </View>
      <View style={[styles.series_dot, styles.dot_mid]}>
        <Icon name="series-dot" size={size - 17} color={fullColor} />
      </View>
      <View style={[styles.series_dot, styles.dot_bot]}>
        <Icon name="series-dot" size={size - 17} color={fullColor} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  series_dot: {
    position: "absolute",
    left: -3,
  },
  dot_top: {
    top: "-4%",
  },
  dot_mid: {
    top: "40%",
  },
  dot_bot: {
    top: "83%",
  },
});
