import { Platform, View } from "react-native";

const TextEditor = (props) => {
  let _TextEditor;
  if (Platform.OS === "web") {
    _TextEditor = require("./WebTextEditor.js").default;
  } else {
    _TextEditor = require("./NativeTextEditor.js").default;
  }
  return (
    <View>
      <_TextEditor {...props} />
    </View>
  );
};
export default TextEditor;
