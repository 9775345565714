import {
  StyleSheet,
  Text,
  View,
  Pressable,
  SafeAreaView,
  FlatList
} from "react-native";
import MenuItem from "../../components/MenuItem";
import RecentlyViewed from "../../components/RecentlyViewed";
import TabsHeader from "../../components/TabsHeader";
import { PoppinsBoldText } from "../../components/PopinsText";
import { useRecentlyViewedApp } from "@podcasts/hooks";
import { dashboardSelector } from '@podcasts/store/dashboard'
import { useSelector } from "react-redux";
import { useLinkTo } from "@react-navigation/native";

const savedPodcastItem =   {
  icon: "star-empty-dark",
  sizeIcon: 19,
  name: "Saved podcasts",
  page: "SavedPodcastsPage",
};

const savedTopicsItem =   {
  icon: "folder-light",
  sizeIcon: 19,
  name: "Saved topics",
  page: "SavedTopicsPage",
};

const savedSpeakersItem =   {
  icon: "user-light",
  sizeIcon: 20,
  name: "Saved speakers",
  page: "SavedSpeakersPage",
};


const savedCoursesItem =   {
  icon: "series-dark",
  sizeIcon: 17,
  name: "Saved courses",
  page: "SavedCoursesPage",
}


export default function YourLibraryPage({ navigation }) {
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    onSavePodcast,
    isSavedPodcast,
  } = useRecentlyViewedApp();

  const { saved_podcasts_ids,saved_speakers_ids, saved_topics_ids, saved_courses_ids, } = useSelector(dashboardSelector);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <View style={styles.container}>
        <View style={styles.title_box}>
          <PoppinsBoldText style={styles.page_title}>
            My Library
          </PoppinsBoldText>
        </View>
          <MenuItem
            onPress={() => {
              navigation.push(savedPodcastItem.page);
            }}
            key={savedPodcastItem.page}
            {...savedPodcastItem}
            count={saved_podcasts_ids.length}
          />
            <MenuItem
            onPress={() => {
              navigation.push(savedTopicsItem.page);
            }}
            fontFamily='SofiaProMedium'
            key={savedTopicsItem.page}
            {...savedTopicsItem}
            count={saved_topics_ids.length}
          />

          <MenuItem
            onPress={() => {
              navigation.push(savedSpeakersItem.page);
            }}
            fontFamily='SofiaProMedium'
            key={savedSpeakersItem.page}
            {...savedSpeakersItem}
            count={saved_speakers_ids.length}
          />

          <MenuItem
            onPress={() => {
              navigation.push(savedCoursesItem.page);
            }}
            fontFamily='SofiaProMedium'
            key={savedCoursesItem.page}
            {...savedCoursesItem}
            count={saved_courses_ids.length}
          />
      </View>
      <View style={styles.wrapper}>
          <View style={styles.title_box_child}>
            <PoppinsBoldText style={styles.page_title}>
              Recently Viewed
            </PoppinsBoldText>
            <Pressable
              onPress={() => {
                navigation.push("RecentlyViewedPage");
              }}
            >
              <Text style={styles.all_btn}>View all</Text>
            </Pressable>
          </View>
          <FlatList 
            data={data}
            style={{ paddingHorizontal: 20}}
            renderItem={({ item, index }) => {
              return  <RecentlyViewed
              key={index}
              {...item}
              onPress={() => {
                linkTo(`/podcasts/${item.slug}`);
              }}
              saved={isSavedPodcast(item.podcast_id)}
              onSave={() => {
                onSavePodcast({...item, id: item.podcast_id });
              }}
            />
            }}
          />
        </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingHorizontal: 20,
    paddingTop: 15,
  },
  title_box: {
    marginBottom: 20,
  },
  wrapper: {
    flex: 1,
  },
  items_wrapp: {
    paddingBottom: 60,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
  },
  title_box_child: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20
  },
  all_btn: {
    color: "#042B59",
    fontSize: 14,
    lineHeight: 14,
    fontFamily: 'SofiaProMedium'
  },
});
