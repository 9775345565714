import { Pressable, StyleSheet, Text, View, Touchable } from "react-native";
import RadioGroup from "react-native-radio-buttons-group";

export default function CustomCheckBox({
  label,
  value,
  error,
  onChange,
  layout = "row",
  bg = "rgb(68, 68, 68)",
  labelColor = 'white',
  style
}) {

  return (
    <View style={[styles.custominput, style]}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-start'}}>
            <Pressable  
             style={{     
                borderColor: bg,
                borderWidth: 2,
                width: 24,
                height: 24,
                borderRadius: 12,
                justifyContent: "center",
                alignItems: "center"
            }}
             onPress={(data) => {
              onChange(!value);
             }}>
                { value && <View style={{ width: 12, height: 12, borderRadius: 12, backgroundColor: bg }}/>}
            </Pressable>
            {label && <Text style={[styles.text_name, { color: labelColor }]}>{label}</Text>}

        </View>

      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },
  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
    marginLeft: 10,
    flexShrink: 1  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
  },
});
