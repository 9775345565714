import { Text, View, StyleSheet, Image } from "react-native";

const FeedBackItem = ({ name, role, description, img }) => {
  return (
    <View style={styles.container}>
      <View style={styles.user_img_container}>
        <Image
          source={img}
          style={styles.user_img}
        />
        <Image
          source={require("../assets/slider-home-bg.svg")}
          style={styles.user_container_back_img}
        />
      </View>
      <Text style={styles.name}>{name}</Text>
      <Text style={styles.role}>{role}</Text>
      <Text style={styles.description}>{description}</Text>
    </View>
  );
};

export default FeedBackItem;

const styles = StyleSheet.create({
  container: { alignItems: "center" },
  name: {
    textAlign: "center",
    fontSize: 20,
    fontFamily: 'Poppins_600SemiBold',
    color: "#021c3c",
    marginBottom: 10,
  },
  role: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: 14,
    marginBottom: 25,
    fontFamily: 'SofiaPro'
  },
  description: {
    width: "100%",
    textAlign: "center",
    fontFamily: 'SofiaPro',
    fontSize: 20,
    lineHeight: 30,
    marginBottom: 35,
    fontSize: 15,
    lineHeight: 24,
    maxWidth: 310,
  },
  user_img_container: {
        position: "relative",
        border: "1px solid #021c3c",
        width: 70,
        height: 70,
        borderRadius: 70 / 2,
        zIndex: -1,
        marginBottom: 20,
  },
  user_img: {
    position: "absolute",
    top: -3,
    right: 3.5,
    width: 70,
    height: 70,
    borderRadius: 70 / 2,
    zIndex: 2,
  },
  user_container_back_img: {
    position: "absolute",
    top: -10,
    left: -24,
    width: 115,
    height: 100,
    zIndex: -1,
  }
});
