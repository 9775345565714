import { StyleSheet, View, Text, Pressable } from "react-native";
import CustomInput from "./CustomInput";
import { Formik } from "formik";
import Icon from "./icons/CustomIcon";
import { referFriendSchema } from "@podcasts/schemas";
import CustomButton from "../components/CustomButton";
import { FieldArray } from "formik";

const initialValue = {
  data: [
    {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
    },
  ],
};

function ReferFormItem({ onSubmit }) {
  return (
    <Formik
      enableReinitialize
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={true}
      initialValues={initialValue}
      validationSchema={referFriendSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        const friendsData = values.data;

        return (
          <>
            <FieldArray
              name="data"
              render={({ push, remove }) => (
                <>
                  {friendsData.map((friend, index) => (
                    <View style={{ paddingBottom: 20 }} key={index}>
                      <CustomInput
                        label="First Name *"
                        onChangeText={handleChange(
                          `data[${index}]['first_name']`
                        )}
                        onBlur={handleBlur(`data[${index}]['first_name']`)}
                        value={values["data"][index]["first_name"]}
                        error={
                          errors["data"] &&
                          errors["data"][index] &&
                          errors["data"][index]["first_name"] &&
                          touched["data"] &&
                          touched["data"][index] &&
                          touched["data"][index]["first_name"]
                            ? errors["data"][index]["first_name"]
                            : null
                        }
                        placeholder={""}
                      />
                      <CustomInput
                        label="Last Name *"
                        onChangeText={handleChange(
                          `data[${index}]['last_name']`
                        )}
                        onBlur={handleBlur(`data[${index}]['last_name']`)}
                        value={values["data"][index].last_name}
                        error={
                          errors["data"] &&
                          errors["data"][index] &&
                          errors["data"][index]["last_name"] &&
                          touched["data"] &&
                          touched["data"][index] &&
                          touched["data"][index]["last_name"]
                            ? errors["data"][index]["last_name"]
                            : null
                        }
                        placeholder={""}
                      />
                      <CustomInput
                        label="Email *"
                        onChangeText={handleChange(`data[${index}]['email']`)}
                        onBlur={handleBlur(`data[${index}]['email']`)}
                        value={values["data"][index]["email"]}
                        error={
                          errors["data"] &&
                          errors["data"][index] &&
                          errors["data"][index]["email"] &&
                          touched["data"] &&
                          touched["data"][index] &&
                          touched["data"][index]["email"]
                            ? errors["data"][index]["email"]
                            : null
                        }
                        placeholder={""}
                      />
                      <CustomInput
                        label="Phone number *"
                        onChangeText={handleChange(`data[${index}]['phone']`)}
                        onBlur={handleBlur(`data[${index}]['phone']`)}
                        value={values["data"][index]["phone"]}
                        error={
                          errors["data"] &&
                          errors["data"][index] &&
                          errors["data"][index]["phone"] &&
                          touched["data"] &&
                          touched["data"][index] &&
                          touched["data"][index]["phone"]
                            ? errors["data"][index]["phone"]
                            : null
                        }
                        placeholder={""}
                      />
                      {!(index < friendsData.length - 1) ? (
                        <Pressable
                          style={styles.add_box}
                          onPress={() => {
                            push({
                              first_name: "",
                              last_name: "",
                              email: "",
                              phone: "",
                            });
                          }}
                        >
                          <View style={styles.plus_ico}>
                            <Icon name="add-plus" size={14} color="#000" />
                          </View>
                          <Text style={styles.add_text}>
                            Add another friend
                          </Text>
                        </Pressable>
                      ) : (
                        <Pressable
                          style={styles.add_box}
                          onPress={() => remove(index)}
                        >
                          <View style={styles.plus_ico}>
                            <View
                              style={{
                                height: 1,
                                width: 14,
                                backgroundColor: "#021c3c",
                              }}
                            ></View>
                          </View>
                          <Text style={styles.add_text}>Remove friend</Text>
                        </Pressable>
                      )}
                    </View>
                  ))}
                </>
              )}
            />
            <CustomButton
              name={"Submit"}
              color="blue"
              customIcon="save"
              onPress={handleSubmit}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default ReferFormItem;

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    width: "100%",
    flex: 1,
    alignSelf: "center",
  },
  page_wrapp: {
    flex: 1,
    paddingHorizontal: 20,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 8,
    fontWeight: "bold",
  },
  page_header: {
    paddingTop: 30,
    paddingBottom: 25,
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#000",
    fontSize: 15,
    lineHeight: 25,
    fontWeight: "normal",
    paddingHorizontal: 10,
  },
  form_control: {
    marginBottom: 15,
  },
  add_box: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  plus_ico: {
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 15,
  },
  add_text: {
    color: "#021C3C",
    fontSize: 15,
    lineHeight: 15,
    fontFamily: "SofiaProMedium",
  },
});
