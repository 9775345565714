import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "./styles.css";
import { View, Text, StyleSheet, Pressable} from "react-native";
import { useCallback, createRef } from "react";

/*
 * Custom toolbar component including insertStar button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1" />
      <option value="2" />
      <option selected />
    </select>
    <button className="ql-bold" >
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none" stroke="white">
      <path  stroke="white" d="M8.36198 6.62812C8.85677 6.01836 9.16667 5.23906 9.16667 4.375C9.16667 2.4448 7.67135 0.875 5.83333 0.875L0.833333 0.875273C0.373177 0.875273 0 1.26656 0 1.75027C0 2.23398 0.373177 2.62527 0.833333 2.62527H1.25V11.3753H0.833333C0.373177 11.3753 0 11.7666 0 12.2503C0 12.734 0.373177 13.1253 0.833333 13.1253H6.66667C8.50469 13.1253 10 11.5555 10 9.62527C10 8.34805 9.33854 7.24062 8.36198 6.62812ZM2.91667 2.62527H5.83333C6.7526 2.62527 7.5 3.41059 7.5 4.37527C7.5 5.33996 6.7526 6.12527 5.83333 6.12527H2.91667V2.62527ZM6.66667 11.375H2.91667V7.875H6.66667C7.58594 7.875 8.33333 8.66004 8.33333 9.60039C8.33333 10.5407 7.58594 11.375 6.66667 11.375Z" fill="white"/>
      </svg>
      </button>
    <button className="ql-italic" >
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
    <path d="M10.2857 0.857143C10.2857 1.33098 9.90241 1.71429 9.42857 1.71429H7.85706L4.28652 10.2857H6C6.47384 10.2857 6.85715 10.669 6.85715 11.1429C6.85715 11.6167 6.47384 12 6 12H0.857143C0.383304 12 0 11.6167 0 11.1429C0 10.669 0.383304 10.2857 0.857143 10.2857H2.42866L5.9992 1.71429H4.28572C3.81188 1.71429 3.42857 1.33098 3.42857 0.857143C3.42857 0.383304 3.81188 0 4.28572 0H9.42857C9.90268 0 10.2857 0.383572 10.2857 0.857143Z" fill="white"/>
    </svg>
    </button>
    <span class="ql-formats">
      <button class="ql-list" value="ordered" type="button">
        <svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path class="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path class="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path class="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg>
        </button>
        <button class="ql-list" value="bullet" type="button"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
     </span>
  </div>
);

/* 
 * Editor component with custom toolbar and content containers
 */
const  MyEditor = ({ editNoteState, onEdit, onSend}) =>   {
  const _editor = createRef();
  const [initialHtml, setInitialHtml] = useState("");
  const [editHtml, setEditHtml] = useState(null);

  const [html, setHtml] = useState("");
  const [canSend, setCanSend] = useState(false);

   useEffect(() => {
    if(editNoteState.value) {
      setEditHtml(editNoteState.value)
    }
  },[editNoteState])


  const onButtonClick = useCallback(() => {
    if(editNoteState.value) {
      onEdit(editHtml)
      setEditHtml(null)
    } else {
      onSend({ html: html, reset: () => { }})
      setHtml(null)
    }
  },[html, editHtml,editNoteState])

  const onHtmlChange = useCallback((value) => {
    if(editNoteState.value) {
      setEditHtml(value)
    } else {
      setHtml(value)
    }
  },[editHtml,editNoteState])

  // handleChange(html) {
  //   this.setState({ editorHtml: html });
  // }

    return (
      <div className="text-editor">
        <ReactQuill
        ref={_editor}
         value={ editHtml || html}
          onChange={(html) => {
            console.log(html,'=`+++')
             onHtmlChange(html);
            // if (html.replace(/(<([^>]+)>)/gi, "")) {
            //   setCanSend(true);
            // } else {
            //   setCanSend(false);
            // }
           }}
          placeholder={"Write down your thoughts..."}
          modules={MyEditor.modules}
          formats={MyEditor.formats}
          theme={"snow"} 
        />
        <View 
         style={{
          paddingHorizontal: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
       <CustomToolbar />
       { (editHtml || html) && <Pressable style={styles.button} onPress={onButtonClick}>
            <Text style={styles.text}>{ editHtml ? "Edit" : "Save"}</Text>
          </Pressable>}
      </View>   
      </div>
    );
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
MyEditor.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  }
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
MyEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];




export default MyEditor;


const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: "#FFFFFF",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 1000,
    marginRight: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: "white",
    fontFamily: "SofiaProMedium",

  }});
