import { StyleSheet, Pressable } from "react-native";
import * as React from "react";
import Icon from "./icons/CustomIcon";
import { size } from "lodash";

export const ChevronButton = ({
  color,
  onPress,
  direction = "left",
  size = 36,
  style = {},
  iconSize = 12,
}) => {
  return (
    <Pressable
      style={[
        styles.back_btn,
        styles[color],
        { width: size, height: size, borderRadius: size / 2 },
        style,
      ]}
      onPress={onPress}
    >
      <Icon
        name={`chevron-${direction}-dark`}
        size={iconSize}
        color={color === "white" ? "#fff" : "#000"}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  back_btn: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
  },
  white: {
    borderColor: "#fff",
    color: "#fff",
  },
  black: {
    borderColor: "#000",
    color: "#000",
  },
  title_text: {
    flex: 1,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: 26,
    fontWeight: "bold",
  },
});
