import { StyleSheet, ScrollView, Text, View, Pressable } from "react-native";
import * as React from "react";
import ReferFormItem from "../../components/ReferFormItem";
import { useCallback, useState } from "react";
import { refereAfriend } from "@podcasts/hooks";
import Icon from "../../components/svg/icon";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as Linking from 'expo-linking';
import * as Clipboard from 'expo-clipboard';
import { userSelector } from "@podcasts/store/user";
import { useDispatch, useSelector } from "react-redux";


const socialSharingLinks = () => {
    const podcastBaseUrl = `https://podcastfellowship.org`;
    const linkedinBaseUrl = `https://www.linkedin.com/sharing/share-offsite/?url=`;
    const facebookBaseUrl = `https://www.facebook.com/sharer/sharer.php?u=`;
    const linkedinPodcast = (slug) => `${linkedinBaseUrl}${podcastBaseUrl}/podcasts/${slug}`;
    const facebookPodcast = (slug) => `${facebookBaseUrl}${podcastBaseUrl}/podcasts/${slug}`;
    const podcastTwitter = (title, slug) => `https://twitter.com/intent/tweet?text=Check%20out%20this%20podcast%20from%20the%20Podcast%20Fellowship!%20${title}&url=${podcastBaseUrl}/podcasts/${slug}`
    const PFlinkedin = `${linkedinBaseUrl}${podcastBaseUrl}`;
    const PFfacebook = `${facebookBaseUrl}${podcastBaseUrl}`

    return { podcastBaseUrl, linkedinPodcast, facebookPodcast, podcastTwitter, PFfacebook, PFlinkedin };  
}

const initialValue = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
};

const SuccessComponent = () => (
  <View style={styles.page_header}>
    <Text style={styles.page_title}>Awesome job!</Text>
    <Text style={styles.subtitle}>
     You've just referred a friend to Podcast Fellowship. When your friend completes their first learning session, you'll receive $15 as a thank you. Keep spreading the joy of Jewish learning!
    </Text>
  </View>
);

export default function ReferFriendPage() {
  const [success, setSuccess] = useState(false);
  const refereAfriendMutation = refereAfriend();
  const { PFfacebook, PFlinkedin, podcastBaseUrl} = socialSharingLinks()
  const { user } = useSelector(userSelector);

  const onMutate = useCallback(async ({ data }) => {
    try {
      await refereAfriendMutation.mutateAsync(data);
      setSuccess(true);
    } catch (e) {
      toast.show(e.response.data.message, { placement: "top", type: "danger" });
    }
  });

  const copyToClipboard =  useCallback(async () => {
    await Clipboard.setStringAsync(`${podcastBaseUrl}/register?referral=${user.referral_token}`);
    toast.show('Link Copied', { placement: "top", type: "success" });
  },[user]);


  return (
    <View style={styles.container}>
      {success ? (
        <SuccessComponent />
      ) : (
        <KeyboardAwareScrollView  extraScrollHeight={20} style={styles.page_wrapp}>
          <View style={styles.page_header}>
            <Text style={styles.page_title}>Refer a friend:</Text>
            <Text style={styles.subtitle}>
              Recieve a $15 referral bonus for every friend that joins the
              program!
            </Text>
            <Pressable style={{ marginTop: 30 }} onPress={copyToClipboard}>
            <Text style={{ textDecorationLine: 'underline', fontFamily: "Poppins_600SemiBold" }}>Copy your custom referral link</Text>
            </Pressable>
            <Text style={{ marginTop: 15, fontFamily: "Poppins_400Regular" }}>Or fill in their contact information and we will email them with your custom referral link and information about the program</Text>
          </View>
          <ScrollView>
            <ReferFormItem initialValue={initialValue} onSubmit={onMutate} />
            <View>
              <Text style={styles.social_text}>Share on socials:</Text>
              <View style={styles.social_icons}>
                <Pressable style={styles.social_icon} onPress={() => {
                  Linking.openURL(PFfacebook);
                }}>
                  <Icon name="Facebook" />
                </Pressable>
                <View style={styles.social_icon}>
                  <Icon name="Instagram" />
                </View>
                <Pressable style={styles.social_icon} onPress={() => {
                   Linking.openURL(PFlinkedin);
                }}>
                  <Icon name="Linkedin" />
                </Pressable>
                <View style={styles.social_icon}>
                  <Icon name="Twitter" />
                </View>
              </View>
            </View>
          </ScrollView>
        </KeyboardAwareScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    width: "100%",
    flex: 1,
    alignSelf: "center",
  },
  page_wrapp: {
    flex: 1,
    paddingHorizontal: 20,
  },
  social_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  social_icon: {
    padding: 10,
  },
  social_text: {
    textAlign: "center",
    fontWeight: "500",
    color: "#042B59",
    marginTop: 30,
    fontSize: 18,
    marginBottom: 11,
    fontFamily: 'SofiaProMedium'
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 8,
    fontFamily: "Poppins_700Bold",
  },
  page_header: {
    paddingTop: 30,
    paddingBottom: 25,
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    color: "#000",
    fontSize: 15,
    lineHeight: 25,
    fontFamily: "SofiaPro",
    paddingHorizontal: 10,
  },
  form_control: {
    marginBottom: 15,
  },
  add_box: {
    marginTop: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  plus_ico: {
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 15,
  },
  add_text: {
    color: "#021C3C",
    fontSize: 15,
    lineHeight: 15,
    fontWeight: "bold",
  },
});
