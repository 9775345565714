import { StyleSheet, View, Pressable } from "react-native";
import Icon from "./CustomIcon";

export default function Star({ size, color = "#000", full = true, onPress }) {
  return (
    <Pressable onPress={onPress}>
      <View style={styles.star_box}>
        <Icon name="star-empty-dark" size={size} color={color} />
        {full && (
          <View style={styles.star_after}>
            <Icon name="star-full" size={size - 1} color="#FCB912" />
          </View>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  star_after: {
    width: "90%",
    position: "absolute",
    top: "4%",
    left: "4%",
    zIndex: -1,
  },
});
