import { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Pressable,
  Text,
  KeyboardAvoidingView,
  Platform,
  StatusBar
} from "react-native";
import NoteItem from "../../components/NoteItem";
import { useDispatch } from "react-redux";
import BackButton from "../../components/BackButton";
import { addNote } from "@podcasts/store/dashboard";
import { useCallback, useRef } from "react";
import TextEditor from "../../components/TextEditor";
import { useActionSheet } from "@expo/react-native-action-sheet";
import {
  usePodcastNotes,
  useDeleteNote,
  useSendNotesToMentor,
  useUpdateNote,
} from "@podcasts/hooks";
import { useQueryClient } from "react-query";
import { Header } from "@react-navigation/elements";
import { SafeAreaView } from "react-native-safe-area-context";

export default function YourNotes({
  navigation,
  route: {
    params: { slug },
  },
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const test = usePodcastNotes({ slug, order: "desc" });
  const [editNoteState, setEditNoteState] = useState({
    visible: false,
    value: "",
  });
  const [dismiss,setDismiss] = useState(0)
  const deleteMutation = useDeleteNote();
  const updateMutation = useUpdateNote();
  const sendNotesMutation = useSendNotesToMentor();
  const flatListRef = useRef();
  const queryClient = useQueryClient();

  const {
    data: { data },
    refetch,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
  } = test;
  const dispatch = useDispatch();

  useEffect(() => {
    // if(data.length > 0) {
    // flatListRef.current.scrollToIndex({
    //     animated: true,
    //     index: data.length - 2,
    //   });
    // }
  }, [data]);

  const onEditNote = useCallback(
    async (note) => {
      const id = editNoteState.id;

      try {
        await updateMutation.mutateAsync({ id, note });
        queryClient.setQueryData([`podcast-notes`, slug], (prev) => {
          return {
            ...prev,
            pages: prev.pages.map((page) => ({
              ...page,
              data: page.data.map((post) =>
                post.id === id ? { ...post, note } : post
              ),
            })),
          };
        });
        onEditClose();
        toast.show(`Note was successfully updated`, {
          placement: "bottom",
          type: "normal",
        });
      } catch (e) {
        toast.show(`Something went wrong`, {
          placement: "bottom",
          type: "danger",
        });
      }
    },
    [editNoteState, updateMutation]
  );

  const onSendNotes = useCallback(async () => {
    try {
      await sendNotesMutation.mutateAsync(slug);
      refetch();
      toast.show(`Successfully sent all notes`, {
        placement: "bottom",
        type: "normal",
      });
    } catch (e) {
      toast.show(`Something went wrong`, {
        placement: "bottom",
        type: "danger",
      });
    }
  }, [slug]);

  const onEditClose = useCallback(() => {
    setEditNoteState({ visible: false, value: "" });
  }, []);

  const onNextPage = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage, queryClient]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => {
        return (
          <View style={{ marginHorizontal: 18}}>
          <BackButton
            {...props}
            backColor={"white"}
            backLinkTo={`/podcasts/${slug}`}
          />
          </View>
        );
      },
    });
  }, [slug]);



  const onSendNote = useCallback(async ({ html, reset }) => {
    try {
      await dispatch(addNote({ id: slug, note: html }));

      toast.show(`Successfully added new note`, {
        placement: "bottom",
        type: "normal",
      });

      reset();
      refetch();
    } catch (e) {
      toast.show(`Something went wrong`, {
        placement: "bottom",
        type: "danger",
      });
    }
  }, []);

  const onDeleteNote = useCallback(async (id) => {
    try {
      await deleteMutation.mutateAsync(id);
      queryClient.setQueryData([`podcast-notes`, slug], (prev) => {
        return {
          ...prev,
          pages: prev.pages.map((page) => ({
            ...page,
            data: page.data.filter((post) => post.id !== id),
          })),
        };
      });

      toast.show(`Successfully deleted note`, {
        placement: "bottom",
        type: "normal",
      });
    } catch (e) {
      toast.show(`Something went wrong`, {
        placement: "bottom",
        type: "danger",
      });
    }
  }, []);

  const showActionSheet = useCallback((note) => {
    const options = ["Edit", "Send note to mentor", "Delete", "Cancel"];
    const destructiveButtonIndex = 2;
    const cancelButtonIndex = 3;

    setEditNoteState((p) => ({ ...p, id: note.id }));

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex) => {
        switch (selectedIndex) {
          case 0:
            setEditNoteState({ visible: true, value: note.note, id: note.id });
            break;

          case destructiveButtonIndex:
            onDeleteNote(note.id);
            break;

          case cancelButtonIndex:
            setEditNoteState((p) => ({ ...p, id: null }));
          default:
            setEditNoteState((p) => ({ ...p, id: null }));
        }
      }
    );
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="light-content" backgroundColor="#042B59" />
      <Header
        headerShadowVisible={false}
        headerBackTitleVisible={false}
        headerStyle={{
          height: 60,
          backgroundColor: "#042B59",
        }}
        layout={{ height: 100, width: 400 }}
        headerLeft={(props) => (
          <View style={{ marginHorizontal: 18}}>

          <BackButton
            backColor="white"
            {...props}
            navigation={navigation}
            replace={"TabsPages"}
          />
          </View>
        )}
        headerRight={(props) => {
          return (
            <Pressable
              onPress={onSendNotes}
              style={{
                borderWidth: 1,
                borderColor: "#FFFFFF",
                paddingHorizontal: 15,
                paddingVertical: 8,
                borderRadius: 1000,
                width: 175,
                marginRight: 20,
              }}
            >
              <Text style={{ color: "#FFFFFF", textAlign: 'center', fontFamily: "SofiaProMedium" }}>Send notes to mentor</Text>
            </Pressable>
          );
        }}
      />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 15 : 40}
        behavior={Platform.OS === 'ios' ? "padding" : "height"}
      >
      <View style={{ flex: 1}}   accessible={false}>
        <FlatList
          data={data || []}
          style={{ marginTop: 10, paddingHorizontal: 17, flex: 1 }}
          ref={(ref) => {
            flatListRef.current = ref;
          }}
          renderItem={({ item, index }) => (
            <NoteItem
              onPress={() => { 
                 setDismiss((Math.random() + 1).toString(36).substring(7))
                }}              key={index}
              {...item}
              active={editNoteState.id === item.id}
              onShowActionSheet={() => {
                showActionSheet(item);
              }}
            />
          )}
          onScrollToIndexFailed={(info) => {
            const wait = new Promise((resolve) => setTimeout(resolve, 500));
            wait.then(() => {
              flatListRef.current?.scrollToIndex({
                index: info.index,
                animated: true,
              });
            });
          }}
          onEndReachedThreshold={0.8}
          onEndReached={onNextPage}
        ></FlatList>
                </View>

        <TextEditor onSend={onSendNote}  onEdit={onEditNote} dismiss={dismiss} editNoteState={editNoteState}/>
        {/* <EditNoteModal
          {...editNoteState}
          onClose={onEditClose}
          onSend={onEditNote}
        /> */}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    // paddingHorizontal: 20,
    flex: 1,
    backgroundColor: "#042B59",
  },
  text: {
    color: "#fff",
  },
  paragraph: {
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 15,
    fontWeight: "bold",
  },
  note_box: {
    marginBottom: 18,
  },
  notes: {
    paddingTop: 20,
    flexShrink: 1,
    justifyContent: "flex-start",
  },
  note_actions: {
    justifyContent: "flex-end",
  },
  note_actions_closed: {
    display: "none",
  },
  send_btn: {
    backgroundColor: "#FCB912",
    alignItems: "center",
    paddingVertical: 22,
  },
  send_text: {
    color: "#fff",
    fontSize: 15,
    lineHeight: 15,
    fontWeight: "normal",
  },
  actions_list: {
    paddingVertical: 15,
    backgroundColor: "#fff",
  },
  action_item: {
    paddingHorizontal: 35,
    paddingVertical: 15,
  },
  action_text: {
    fontSize: 14,
    lineHeight: 14,
    fontWeight: "bold",
  },
  editor: {
    flex: 1,
    padding: 0,
    borderColor: "gray",
    borderWidth: 1,
    marginHorizontal: 30,
    // marginVertical: 5,
    backgroundColor: "white",
  },
});
