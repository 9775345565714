import { StyleSheet, Text, View, Image, TouchableOpacity, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";
import Star from "./icons/Star";
import { get } from "lodash";
import PodcastListItemWrapper from "./PodcastListItemWrapper";
import { useCallback, useMemo } from "react";

const PodcastListItem = ({
  item,
  index,
  isAuthenticated,
  saved,
  deleteItem,
  onSave,
  onPress,
  paddingHorizontal = 20,
  subTitle = 'topic'
}) => {

  const getTitle = useCallback((item) => {
    let text = null;
     if(subTitle === 'topic') {
        text = get(item, "topics[0].title", null);
     } else if(subTitle === 'speaker') {
       text = `${item?.speaker?.title} ${item?.speaker?.name}`
     } 
      if(text === null) {
       return <></>
     } 
     console.log(text,'text')
      return (<><Text style={styles.category_text} ellipsizeMode='tail' numberOfLines={1}>{text}</Text><View style={styles.separator}></View></>)
  },[subTitle]);

  return (
    <Pressable
      onPress={onPress}
      style={[styles.common_item, { paddingHorizontal, backgroundColor: index % 2 !== 0 ? '#FFF8E8': 'white' }]}
      key={item.id}
    >
      <View style={styles.common_info}>
        {item.poster && (
          <View style={styles.poster_box}>
            <Image source={{ uri: item.poster }} style={styles.poster_img} />
          </View>
        )}
        <View style={styles.text_box}>
          <Text style={styles.common_name} numberOfLines={1}>
            {item?.title}
          </Text>
          <View style={styles.common_category}>
            {getTitle(item)}
            <Text style={styles.category_text}>{item?.duration}</Text>
          </View>
        </View>
      </View>
      <View style={styles.common_actions}>
        {isAuthenticated && (
          <Star size={20} color="#000" full={saved} onPress={onSave} />
        )}
        <TouchableOpacity style={styles.action_menu} onPress={onPress}>
          <Icon name="dots-btn-v" size={18} color="#C4C4C4" />
        </TouchableOpacity>
        {deleteItem && (
          <TouchableOpacity style={styles.action_menu}>
            <Icon name="close-dark" size={13} color="#000" />
          </TouchableOpacity>
        )}
      </View>
    </Pressable>
  );
};

export default PodcastListItem;

const styles = StyleSheet.create({
  common_item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingVertical: 15,
    alignSelf: "center",
  },
  common_info: {
    marginRight: 20,
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  text_box: {
    flexShrink: 1,
  },
  common_name: {
    color: "#021C3C",
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 2,
    fontFamily: 'Poppins_600SemiBold'
  },
  common_category: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  category_text: {
    color: "#707070",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: "SofiaPro",
  },
  separator: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: "#707070",
    marginHorizontal: 4,
    marginTop: 6,
  },
  common_actions: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0,
  },
  action_menu: {
    minWidth: 10,
    alignItems: "flex-end",
    marginLeft: 15,
  },
  poster_box: {
    width: 40,
    height: 40,
    borderRadius: 6,
    overflow: "hidden",
    marginRight: 10,
  },
  poster_img: {
    width: "100%",
    height: "100%",
  },
});
