import * as React from "react";
import { StyleSheet, Image, TouchableOpacity, View } from "react-native";
import Icon from "./icons/CustomIcon";
import { useLinkTo } from "@react-navigation/native";
import BurgerIcon from "./icons//BurgerIcon";
import { useNavigation } from "@react-navigation/native";
import { useCallback } from 'react';

const HeaderRight = ({ searchIconColor = "black",  search = true }) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  const onOpen = useCallback(() => {
    console.log(navigation,'navigation')
   navigation.openDrawer();
  },[navigation])

  return (
    <View style={styles.data_user}>
      { search && <TouchableOpacity
        onPress={() => {
          linkTo(`/podcasts/search`);
        }}
      >
        <Icon
          name="search"
          size={23}
          color={searchIconColor}
          style={styles.search_btn}
        />
      </TouchableOpacity>}
      <TouchableOpacity
        onPress={onOpen}
      >
        <BurgerIcon />
      </TouchableOpacity>
    </View>
  );
};

export default HeaderRight;

const styles = StyleSheet.create({
  user: {
    width: "100%",
    paddingVertical: 8,
  },
  data_user: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    // marginTop: 40
  },
  search_btn: {
    marginRight: 27,
  },
});
