import { View, StyleSheet, ScrollView, Text, Image, Pressable, SafeAreaView} from "react-native";
import HowItWorksItem from "../components/HowItWorksItem";
import FeedBackItem from "../components/FeedBackItem";
import TopicSliderItem from "../components/TopicSliderItem";
import Swiper from "react-native-web-swiper";
import { dashboardSelector } from "@podcasts/store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { getTopics } from "@podcasts/store/dashboard";
import { useCallback, useEffect } from "react";
import { ChevronButton } from "../components/ChevronButton";
import CustomButton from '../components/CustomButton';
import { useLinkTo } from "@react-navigation/native";
import { useIsAuthenticated } from '@podcasts/hooks';
import Title from '../components/Title';

const slides = [
  {
    name: "Satchel Bootchk",
    role: "Participant",
    img: require("../assets/satchel.jpg"),
    description:
      '"The Podcast Fellowship is an eye-opening, ear-opening and heart-opening Jewish learning experience."',
  },
  {
    name: "Chelsea Moses",
    role: "Participant",
    img: require("../assets/chelsea.jpg"),
    description:
      '"The idea that I’m getting paid to speak to someone so knowledgeable who wants to share what she knows with me…it’s crazy!"',
  },
  {
    name: "Alek Dernovoy",
    role: "Participant",
    img: require("../assets/alex.jpg"),
    description:
      '"My advice to other young Jews: Go for it. Start learning, because everybody is at a different step in the journey...It’s been great for me to have this opportunity to grow as a person as well."',
  },
];

const HowItWorksList = [
  {
    text: "Apply and choose a timeslot for your intake interview",
    img: require("../assets/apply_and_choose.jpg"),
  },
  {
    text: "You will be paired with a mentor",
    img: require("../assets/meet.jpg"),
  },
  {
    text: "Listen to a podcast and meet with your mentor to discuss over zoom",
    img: require("../assets/listen.jpg"),
  },
  {
    text: "Get paid $15 for each session you complete",
    img: require("../assets/get_paid.jpg"),
  },
];
const HomePage = () => {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const isAuthenticated = useIsAuthenticated();

  const {
    topics: { data = [] },
  } = useSelector(dashboardSelector);

  useEffect(() => {
    dispatch(getTopics());
  }, []);


  const onApply = useCallback(() => {
      linkTo(isAuthenticated ? '/podcasts/featured' : `/application`);
  },[isAuthenticated]);

  const onMentorApply = useCallback(() => {
    linkTo(isAuthenticated ? '/podcasts/featured' : `/mentor-apply`);
},[isAuthenticated]);

  return (
    <SafeAreaView  style={{ flex: 1}}>
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={{ paddingHorizontal: 15,alignItems: "center", paddingTop: 15}}>
      <View style={styles.hero_img_container}>
      <Image source={require("../assets/hero.png")} style={{ width: 303, height: 321}}/>
      <Image source={require("../assets/hero_right_bottom.png")}
       style={styles.hero_img_right_bottom}/>
      </View>

      <Title style={{ marginBottom: 15}}
          source={ require('../assets/titles/learn-share.jpg')}/>

      {/* <View style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: 226, marginBottom: 15 }}>
        <Text
          style={{
            fontSize: 32,
            fontFamily: "Poppins_700Bold",
            color: "#fcb912",
          }}
        >
          Learn. 
        </Text>
        <Text
          style={{
            fontSize: 32,
            fontFamily: "Poppins_700Bold",
            color: "#021c3c",
          }}
        >
          {" Share."}
        </Text>

          <Text
            style={{
              fontSize: 32,
              fontFamily: "Poppins_700Bold",
              color: "#021c3c",
            }}
          >
            Earn.
          </Text>
          <Text
            style={{
              fontSize: 26,
              fontFamily: "Poppins_500Medium",
              lineHeight: 47
            }}>
            {" (Repeat)"}
          </Text>
      </View> */}

      <Text
          style={{
            fontSize: 16,
            textAlign: "center",
            fontWeight: "300",
            lineHeight: 25,
            marginBottom: 10,
            fontFamily: 'SofiaPro'
          }}
        >
         Earn $15 for every podcast (or live class) you complete and share with one person
        </Text>


          <View style={{ width: isAuthenticated ? '100%' : 200, marginBottom: 30, maxWidth: 340 }}>
              <CustomButton
                     fontSize={16}
                     decorator
                      name={isAuthenticated ? "Check out our Jewish Podcasts" : "Apply now"}
                      color="blue"
                      onPress={onApply}
                    />
              </View>
          <View style={{ width: isAuthenticated ? '100%' : 200, marginBottom: 30, maxWidth: 340 }}>
              <CustomButton
                     fontSize={16}
                     decorator
                      name={isAuthenticated ? "Check out our Jewish Podcasts" : "Mentor Apply"}
                      color="blue"
                      onPress={onMentorApply}
                    />
              </View>
     { !isAuthenticated &&  <Pressable onPress={() => {
        linkTo('/podcasts/featured')
      }}>
      <Text style={{ textDecorationLine: 'underline',marginTop: 10, marginBottom: 30, fontSize: 16, fontFamily: 'SofiaPro', marginTop: 10}}>View our Podcast Library</Text>
      </Pressable> }
      <Title style={{ marginBottom: 15, height: 42}}
          source={ require('../assets/titles/how-it-works.jpg')}/>

      {/* <View style={styles.how_it_works_title_container}>
      <Text style={{ fontFamily: "Poppins_700Bold", fontSize: 28, color: '#021c3c' }}>How It</Text>
      <Text  style={{ fontFamily: "Poppins_700Bold", fontSize: 28, color: '#fcb912' }}> Works</Text>
      </View> */}

      <View>
      </View>

      <View style={{ alignItems: "center" }}>
        {HowItWorksList.map((item, index) => (
          <HowItWorksItem
            key={index}
            style={{ marginBottom: 25 }}
            number={index + 1}
            {...item}
          />
        ))}
      </View>

      <View>
      {/* <Title style={{ marginBottom: 15, height: 42, alignSelf: 'center'}}
          source={ require('../assets/titles/topics.jpg')}/> */}
      {/* <Title  source={ require('../assets/titles/learn-share.jpg')}/> */}


        <Text style={styles.topics_title}>Topics</Text>
        <Text style={styles.topics_description}>
           Delve into a vibrant collection of timely and relevant Jewish topics, exploring contemporary issues, social justice, ethical dilemmas, and the intersection of tradition and modernity.
        </Text>
        {data.length > 0 && (
          <View style={styles.topics_container}>
            <Swiper
              nextTitle={""}
              prevTitle={""}
              controlsProps={{
                prevPos: false,
                nextPos: false,
                DotComponent: ({ index, activeIndex, isActive, onPress }) => (
                  <></>
                ),
              }}
              Controls={(props) => {
                return (
                  <View
                    style={{
                      position: "absolute",
                      bottom: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <ChevronButton
                      size={45}
                      iconSize={14}
                      direction="left"
                      style={{ margin: 12 }}
                      onPress={() => props.goToPrev()}
                    />
                    <ChevronButton
                      size={45}
                      iconSize={14}
                      direction="right"
                      style={{ margin: 12 }}
                      onPress={() => props.goToNext()}
                    />
                  </View>
                );
              }}
            >
              {data.map((item, index) => (<Pressable onPress={() => { linkTo(`/podcasts/topics/${item.slug}`); }}>
                 <TopicSliderItem key={index} {...item} />
              </Pressable>
              ))}
            </Swiper>
          </View>
        )}
      </View>

      <View style={[styles.container,styles.feedbacks_container]}>
        <Swiper
          nextTitle={""}
          prevTitle={""}
          controlsProps={{
            // prevPos: false,
            // nextPos: false,
            DotComponent: ({ index, activeIndex, isActive, onPress }) => (
              <Text
                onPress={onPress}
                style={{
                  width: 10,
                  margin: 5,
                  borderWidth: 1,
                  // borderColor: index === activeIndex ? "#FCB912" : "black",
                  height: 10,
                  borderRadius: 5,
                  overflow: 'hidden',
                  backgroundColor: isActive ? "#FCB912" : "transperent",
                }}
              ></Text>
            ),
          }}
        >
          {slides.map((item, index) => (
            <View key={index} style={[styles.slideContainer]}>
              <FeedBackItem key={index} {...item} />
            </View>
          ))}
        </Swiper>
      </View>
      </View>
      <View style={styles.footer}>
      <Pressable  onPress={() => { linkTo(`/aboutus`); }}>
          <Text style={[styles.footer_text, styles.margin_bottom_25]}>About</Text>
        </Pressable>
        <Pressable  onPress={() => { linkTo(`/podcasts/featured`); }}>
        <Text style={[styles.footer_text, styles.margin_bottom_25]}>Podcast Library</Text>
        </Pressable>
        <Pressable  onPress={() => { linkTo(`/trivia`); }}>
        <Text style={[styles.footer_text, styles.margin_bottom_25]}>Trivia</Text>
        </Pressable>
        <Pressable  onPress={() => { linkTo(`/mentor-apply`); }}>
        <Text style={[styles.footer_text, styles.margin_bottom_25]}>Mentor Apply</Text>
        </Pressable>
        <Pressable  onPress={() => { linkTo(`/contact`); }}>
        <Text style={[styles.footer_text, styles.margin_bottom_57]}>Contact</Text>
        </Pressable>
        
        <View style={[styles.margin_bottom_57]}>
        <Pressable  style={{ flexDirection: 'row', alignItems: 'center', marginRight: -26}} onPress={() => { linkTo(`/donate`); }}>
           <Text  style={[styles.footer_text,{ fontSize: 18}]}>Donate</Text>
           <ChevronButton
                      size={30}
                      iconSize={14}
                      color={"white"}
                      direction="right"
                      style={{ margin: 12 }}
                      onPress={() => {linkTo(`/donate`);}}
                    />
        </Pressable>
        </View>
       <Text style={[styles.footer_text, { maxWidth: 238  }]}>©The Podcast Fellowship 2021 - 2024 all rights reserved</Text>
      </View>
    </ScrollView>
    </SafeAreaView>
  );
};

export default HomePage;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 420,
    backgroundColor: "#fff8e8",
    paddingTop: 30,
    paddingBottom: 50,
  },
  topics_container: {
    // width: 300,
    height: 424,
    // paddingHorizontal: 15,
    marginBottom: 50,
  },
  topics_title: {
    backgroundPosition: "center",
    backgroundSize: "620px 230px",
    backgroundClip: "text",
    color: "#fcb912",
    textAlign: "center",
    fontFamily: "Poppins_700Bold",
    textFillColor: "transperent",
    fontSize: 26,
    lineHeight: 39,
    marginBottom: 20,
  },
  topics_description: {
    textAlign: "center",
    fontSize: 15,
    lineHeight: 25,
    marginBottom: 30,
    fontFamily: "SofiaPro",
  },
  slideContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  hero_img_container: { 
    height: 310, 
    width: 290,
    marginBottom: 28 ,
    position: 'relative'
 },
 hero_img_right_bottom: { 
  width: 115,
  height: 120,
  position: "absolute",
  bottom: -21,
  right: -24,
  zIndex: -1 },
 hero_img: {
  width: "100%",
  clipPath: "url(#imgTopMob)",
  objectFit: "cover",
  position: "absolute",
},
hero_border_img: {
  top: 12,
  left: 10,
  position: "absolute",
  right: -10,
  width: "100%",
},
 how_it_works_title_container: {
  flexDirection: 'row',
  marginBottom: 26
  },
  feedbacks_container: {
    marginLeft: -15,
    marginRight: -15,
    width: '110%'

    //  width: 'calc(100% + 30px)'
  },
  footer: {
    backgroundColor: '#042B59',
    paddingVertical: 40,
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  footer_text: {
    color: 'white',
    fontSize: 16,
    fontFamily: "SofiaPro"
  },
  margin_bottom_25: {
    marginBottom: 25
  },
  margin_bottom_57: {
    marginBottom: 57
  }
});

