import { StyleSheet, View } from "react-native";

export default function BurgerIcon() {
  return (
    <View>
      <View style={styles.burger_box}>
        <View style={styles.line}></View>
        <View style={[styles.line, styles.midle_line]}></View>
        <View style={styles.line}></View>
      </View>
      <View style={styles.burger_bg}></View>
    </View>
  );
}

const styles = StyleSheet.create({
  burger_box: {
    width: 34,
    height: 34,
    borderRadius: 17,
    borderColor: "#000000",
    borderWidth: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 14,
    height: 1,
    backgroundColor: "#000",
  },
  midle_line: {
    marginVertical: 3,
  },
  burger_bg: {
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: "#FCB912",
    position: "absolute",
    left: 2,
    top: 1,
    zIndex: -1,
  },
});
