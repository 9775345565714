import { Platform } from "react-native";

const  useMixpanel = () => {
    if (Platform.OS === "web") {
        const { WebHook } =  require("./webHook.js");
        return WebHook()
    } else {
        const { NativeHook } =  require("./nativeHook.js");
        return NativeHook()
    }
}

export default useMixpanel;