import { useToast } from "react-native-toast-notifications";
import { useCallback } from "react";
import {
  useCoursesLibraryBase,
  useSpeakerLibraryBase,
  useTopicLibraryBase,
  usePodcastsLibraryBase,
  usePodcastLibraryAppToast,
} from "@podcasts/hooks";

const style = { backgroundColor: "#042B59", marginBottom: 79, width: "100%" };

const useLibaryToast = () => {
  const toast = useToast();

  const showSavedLibrary = useCallback(
    (item) => {
      toast.show(`Added to saved ${item.title}`, {
        placement: "bottom",
        type: "success",
        style,
      });
    },
    [toast]
  );

  const showRemovedLibrary = useCallback(
    (item) => {
      toast.show(`Removed from saved ${item.title}`, {
        placement: "bottom",
        type: "success",
        style,
      });
    },
    [toast]
  );

  const showAnuthorizedLibrary = useCallback(
    (item) => {
      toast.show("Authorization is required", {
        placement: "top",
        type: "warning",
      });
    },
    [toast]
  );

  return { showSavedLibrary, showRemovedLibrary, showAnuthorizedLibrary };
};

export const usePodcastsLibrary = ({ type } = { type: "success" }) => {
  const toast = usePodcastLibraryAppToast({ type });
  return usePodcastsLibraryBase(toast);
};

export const useCoursesLibrary = () => {
  const toast = useToast();

  const onAddFinish = useCallback((item) => {}, [toast]);

  const onRemoveFinish = useCallback((item) => {}, [toast]);

  const onUnathorized = useCallback((item) => {}, [toast]);

  return useCoursesLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized });
};

export const useSpeakerLibrary = () => {
  const toast = useToast();

  const onAddFinish = useCallback((item) => {}, [toast]);

  const onRemoveFinish = useCallback((item) => {}, [toast]);

  const onUnathorized = useCallback((item) => {}, [toast]);

  return useSpeakerLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized });
};

export const useTopicLibrary = () => {
  const toast = useToast();

  const onAddFinish = useCallback((item) => {}, [toast]);

  const onRemoveFinish = useCallback((item) => {}, [toast]);

  const onUnathorized = useCallback((item) => {}, [toast]);

  return useTopicLibraryBase({ onAddFinish, onRemoveFinish, onUnathorized });
};
