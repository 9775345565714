import { StyleSheet, Text, View, Image, Platform } from "react-native";
import pick from "lodash/pick";
import { Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import DatePicker from "../../components/DatePicker";
import { useSelector } from "react-redux";
import { firsStepInitialValue } from "@podcasts/store/application";
import CustomSelect from "../../components/CustomSelect";
import { firstStepMentorSchema } from "@podcasts/schemas";
import { countriesList, genderSelectOptions } from "@podcasts/constants";
import { PoppinsBoldText } from "../../components/PopinsText";
import Title from "../../components/Title";

const countries = countriesList.map((v) => ({
  value: v.isoCode,
  label: v.name,
}));

export default function FirstStep({ onNext, initialValues }) {
  // const data = useSelector(firsStepInitialValue);

  return (
    <View style={styles.login}>
      <View style={styles.step_number}>
        <Text style={styles.step_number_text}>1</Text>
      </View>
      <Title
        source={require("../../assets/titles/application_process_1.jpg")}
      />
      <Text style={styles.step_subtitle}>
        Thank you for applying! Join our community of mentors and help shape the
        Jewish learning journey of aspiring students at The Podcast Fellowship.
      </Text>
      <Formik
        enableReinitialize
        initialValues={{ ...initialValues, accept: true }}
        validationSchema={firstStepMentorSchema}
        onSubmit={(values) => {
          onNext(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <View style={styles.form}>
            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("fname")}
                onBlur={handleBlur("fname")}
                value={values.fname}
                error={errors.fname && touched.fname ? errors.fname : null}
                label={"First Name *"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("lname")}
                onBlur={handleBlur("lname")}
                value={values.lname}
                error={errors.lname && touched.lname ? errors.lname : null}
                label={"Last Name *"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                autoCapitalize="none"
                keyboardType={"email-address"}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                value={values.email}
                error={errors.email && touched.email ? errors.email : null}
                label={"Email *"}
              />
            </View>
            <View style={styles.form_control}>
              <CustomSelect
                options={genderSelectOptions}
                onChange={(v) => {
                  setFieldValue("gender", v || null);
                }}
                onBlur={handleBlur("gender")}
                value={values.gender}
                error={errors.gender && touched.gender ? errors.gender : null}
                label={"Gender"}
                placeholder={{
                  label: "Please select:",
                  color: "#7E7E7E",
                  fontSize: 15,
                }}
              />
            </View>
            <View style={styles.form_control}>
              <CustomInput
                keyboardType="phone-pad"
                onChangeText={handleChange("phone")}
                onBlur={handleBlur("phone")}
                value={values.phone}
                error={errors.phone && touched.phone ? errors.phone : null}
                label={`Phone Number ${Platform.OS === "ios" ? "" : "*"}`}
                placeholder={"( _ _ _ )  _ _ _   _ _ _ _"}
              />
            </View>

            <View style={[styles.form_control, { zIndex: 33 }]}>
              <DatePicker
                onChange={(value) => {
                  setFieldValue("dob", value);
                }}
                keyboardType="phone-pad"
                onBlur={handleBlur("dob")}
                value={values.dob}
                error={errors.dob && touched.dob ? errors.dob : null}
                label={`Date of Birth ${Platform.OS === "ios" ? "" : "*"}`}
                placeholder={"mm / dd / yyyy"}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("calendly_url")}
                onBlur={handleBlur("calendly_url")}
                value={values.calendly_url}
                error={
                  errors.calendly_url && touched.calendly_url
                    ? errors.calendly_url
                    : null
                }
                label={"Calendly url *"}
              />
            </View>
            {/* <View style={styles.form_control}>
                <CustomSelect
                  options={countries}
                  onChange={(v) => {
                    setFieldValue('country', v || null)
                  }}
                  onBlur={handleBlur("country")}
                  value={values.country}
                  error={
                    errors.country && touched.country ? errors.country : null
                  }
                  label={"Country (Current Residence) *"}
                  placeholder={{ label: "Please select:", color: "#7E7E7E", fontSize: 15}}/>
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("city_residence")}
                  onBlur={handleBlur("city_residence")}
                  value={values.city_residence}
                  label={"City (Current Residence)"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("state_residence")}
                  onBlur={handleBlur("state_residence")}
                  value={values.state_residence}
                  label={"State (Current Residence)"}
                />
              </View> */}
            <View style={styles.next_step}>
              <CustomButton
                decorator
                name={"Next step"}
                color="blue"
                onPress={handleSubmit}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 50,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  pf_title: {
    color: "#FCB912",
    fontSize: 26,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    fontFamily: "SofiaPro",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: "100%",
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  next_step: {
    marginTop: 40,
  },
});
