import * as React from "react";
import { TouchableOpacity, Platform } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AboutUsPage from "../pages/AboutUsPage";
import DonatePage2 from "../pages/DonatePage2";
import TriviaPage from "../pages/TriviaPage";
import ContactUsPage from "../pages/ContactUsPage";
import BurgerIcon from "../components/icons/BurgerIcon";
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import HomePage from "../pages/HomePage";

const Stack = createNativeStackNavigator();

export const homePageOptions = ({ navigation, title = '' }) => ({
  title,
  headerShadowVisible: false,
  headerBackTitleVisible: false,
  headerTitle: (props) => null,
  headerRight: (props) => (
    <TouchableOpacity
      style={{ marginRight: 22 }}
      onPress={() => {
        navigation.openDrawer();
      }}
    >
      <BurgerIcon />
    </TouchableOpacity>
  ),
});

export const AboutUsPageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="AboutUsPage"
        component={AboutUsPage}
        options={(options) => homePageOptions({...options, title: Platform.OS === 'web' ? "About - The Podcast Fellowship": ''})}
      />
    </Stack.Navigator>
  );
};

export const HomePageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="HomePage"
        component={HomePage}
        options={(options) => homePageOptions({...options, title: Platform.OS === 'web' ? "About - The Podcast Fellowship": ''})}
      />
    </Stack.Navigator>
  );
};



export const PrivacyPolicyPageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicy}
        options={(options) => homePageOptions({...options, title: Platform.OS === 'web' ? "About - The Podcast Fellowship": ''})}
      />
    </Stack.Navigator>
  );
};

export const ContactUsPageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ContactUsPage"
        component={ContactUsPage}
        options={(options) => homePageOptions({...options, title: Platform.OS === 'web' ? "Contact - The Podcast Fellowship" : ''})}
      />
    </Stack.Navigator>
  );
};

export const DonatePageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="DonatePage"
        component={DonatePage2}
        options={(options) => ({ ...homePageOptions({...options, title: Platform.OS === 'web' ? "Donate - The Podcast Fellowship" : ''}), headerShown: false})}
      />
    </Stack.Navigator>
  );
};

export const TriviaPageStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TriviaPage"
        component={TriviaPage}
        options={(options) => ({ ...homePageOptions({...options, title: Platform.OS === 'web' ? "Trivia - The Podcast Fellowship" : ''})})}
      />
  </Stack.Navigator>
  )
}