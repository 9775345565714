import { useEffect } from 'react';
import { View, Platform} from 'react-native';
import { usePodcastSpeakerPage } from "@podcasts/hooks";
import SpeakerBase from "./SpeakerBase";
import BackButton from "../../components/BackButton";

export default function PodcastSpeakerPage({
  route: {
    params: { slug },
  },
   navigation
}) {
  const hook = usePodcastSpeakerPage(slug);

  useEffect(() => {
      navigation.setOptions({
        headerLeft: (props) => {
          return (
            <View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0}}>
            <BackButton
              {...props}
              backColor={"blue"}
              backLinkTo={`/podcasts/${slug}`}
            />
           </View>
          );
        },
      }); 
  }, [slug]);


  return <SpeakerBase {...hook} slug={hook?.data?.slug} navigation={navigation}/>;
}
