import { Platform } from "react-native";

const AnalyticsProvider = ({ children }) => {
  if (Platform.OS === "web") {
    const MixpanelProvider =   require("./Web.js").default;
    return <MixpanelProvider >{children}</MixpanelProvider>
  } else {
    const MixpanelProvider = require("./Native.js").Native;
    return <MixpanelProvider >{children}</MixpanelProvider>
  }
};
export default AnalyticsProvider;
