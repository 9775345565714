import {
  StyleSheet,
  Text,
  View,
  Platform,
  Pressable,
  FlatList,
} from "react-native";
import BlueButton from "../../components/BlueButton";
import Icon from "../../components/icons/CustomIcon";
import React, { useState } from "react";
import moment from "moment";
import { useLinkTo } from "@react-navigation/native";
import { useRecentNotesPage } from "@podcasts/hooks";
import CustomRenderHtml from "../../components/CustomRenderHtml";
import { useWindowDimensions } from "react-native";

export default function RecentNotesPage() {
  const { width } = useWindowDimensions();

  const [openedIndex, setOpenedIndex] = useState(-1);
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    onNextPage,
  } = useRecentNotesPage();

  const toggle = (index) => {
    setOpenedIndex((value) => {
      return index === value ? -1 : index;
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <Text style={styles.page_title}>Recent notes</Text>
        {/* <BlueButton
          icon="clock-light"
          size={16}
          text="View all recently viewed"
          paddingH={11}
          onPress={() => {
            linkTo(`/podcasts/library/recent`);
          }}
        /> */}
      </View>
      <FlatList
        data={data}
        onEndReachedThreshold={0.8}
        onEndReached={onNextPage}
        renderItem={({ item, index }) => {
          return (
            <View key={index} style={styles.note_item}>
              <View style={styles.header}>
                <View style={styles.info}>
                  <Text style={styles.name}>{item.title}</Text>
                  <View style={{ width: 135 }}>
                    <BlueButton
                      icon="chevron-right-light"
                      size={6}
                      text="View podcast"
                      onPress={() => {
                        linkTo(`/podcasts/${item.slug}`);
                      }}
                      circle={true}
                      paddingH={11}
                    />
                  </View>
                </View>
                <Pressable
                  style={[
                    styles.header_btn,
                    openedIndex === index && styles.opened,
                  ]}
                  onPress={() => {
                    toggle(index);
                  }}
                >
                  <Icon name="chevron-down-dark" size={6} color="#000" />
                </Pressable>
              </View>
              {openedIndex === index && (
                <>
                  {item?.notes.map((n, i) => (
                    <View style={styles.body} key={i}>
                      <Text style={styles.note_time}>
                        {moment(n.created_at).fromNow()}
                      </Text>
                      <View style={styles.note_box}>
                        <CustomRenderHtml
                          html={n.note}
                          width={width}
                          color={"black"}
                        />
                      </View>
                    </View>
                  ))}
                </>
              )}
            </View>
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    width: "100%",
    flex: 1,
    alignSelf: "center",
  },
  note_item: {
    paddingHorizontal: 25,
    paddingTop: 25,
    paddingBottom: 10,
    borderBottomColor: "#D8D8D8",
    borderBottomWidth: 1,
  },
  page_header: {
    paddingTop: 30,
    paddingBottom: 25,
    alignItems: "center",
    borderBottomColor: "#D8D8D8",
    borderBottomWidth: 1,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 8,
    fontFamily: 'Poppins_700Bold'
  },
  date: {
    fontSize: 12,
    lineHeight: 24,
    marginBottom: 3,
  },
  name: {
    color: "#021C3C",
    fontSize: 14,
    lineHeight: 26,
    fontFamily: "Poppins_500Medium",
    marginBottom: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 15,
  },
  header_btn: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    borderColor: "#000",
    borderWidth: 1,
  },
  info: {
    flexShrink: 1,
    paddingRight: 20,
  },
  body: {
    alignItems: "flex-start",
    paddingBottom: 15,
  },
  note_time: {
    color: "#828282",
    fontSize: 12,
    lineHeight: 18,
    fontWeight: "normal",
    marginBottom: 5,
  },
  note: {
    color: "#030406",
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "normal",
  },
  note_box: {
    backgroundColor: "#FFF8E8",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 8,
    marginBottom: 20,
  },
  opened: {
    transform: [{ rotateX: "180deg" }],
  },
});
