import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Pressable,
  Modal,
  SafeAreaView,
  ScrollView
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { searchSelector } from "@podcasts/store/search";
import { getFilters } from "@podcasts/store/search";
import { useEffect } from "react";
import FilterIcon from "../../components/icons/FilterIcon";
import Icon2 from "../../components/icons/CustomIcon2";
import { Header } from "@react-navigation/elements";
import Icon from "../../components/svg/icon";

const Item = ({ title, selected }) => (
  <View style={styles.item}>
    <Text style={{fontFamily: selected ? 'SofiaProMedium' : 'SofiaPro', fontWeight: '500' }}>{title}</Text>
    <FilterIcon checked={selected} />
  </View>
);

const FilterItem = ({
  list = [],
  title,
  renderFilterTitle,
  toggleView,
  selectedType,
  type,
  selected,
  onSelect,
}) => {
  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => toggleView(selectedType === type ? null : type)}
      >
        <View style={styles.title_box}>
          <Text style={styles.title}>{title}</Text>
          <View
            style={[
              selectedType === type
                ? { transform: [{ rotate: "180deg" }] }
                : {},
            ]}
          >
            <Icon2 name="chevron-select" size={11} color="#000" />
          </View>
        </View>
      </Pressable>
      {selectedType === type && (
        <View  style={{ backgroundColor: "#FFF8E8", paddingVertical: 15 }}> 
        { list.map((item, index) =>   <Pressable key={index}
              onPress={() => {
                onSelect({ id: item.id, type });
              }}
            >
              <Item
                {...item}
                selected={selected.includes(item.id)}
                title={renderFilterTitle(item)}
              />
            </Pressable>)}
        </View>
     
      )}
    </View>
  );
};

export default function SearchFiltersModal({ visible, onClose, filters, toggleFilter }) {
  const [openedType, setOpenedType] = useState("course");
  const dispatch = useDispatch();
  const { courses, speakers, topics, loaded } = useSelector(searchSelector);

  const {
    course: courseFilter,
    speaker: speakerFilter,
    topic: topicFilter,
  } = filters;

  useEffect(() => {
    if (!loaded) dispatch(getFilters());
  }, []);

  return (
    <Modal
      animationType={"slide"}
      transparent={false}
      visible={visible}
      onRequestClose={() => {}}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <Header
          headerShadowVisible={false}
          headerBackTitleVisible={false}
          headerStyle={{
            height: 60,
          }}
          layout={{ height: 100, width: 400 }}
          headerRight={() => (
            <Pressable
              onPress={() => {
                onClose();
              }}
              style={{
                display: "flex",
                width: 35,
                justifyContent: "center",
                alignItems: "center",
                height: 35,
                borderColor: "#000000",
                borderWidth: 1,
                borderRadius: 35 / 2,
                marginRight: 10,
              }}
            >
              <Icon name="CloseCross" width="13" />
            </Pressable>
          )}
        />
        <ScrollView style={{ flex: 1 }}>
          <FilterItem
            list={speakers}
            selected={speakerFilter}
            type={"speaker"}
            onSelect={(data) => {
              toggleFilter(data);
            }}
            selectedType={openedType}
            renderFilterTitle={(item) => `${item.fname} ${item.lname}`}
            title={"Speakers"}
            toggleView={setOpenedType}
          ></FilterItem>
          <FilterItem
            list={courses}
            selected={courseFilter}
            type={"course"}
            onSelect={(data) => {
              toggleFilter(data);
            }}
            selectedType={openedType}
            renderFilterTitle={(item) => item.name}
            title={"Series / Courses"}
            toggleView={setOpenedType}
          ></FilterItem>
          <FilterItem
            title={"Topics"}
            type={"topic"}
            selected={topicFilter}
            list={topics}
            onSelect={(data) => {
               toggleFilter(data);
            }}
            selectedType={openedType}
            renderFilterTitle={(item) => item.name}
            toggleView={setOpenedType}
          ></FilterItem>
        </ScrollView>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    // marginTop: StatusBar.currentHeight || 0,
    paddingBottom: 15,
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingHorizontal: 20,
    paddingBottom: 10,
    paddingTop: 10,
    fontSize: 14,
  },
  title_box: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 14,
    paddingHorizontal: 20,
  },
  title: {
    color: "#021C3C",
    fontSize: 22,
    fontFamily: "Poppins_700Bold"
  },
  active: {
    fontWeight: "500",
  },
});
