import { useTopicAppPage } from "@podcasts/hooks";
import TopicBase from "./TopicBase";

const TopicInnerPage = ({
  route: {
    params: { slug },
  },
  navigation
}) => {
  const hook = useTopicAppPage(slug);

  return <TopicBase {...hook} slug={slug} navigation={navigation} />;
};

export default TopicInnerPage;
