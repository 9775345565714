import { StyleSheet, View } from "react-native";
import SavedRandomBlock from "../../components/SavedRandomBlock";
import { TopicHeader } from "../../components/TopicHeader";
import PodcastList from "../../components/PodcastList";
import { useLayoutEffect } from "react";

const TopicBase = (props) => {
  const {
    data: topic = {},
    isSavedTopic,
    onSaveTopic,
    isAuthenticated,
    slug,
    backLinkTo = "/podcasts/topics",
    navigation
  } = props;


  useLayoutEffect(() => {
    navigation.setOptions({
      title:  `${topic?.title ? `${topic.title} - ` : ''}The Podcast Fellowship`
    })
  },[topic])

  return (
    <>
      <TopicHeader {...topic} backLinkTo={backLinkTo} />
      <View style={styles.container}>
        <SavedRandomBlock
          authenticated={isAuthenticated}
          saved={isSavedTopic(topic?.id)}
          onPress={() => onSaveTopic()}
          btnText="Save topic"
        />
        <PodcastList params={{ topic_slug: [slug] }}  podcastSubTitle = 'speaker'/>
      </View>
    </>
  );
};

export default TopicBase;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
  items_wrapp: {
    marginTop: 15,
    paddingBottom: 80,
  },
});
