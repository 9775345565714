import { Text } from "react-native";

export const PoppinsBoldText = (
  { children, style, ...rest } = { style: {} }
) => (
  <Text {...rest} style={{ ...style, fontFamily: "Poppins_700Bold" }}>
    {children}
  </Text>
);
export const PoppinsMediumText = (
  { children, style, ...rest } = { style: {} }
) => (
  <Text {...rest} style={{ ...style, fontFamily: "Poppins_500Medium" }}>
    {children}
  </Text>
);
