import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { mentorSelector } from "@podcasts/store/mentor";
import { secondStepMentorSchema } from "@podcasts/schemas";
import { useSelector } from "react-redux";
import { secondApplicationStepSchema } from "@podcasts/schemas";
import { countriesList, genderSelectOptions } from "@podcasts/constants";
import { PoppinsBoldText } from "../../components/PopinsText";
import Title from '../../components/Title';

const radioButtons = [
  {
    id: "1",
    label: "Yes",
    value: true,
  },
  {
    id: "2",
    label: "No",
    value: false,
    selected: true,
  },
];

const countries = countriesList.map((v) => ({
  value: v.isoCode,
  label: v.name,
}));

export default function SecondStep({ onPrev, onNext, initialValues }) {
  const { error: registerErrorMessage } = useSelector(mentorSelector);

  return (
      <View style={styles.login}>
        <View style={styles.step_number}>
          <Text style={styles.step_number_text}>2</Text>
        </View> 
        <Text style={styles.step_title}>
          Tell us about yourself
        </Text>
        {/* <Title source={ require('../../assets/titles/application_process_2.jpg')}/> */}
        {registerErrorMessage && (
            <View className={`${styles["errorMessage"]}`}>
              <Text style={styles.text_error}>{ registerErrorMessage }</Text>
            </View>
          )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={secondStepMentorSchema}
          onSubmit={(values) => {
            onNext(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <CustomSelect
                  options={countries}
                  onChange={(v) => {
                    setFieldValue('country', v || null)
                  }}
                  onBlur={handleBlur("country")}
                  value={values.country}
                  error={
                    errors.country && touched.country ? errors.country : null
                  }
                  label={"Country *"}
                  placeholder={{ label: "Please select:", color: "#7E7E7E", fontSize: 15}}/>
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("school")}
                  onBlur={handleBlur("school")}
                  value={values.school}
                  error={
                    errors.school &&
                    touched.school
                      ? errors.school
                      : null
                  }
                  label={"School *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("city")}
                  onBlur={handleBlur("city")}
                  value={values.city}
                  error={
                    errors.city && touched.city
                      ? errors.city
                      : null
                  }
                  label={"City *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("state")}
                  onBlur={handleBlur("state")}
                  value={values.state}
                  error={
                    errors.state && touched.state
                      ? errors.state
                      : null
                  }
                  label={"State *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("bio")}
                  onBlur={handleBlur("bio")}
                  value={values.bio}
                  minHeight={90}
                  numberOfLines={8}
                  error={
                    errors.bio && touched.bio
                      ? errors.bio
                      : null
                  }
                  label={"Bio *"}
                  multiline={true}
                />
              </View>

              <View style={styles.next_step}>
                <CustomButton
                  decorator
                  name={"Next step"}
                  color="blue"
                  onPress={handleSubmit}
                />
              </View>

              <View style={styles.prev_step}>
                <TouchableOpacity onPress={() => onPrev(values)}>
                  <Text style={styles.prev_step_text}>Previous step</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Formik>
      </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 55,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontWeight: "bold",
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  pf_title: {
    color: '#FCB912',
    fontSize: 26,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: "100%",
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  next_step: {
    marginTop: 40,
  },
  prev_step: {
    marginTop: 40,
  },
  prev_step_text: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 18,
    fontFamily: "SofiaPro"
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    fontFamily: 'SofiaPro',
    marginLeft: '5%',
    marginTop: 5
  },
});
