import { StyleSheet, Text, View, FlatList } from "react-native";
import TopicsList from "../../components/TopicsList";
import { useLinkTo } from "@react-navigation/native";
import { useSavedTopicsAppPage } from "@podcasts/hooks";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";

export default function SavedTopicsPage() {
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    order,
    toggleOrder,
    onNextPage,
    isSavedTopic,
    onSaveTopic,
    isAuthenticated,
  } = useSavedTopicsAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "title",
  });

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <PageHeaderSort
              title="Saved Topics"
              onPress={() => toggleOrder()}
              order={order}
            />
        </View>
      <View style={styles.items_wrapp}>
        <FlatList
          data={data}
          renderItem={({ item, index }) => (
            <TopicsList
              {...item}
              index={index}
              totalCount={data.length}
              authenticated={isAuthenticated}
              saved={isSavedTopic(item.id)}
              onSave={() => onSaveTopic(item)}
              key={index}
              onPress={() => {
                linkTo(`/podcasts/library/topics/${item.slug}`);
              }}
            />
          )}
          numColumns={2}
          style={{ paddingHorizontal: 20 }}
          onEndReachedThreshold={0}
          onEndReached={onNextPage}
        ></FlatList>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  items_wrapp: {
    flex: 1,
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
  },
});
