import * as React from "react";
import { useLinkTo } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";
import { ChevronButton } from "./ChevronButton";

export default function BackButton({ backColor = "black", backLinkTo, skipCheck }) {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  return (
    <ChevronButton
      color={backColor}
      size={34}
      onPress={() => {
         if (!skipCheck && navigation.canGoBack()) {
          navigation.goBack();
        } else {
          linkTo(backLinkTo);
        }
      }}
    />
  );
}
