import { StyleSheet, Text, View, Pressable, Platform} from "react-native";
import  React, { useRef, useLayoutEffect} from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { get, isNil, omitBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "@podcasts/store/user";
import {} from "@podcasts/store";
import { Formik } from "formik";
import { updateStudentProfile } from "@podcasts/store/user";
import { useToast } from "react-native-toast-notifications";
import { editProfileSchema } from "@podcasts/schemas";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Icon from "../../components/svg/icon";

const SaveButton = ({ onPress }) => {
  return <Pressable onPress={onPress} style={{ width: 76, height: 29, backgroundColor: "#042B59", borderRadius: 1000, justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>
  <Text style={{ color: 'white', marginRight: 10, fontFamily: 'SofiaProMedium', fontSize: 12}}>Save</Text>
  <Icon name="Save2" width="13" />
</Pressable>
}
export default function EditPage({ navigation }) {
  const dispatch = useDispatch();
  const formRef = useRef()
  const { user } = useSelector(userSelector);

  useLayoutEffect(() => {
    navigation.setOptions({ 
      headerRight: () => <View style={{ marginRight: Platform.OS === 'web' ? 15 : 0}}>
            <SaveButton onPress={() => { 
          formRef.current.handleSubmit()
        }}/>
        </View> })
  },[])

  return (
    <KeyboardAwareScrollView  extraScrollHeight={20} >
      <Formik
        validationSchema={editProfileSchema}
        innerRef={formRef}
        initialValues={{
          email: {
            new_email: undefined,
            confirm_email: undefined,
          },
          password: {
            current_password: undefined,
            new_password: undefined,
            confirm_password: undefined,
          },
          phone: get(user, "phone", ""),
          city: get(user, "city", ""),
          school: get(user, "school", ""),
          difficulty: get(user, "difficulty", ""),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const email = get(values, "email.new_email", null);
          const password = get(values, "password.new_password", null);
          const current_password = get(
            values,
            "password.current_password",
            null
          );

          const payload = { ...values, email, password, current_password };

          const { error } = await dispatch(
            updateStudentProfile(omitBy(payload, isNil))
          );
          if (error) {
            toast.show(error.message, { placement: "top", type: "danger" });
          } else {
            toast.show("Profile was update successfully", {
              placement: "bottom",
              type: "success",
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <View style={styles.container}>
            <View style={styles.page_wrapp}>
              <Text style={styles.page_title}>Edit your email:</Text>
              <View style={styles.form_control}>
                <CustomInput
                  label="Your new email address:"
                  autoCapitalize="none"
                  keyboardType={'email-address'}
                  onChangeText={handleChange("email.new_email")}
                  onBlur={handleBlur("email.new_email")}
                  value={values.email.new_email}
                  error={
                    values.email.new_email && values.email.new_email
                      ? errors.email?.new_email
                      : null
                  }
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  label="Confirm email:"
                  autoCapitalize="none"
                  keyboardType={'email-address'}
                  onChangeText={handleChange("email.confirm_email")}
                  onBlur={handleBlur("email.confirm_email")}
                  value={values.email.confirm_email}
                  error={
                    values.email.confirm_email && values.email.confirm_email
                      ? errors.email?.confirm_email
                      : null
                  }
                  // placeholder={"Confirm email:"}
                />
              </View>
              <Text style={styles.page_title}>Edit your password:</Text>
              <View style={styles.form_control}>
                <CustomInput
                  label="Enter your current password:"
                  onChangeText={handleChange("password.current_password")}
                  onBlur={handleBlur("password.current_password")}
                  value={values.password.current_password}
                  secureTextEntry={true}
                  autoCapitalize="none"
                  error={
                    errors.password?.current_password &&
                    touched.password?.current_password
                      ? errors.password?.current_password
                      : null
                  }
                  // placeholder={"your current password:"}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  label="Your new password:"
                  onChangeText={handleChange("password.new_password")}
                  onBlur={handleBlur("password.new_password")}
                  value={values.password.new_password}
                  secureTextEntry={true}
                  autoCapitalize="none"
                  error={
                    errors.password?.new_password &&
                    touched.password?.new_password
                      ? errors.password?.new_password
                      : null
                  }
                  // placeholder={"your current password:"}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  label="Confirm password:"
                  onChangeText={handleChange("password.confirm_password")}
                  onBlur={handleBlur("password.confirm_password")}
                  value={values.password.confirm_password}
                  secureTextEntry={true}
                  autoCapitalize="none"
                  error={
                    errors.password?.confirm_password &&
                    touched.password?.confirm_password
                      ? errors.password?.confirm_password
                      : null
                  }
                  // placeholder={"confirm_password"}
                />
              </View>
              <Text style={styles.page_title}>
                Edit your personal information:
              </Text>
              <View style={styles.form_control}>
                <CustomInput
                  label="Education:"
                  // placeholder="University of Toronto"
                  onChangeText={handleChange("school")}
                  onBlur={handleBlur("school")}
                  value={values.school}
                  error={errors.school && touched.school ? errors.school : null}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  label="City:"
                  // placeholder="Toronto, ON"
                  onChangeText={handleChange("city")}
                  onBlur={handleBlur("city")}
                  value={values.city}
                  error={errors.city && touched.city ? errors.city : null}
                />
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  label="Phone number:"
                  placeholder={"( _ _ _ )  _ _ _   _ _ _ _"}
                  keyboardType="phone-pad"
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={values.phone}
                  error={errors.phone && touched.phone ? errors.phone : null}
                />
              </View>
              <View style={styles.btn_box}>
                <CustomButton
                  name={"Save"}
                  color="blue"
                  customIcon="save"
                  onPress={handleSubmit}
                />
              </View>
            </View>
          </View>
        )}
      </Formik>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    width: "100%",
    flex: 1,
    alignSelf: "center",
  },
  page_wrapp: {
    flex: 1,
    paddingHorizontal: 18,
    paddingTop: 15,
    paddingBottom: 40,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 15,
    marginTop: 10,
    fontFamily: 'Poppins_700Bold'
  },
  form_control: {
    marginBottom: 15,
  },
  btn_box: {
    marginTop: 20,
  },
});
