import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function CirclePlay({ color = "#fff" }) {
  return (
    <View style={[styles.play_box, { borderColor: color }]}>
      <Icon name="play-emty" size={27} color={color} />
    </View>
  );
}

const styles = StyleSheet.create({
  play_box: {
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 1,
  },
});
