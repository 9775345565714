import { StyleSheet, View, FlatList } from "react-native";
import { useSavedSpeakersAppPage } from "@podcasts/hooks";
import { useLinkTo } from "@react-navigation/native";
import TopicsList from "../../components/TopicsList";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";

export default function SavedSpeakersPage() {
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    order,
    onNextPage,
    isSavedSpeaker,
    onSaveSpeaker,
    isAuthenticated,
    toggleOrder
  } = useSavedSpeakersAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "fname",
  });

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <PageHeaderSort
              title="Saved Speakers"
              onPress={() => toggleOrder()}
              order={order}
            />
        </View>
      <View style={styles.items_wrapp}>
        <FlatList
          data={data}
          renderItem={({ item, index }) => (
            <TopicsList
            {...item}
            key={index}
            index={index}
            title={`${item.title} ${item.name}`}
            totalCount={data.length}
            authenticated={isAuthenticated}
            saved={isSavedSpeaker(item.id)}
            onSave={() => onSaveSpeaker(item)}
            onPress={() => {
              linkTo(`/podcasts/library/speakers/${item.slug}`);
            }}
          />
          )}
          numColumns={2}
          style={{ paddingHorizontal: 20 }}
          onEndReachedThreshold={0}
          onEndReached={onNextPage}
        ></FlatList>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    // paddingHorizontal: 20,
  },
  items_wrapp: {
    flex: 1,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    paddingHorizontal: 20,
    marginTop: 15,
    marginBottom: 25,
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20,
  },
});
