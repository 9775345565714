import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  Image,
  SafeAreaView,
} from "react-native";
import Icon from "../../components/icons/CustomIcon";
import CustomButton from "../../components/CustomButton";
import { useLinkTo } from "@react-navigation/native";
import TabsHeader from "../../components/TabsHeader";

export default function NotLoggetPage({ navigation }) {
  const linkTo = useLinkTo();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <View style={styles.container}>
        <View>
          <View style={styles.lock_box}>
            <View style={styles.lock_ico}>
              <Icon name="lock" size={30} color="#000" />
            </View>
            <Text style={styles.lock_text}>
              Apply now to{" "}
              <Text style={[styles.lock_text, { fontFamily: "SofiaProBold" }]}>
                earn $15
              </Text>{" "}
              for each class you listen to and discuss with one person.
            </Text>
          </View>
        </View>

        <View>
          <View style={styles.btn}>
            <CustomButton
              name={"Apply now"}
              color="blue"
              onPress={() => {
                linkTo(`/application`);
              }}
            />
          </View>

          <View style={styles.btn}>
            <CustomButton
              name={"Login"}
              color="white"
              onPress={() => {
                linkTo(`/login`);
              }}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: 20,
    width: "100%",
    maxWidth: 600,
    alignSelf: "space-between",
    paddingHorizontal: 20,
    paddingBottom: 50,
  },
  lock_box: {
    marginTop: 110,
    alignItems: "center",
  },
  lock_ico: {
    width: 75,
    height: 75,
    borderRadius: 10,
    backgroundColor: "#FEEFC9",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  lock_text: {
    textAlign: "center",
    fontSize: 14,
    lineHeight: 24,
    color: "#000",
    fontFamily: 'SofiaPro'
  },
  btn: {
    marginBottom: 15,
  },
});
