import { StyleSheet, Text, View, FlatList } from "react-native";
import PlayRandom from "../../components/icons/PlayRandom";
import { useSavedPodcastsAppPage } from "@podcasts/hooks";
import PodcastListItem from "../../components/PodcastListItem";
import { useLinkTo } from "@react-navigation/native";
import { PoppinsBoldText } from "../../components/PopinsText";

export default function SavedPodcastsPage() {
  const linkTo = useLinkTo();
  const {
    data: { data = [] },
    onNextPage,
    isSavedPodcast,
    onSavePodcast,
    isAuthenticated,
  } = useSavedPodcastsAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "title",
  });

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <PoppinsBoldText style={styles.page_title}>
          Saved Podcasts
        </PoppinsBoldText>
      </View>
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <PodcastListItem
            key={item.id}
            index={index}
            onPress={() => {
              linkTo(`/podcasts/${item.slug}`);
            }}
            onSave={() => onSavePodcast(item)}
            isAuthenticated={isAuthenticated}
            saved={isSavedPodcast(item.id)}
            item={item}
          />
        )}
        onEndReachedThreshold={0.8}
        onEndReached={onNextPage}
      ></FlatList>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  items_wrapp: {
    paddingBottom: 30,
  },
  page_header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    marginTop: 25,
    marginBottom: 15,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    fontWeight: "bold",
  },
});
