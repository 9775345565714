// import "react-native-gesture-handler";
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Dimensions, Text } from "react-native";
import { useFonts } from "expo-font";
import { MenuProvider } from "react-native-popup-menu";
import RootNavigator from "./navigation/RootNavigator";
import { store } from "@podcasts/store";
import { Provider } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import AnalyticsProvider from './Analytics';
import Toast from "react-native-toast-notifications";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import LogRocket from '@logrocket/react-native';

import {
  Poppins_100Thin,
  Poppins_100Thin_Italic,
  Poppins_200ExtraLight,
  Poppins_200ExtraLight_Italic,
  Poppins_300Light,
  Poppins_300Light_Italic,
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_500Medium,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_700Bold_Italic,
  Poppins_800ExtraBold,
  Poppins_800ExtraBold_Italic,
  Poppins_900Black,
  Poppins_900Black_Italic,
} from "@expo-google-fonts/poppins";
import { Sofia_400Regular } from "@expo-google-fonts/sofia";
import { QueryClient, QueryClientProvider } from "react-query";

// import { API_URL } from "@env"


const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false
  //   },
  // },
});

export default function App() {
  const windowWidth = Dimensions.get("window").width;
  console.log(process.env.API_URL);
  // console.log(API_URL);
  useEffect(() => {
    axios.defaults.baseURL = process.env.API_URL;
    LogRocket.init('gbijfc/podcast-fellowship');
  }, []);

  const [fontsLoaded] = useFonts({
    IcoMoon: require("./resources/fonts/icomoon.ttf"),
    IcoMoon2: require("./resources/fonts/icomoon2.ttf"),
    SofiaPro: require("./assets/fonts/SofiaProLightAz.otf"),
    SofiaProBold: require("./assets/fonts/SofiaProBold.ttf"),
    SofiaProMedium: require("./assets/fonts/SofiaProMedium.ttf"),
    SofiaProRegularItalic: require("./assets/fonts/SofiaProRegular-Italic.ttf"),
    Poppins_100Thin,
    Poppins_100Thin_Italic,
    Poppins_200ExtraLight,
    Poppins_200ExtraLight_Italic,
    Poppins_300Light,
    Poppins_300Light_Italic,
    Poppins_400Regular,
    Poppins_400Regular_Italic,
    Poppins_500Medium,
    Poppins_500Medium_Italic,
    Poppins_600SemiBold,
    Poppins_600SemiBold_Italic,
    Poppins_700Bold,
    Poppins_700Bold_Italic,
    Poppins_800ExtraBold,
    Poppins_800ExtraBold_Italic,
    Poppins_900Black,
    Poppins_900Black_Italic,
    Sofia_400Regular,
  });

   if (!fontsLoaded) return <></>;

   
  return (
    <GestureHandlerRootView style={{ flex: 1}}>
      <ActionSheetProvider>
      <AnalyticsProvider>
        <MenuProvider>
          <QueryClientProvider client={queryClient}>
            <Provider store={store} animationType="zoom-in">
              <RootNavigator />
            </Provider>
          </QueryClientProvider>
          <Toast
            successColor="#042B59"
            normalColor="white"
            duration={1500}
            offsetTop={100}
            offsetBottom={100}
            check={"check"}
            ref={(ref) => (global["toast"] = ref)}
            style={{ width: windowWidth, paddingVertical: 20 }}
            renderToast={(toastOptions) => {
              const commonStyle = {
                padding: 20,
                width: "100%",
                maxWidth: 355,
                borderRadius: 10,
                fontWeight: "500",
                fontSize: 14,
                fontFamily: 'SofiaProMedium'
              };
              const styles = {
                success: {
                  backgroundColor: "#042B59",
                  color: "white",
                },
                normal: {
                  backgroundColor: "white",
                  color: "#000000",
                },
                danger: {
                  backgroundColor: "red",
                  color: "white",
                },
                warning: {
                  backgroundColor: "#f9bf34",
                  color: "white",
                },
              };
              return (
                <Text style={[styles[toastOptions.type], commonStyle]}>
                  {toastOptions.message}
                </Text>
              );
            }}
          />
        </MenuProvider>
        </AnalyticsProvider>
      </ActionSheetProvider>
    </GestureHandlerRootView>
  );
}
