import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    SafeAreaView,
    TouchableWithoutFeedback,
    Keyboard,
    Modal
  } from "react-native";
  import { Formik } from "formik";
  import CustomButton from "../components/CustomButton";
  import CustomInput from "../components/CustomInput";
  import { useForgotPassword } from '@podcasts/hooks';
  import { resetPassSchema } from "@podcasts/schemas";
  import { useLinkTo } from "@react-navigation/native";
  import { PoppinsBoldText } from "../components/PopinsText";
  
  export default function ForgotPassword({}) {
    const linkTo = useLinkTo();
    const mutation = useForgotPassword();
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
        <TouchableWithoutFeedback onPress={() =>{ 
          }} >
          <View style={styles.login}>
            <PoppinsBoldText style={styles.text_welcome}>
              Forgot password?
            </PoppinsBoldText>

            <Text style={{ fontFamily: 'SofiaPro'}}>
             Type the email address to recover your password.
            </Text>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={resetPassSchema}
              onSubmit={async ({ email }) => {

                try {
                  const { error, data: { message }} = await mutation.mutateAsync({ email });

                  toast.show(message, {
                    placement: "bottom",
                    type: "success",
                  });
                    linkTo(`/login`);

                } catch(e) {
                  toast.show("User with current email not found", {
                    placement: "bottom",
                    type: "danger",
                    offset: 20,
                  });
                }
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <View style={{ width: "100%" }}>
                  <View style={styles.form_control}>
                    <CustomInput
                      onChangeText={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      error={errors.email && touched.email ? errors.email : null}
                      label={"Email *"}
                      autoCapitalize="none"
                      keyboardType={'email-address'}
                      placeholder={"Enter your email"}
                    />
                  </View>
  
                  <View style={styles.login_btn}>
                    <CustomButton
                      name={"Send"}
                      color="blue"
                      onSubmitEditing={Keyboard.dismiss}
  
                      onPress={handleSubmit}
                    />
                  </View>
                </View>
              )}
            </Formik>
          </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </SafeAreaView>
    );
  }
  
  const styles = StyleSheet.create({
    login: {
      flex: 1,
      height: "100%",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "87%",
      alignSelf: "center",
      paddingBottom: 40,
      marginTop: 50,
      marginBottom: 50,
    },
    text_welcome: {
      fontSize: 22,
      textAlign: "center",
      marginTop: 40,
      marginBottom: 20,
    },
    form_control: {
      marginTop: 15,
      width: "100%",
    },
    login_btn: {
      width: "100%",
      marginTop: 35,
    },
  });
  