import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Alert,
  ScrollView,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  Keyboard
} from "react-native";
import { Formik } from "formik";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import { useDispatch } from "react-redux";
import { signIn } from "@podcasts/store/user";
import { loginSchema } from "@podcasts/schemas";
import { useLinkTo } from "@react-navigation/native";
import { PoppinsBoldText } from "../components/PopinsText";
import useMixpanel from '../Analytics/hook';

export default function Login({}) {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const linkTo = useLinkTo();
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
      <TouchableWithoutFeedback onPress={() =>{ 
        // Keyboard.dismiss()
        }} >
        <View style={styles.login}>
          <PoppinsBoldText style={styles.text_welcome}>Welcome back!</PoppinsBoldText>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={async (values) => {
               const { error } = await dispatch(signIn(values));
              if (error) {
                toast.show("Email or password is invalid", {
                  placement: "bottom",
                  type: "danger",
                  offset: 20,
                });

                mixpanel.track("Log in", {"Success": "no"});

              } else {
                toast.show("You are successfuly logged in", {
                  placement: "bottom",
                  type: "success",
                });

                mixpanel.track("Log in", {"Success": "yes"});
                 linkTo(`/podcasts/featured`);
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <View style={{ width: "100%" }}>
                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                    error={errors.email && touched.email ? errors.email : null}
                    label={"Email *"}
                    autoCapitalize="none"
                    keyboardType={'email-address'}
                    placeholder={"Enter your email"}
                  />
                </View>

                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                    error={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                    label={"Password *"}
                    secureTextEntry={true}
                    autoCapitalize="none"
                    onSubmitEditing={Keyboard.dismiss}

                    placeholder={"Enter your password"}
                  />
                </View>

                <View style={styles.login_btn}>
                  <CustomButton
                    name={"Login"}
                    color="blue"
                    onSubmitEditing={Keyboard.dismiss}

                    onPress={handleSubmit}
                  />
                </View>
              </View>
            )}
          </Formik>

          <TouchableOpacity
            style={styles.button_fargot}
            onPress={() => { linkTo('/forgot-password')}}
          >
            <Text style={styles.text_fargot}>Forgot password?</Text>
          </TouchableOpacity>

          <View style={styles.skip_login}>
            <Text style={styles.text_dont}>Don`t have an account? Apply now or you can still browse our library of podcasts!</Text>
            <View>
              <CustomButton
                name={"Apply now"}
                color="white"
                onPress={() => {
                  linkTo("/application");
                }}
              />
            </View>

            <TouchableOpacity
              style={styles.button_fargot}
              onPress={() => {
                linkTo(`/podcasts/featured`);
              }}
            >
              <Text style={styles.text_skip}>Browse as a Guest</Text>
            </TouchableOpacity>
          </View>
        </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    width: "87%",
    alignSelf: "center",
    paddingBottom: 40,
    marginTop: 50,
    marginBottom: 50,
  },
  text_welcome: {
    fontSize: 22,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 20,
  },
  text_fargot: {
    fontSize: 15,
    fontWeight: "500",
    textAlign: "center",
    color: "#042B59",
    fontFamily: 'SofiaProMedium'
  },
  text_skip: {
    fontSize: 15,
    textAlign: "center",
    fontWeight: "500",
    color: "#042B59",
    fontFamily: 'SofiaProMedium'
  },
  text_dont: {
    fontSize: 16,
    fontWeight: "normal",
    textAlign: "center",
    color: "black",
    marginBottom: 15,
    fontFamily: "SofiaPro",
  },

  button_fargot: {
    marginTop: 35,
    alignSelf: "center",
  },
  form_control: {
    marginTop: 15,
    width: "100%",
  },
  login_btn: {
    width: "100%",
    marginTop: 35,
  },
  skip_login: {
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 60,
  },
});
