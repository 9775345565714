import { StyleSheet, Text, View, TouchableOpacity, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";
import Star from "./icons/Star";
import { get } from "lodash";

export default function RecentlyViewed({
  title,
  duration,
  topics,
  saved = true,
  onSave,
  onPress
}) {
  return (
    <Pressable onPress={onPress}>
      <View style={styles.recently_item}>
        <View style={styles.recently_item_content}>
        <View style={styles.recently_info}>
          <Text style={styles.recently_name} ellipsizeMode='tail' numberOfLines={1}>{title}</Text>
          <View style={styles.recently_category}>
            <Text style={styles.category_text}>
              {get(topics, "[0].title", "")}
            </Text>
            <View style={styles.separator}></View>
            <Text style={styles.category_text}>{duration}</Text>
          </View>
        </View>
        <View style={styles.recently_actions}>
          <Star size={20} full={saved} onPress={onSave} />
          <TouchableOpacity style={styles.action_menu}>
            <Icon name="dots-btn-v" size={18} color="#C4C4C4" />
          </TouchableOpacity>
        </View>
        </View>
        <View style={styles.orange_decor}></View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  recently_item: {
    width: "100%",
    marginBottom: 15,
    position: 'relative',
  },
  recently_item_content: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 10,
    width: "100%",
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: "#fff",
    position: 'relative'
  },
  recently_info: {
    flex: 1
  },
  orange_decor: {
    width: "90%",
    height: 5,
    minHeight: 5,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flex: 1,
  },
  recently_name: {
    color: "#021C3C",
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 2,
    fontFamily: "Poppins_500Medium",
  },
  recently_category: {
    flexDirection: "row",
    alignItems: "center",
  },
  category_text: {
    color: "#707070",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'SofiaPro'
  },
  separator: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: "#707070",
    marginHorizontal: 4,
  },
  recently_actions: {
    flexDirection: "row",
    alignItems: "center",
    width: 46,
    marginLeft: 11
  },
  action_menu: {
    width: 10,
    alignItems: "flex-end",
    marginLeft: 15,
  },
});
