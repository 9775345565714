import { useSpeakerAppPage } from "@podcasts/hooks";
import SpeakerBase from "./SpeakerBase";

export default function SpeakerInnerPage({
  route: {
    params: { slug },
  },
  navigation
}) {
  const hook = useSpeakerAppPage(slug);
  console.log(hook.isAuthenticated,'isAuthenticated')

  return <SpeakerBase {...hook} slug={slug} navigation={navigation} />;
}
