import { View, Text, StyleSheet, Pressable} from "react-native";
import QuillEditor, { QuillToolbar } from "react-native-cn-quill";
import { createRef, useCallback, useEffect, useState } from "react";
import { sofiaProFontFace  } from '../fonts/SofiaPro';

const NativeTextEditor = ({ onSend, dismiss }) => {
  const _editor = createRef();
  const [html, setHtml] = useState("");
  const [initialHtml, setInitialHtml] = useState("");
  const [canSend, setCanSend] = useState(false);

  useEffect(() => {
    _editor.current.blur()
  },[dismiss])

  const customStyles = {
    toolbar: {
      provider: (provided) => ({
        ...provided,
        borderTopWidth: 0,
        border: "none",
        width: 250,
      }),
      root: (provided) => ({
        ...provided,
        border: "none",
        backgroundColor: "#042B59",
        width: 250,
      }),
    },
  };

  const formats = [
    [
      "bold",
      "italic",
      { size: ["small", false, "large", "huge"] },
      { list: "ordered" },
      { list: "bullet" },
    ],
  ];

  const onPress = useCallback(() => {
    const reset = () => {
      _editor.current.deleteText(0, 999999);
    };
    onSend({ html, reset });
    setInitialHtml("");
  }, [_editor, html]);

  return (
    <View style={{ width: "100%", height: 120, backgroundColor: "#042B59" }}>
      <QuillEditor
        initialHtml={initialHtml}
        defaultFontFamily={'SofiaPro'}
        customFonts={[{ name: 'SofiaPro', css: sofiaProFontFace}]}
        quill={{
          placeholder: "Write down your thoughts...",
          modules: {
            toolbar: false,
          },
        }}
        onHtmlChange={({ html }) => {
          setHtml(html);
          if (html.replace(/(<([^>]+)>)/gi, "")) {
            setCanSend(true);
          } else {
            setCanSend(false);
          }
        }}
        theme={{
          background: "#042B59",
          color: "white",
          placeholder: "#CCCCCC",
        }}
        style={styles.editor}
        ref={_editor}
      />
      <View
        style={{
          paddingHorizontal: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <QuillToolbar
          editor={_editor}
          options={formats}
          theme={{ size: 30, color: "white", border: "none" }}
          styles={customStyles}
        />
        {html && (
          <Pressable style={styles.button} onPress={onPress}>
            <Text style={styles.text}>Save</Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};
export default NativeTextEditor;

const styles = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: "#FFFFFF",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 1000,
    marginRight: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: "white",
    fontFamily: "SofiaProMedium",

  },
  title: {
    fontWeight: "bold",
    alignSelf: "center",
    paddingVertical: 10,
  },
  editor: {
    padding: 0,
    width: "100%",
    backgroundColor: "#042B59",
  },
});
