import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Pressable,
  FlatList,
  Platform
} from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { logOut } from "@podcasts/store/user";
import { useDispatch } from "react-redux";
import Icon from "./icons/CustomIcon";
import { useIsAuthenticated } from "@podcasts/hooks";
import { userSelector } from "@podcasts/store/user";
import { useSelector } from "react-redux";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Linking } from 'react-native';

function CustomDrawerContent(props) {
  const dispatch = useDispatch();
  const linkTo = useLinkTo();
  const isAuthenticated = useIsAuthenticated();
  const [mainDrawer, setMainDrawer] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const { user } = useSelector(userSelector);
  const insets = useSafeAreaInsets();

  const toggleMainDrawer = () => {
    setMainDrawer(true);
    setFilteredItems([]);
  };

  const onItemParentPress = (key) => {
    const filteredMainDrawerRoutes = props.drawerItems.find((e) => {
      return e.key === key;
    });

    if (!filteredMainDrawerRoutes?.routes) {
      props.navigation.toggleDrawer();
      linkTo(filteredMainDrawerRoutes.path);
    } else {
      setMainDrawer(false);
      setFilteredItems(filteredMainDrawerRoutes);
    }
  };

  const onLogout = () => {
    dispatch(logOut());
    props.navigation.reset({
      index: 0,
      routes: [{ name: Platform.OS === 'web'? 'Dashboard' : 'GetStarted'}]
    })
  };

  function renderMainDrawer() {
    return (
      <View style={{ flex: 1 }}>
        <View style={styles.drawer_header}>
          <View style={styles.header_info}>
            <View>
              <View style={styles.drawer_user}>
                {!isAuthenticated ? (
                  <Icon name="user-light" size={17} color="#fff" />
                ) : (
                  <Image style={styles.user_img} source={user.image} />
                )}
              </View>
              <View style={styles.user_decor}></View>
            </View>
            <View style={styles.drawer_login}>
              {!isAuthenticated ? (
                <View style={styles.login_links}>
                  <View>
                    <Pressable
                      onPress={() => {
                        linkTo("/application");
                      }}
                    >
                      <Text style={styles.login_text}>Apply</Text>
                    </Pressable>
                  </View>
                  <Text style={[styles.login_text, { marginHorizontal: 5 }]}>
                    /
                  </Text>
                  <View>
                    <Pressable
                      onPress={() => {
                        linkTo("/login");
                      }}
                    >
                      <Text style={styles.login_text}>Login</Text>
                    </Pressable>
                  </View>
                </View>
              ) : (
                <Text style={styles.login_text}>Welcome, {user?.fname}</Text>
              )}
            </View>
          </View>
          <Pressable onPress={() => props.navigation.toggleDrawer()}>
            <View style={styles.drawer_close}>
              <Icon name="close-dark" size={16} color="#fff" />
            </View>
          </Pressable>
        </View>

          <FlatList
          style={styles.mainDrawerItems}
          ListFooterComponent={isAuthenticated ? renderLogoutBtn() : <></>}

           data={props.drawerItems}
           renderItem={({ item: parent, index }) => {
            return       <View key={parent.key}>
            <TouchableOpacity
              key={parent.key}
              testID={parent.key}
              onPress={async () => {
                 onItemParentPress(parent.key);
              }}
            >
              <View style={styles.item_wrapp}>
                <View style={styles.parentItem}>
                  <View style={styles.icon_menu}>{parent.icon}</View>
                  <Text style={styles.title}>{parent.title}</Text>
                </View>
                {parent.hasChild && (
                  <View style={styles.child_icon}>
                    <Icon name="chevron-right-dark" size={14} color="#fff" />
                  </View>
                )}
              </View>
            </TouchableOpacity>
          </View>
           }}/>
          {/* {isAuthenticated && renderLogoutBtn()} */}
        </View>
    );
  }

  function renderBottomDrawer({ onPress, mainDrawer }) {
    return (
      <View style={styles.bottomDrawer}>
       { props.draweBottomItems.filter(item => mainDrawer ? !item?.library : item?.library ).map((route) => (
          <Pressable key={route.key} onPress={() => onPress(route)} style={{ display: 'flex',flexDirection: 'row', alignItems: 'center',marginBottom: 25        }}>
            {route?.icon && <View style={{ marginRight: 20}}>{route.icon}</View>}
            <Text style={styles.bottomDrawerItem}>{route.title}</Text>
          </Pressable>
        ))}
      </View>
    );
  }

  function renderFilteredItemsDrawer({ onPress }) {
    return (
      <View style={{ flex: 1}}>  
        <View style={styles.inner_header}>
          <TouchableOpacity
            onPress={() => toggleMainDrawer()}
            style={styles.backButtonRow}
          >
            <Icon name="chevron-left-dark" size={12} color="#fff" />
            <Text style={[styles.backButtonText, styles.title]}>{"Back"}</Text>
          </TouchableOpacity>
        </View>
   
          <FlatList
            style={styles.mainDrawerItems}
            data={filteredItems.routes} 
            renderItem={({ item: route}) => {
              return    <TouchableOpacity
              key={route.routeName}
              testID={route.routeName}
              onPress={() => onPress(route)}
              style={styles.parentItem}
            >
              <View style={styles.icon_menu}>{route.icon}</View>
              <Text style={styles.title}>{route.title}</Text>
            </TouchableOpacity>
            }}/>
        </View>
    );
  }

  function renderLogoutBtn() {
    return (
      <View >
        <TouchableOpacity onPress={onLogout} testID="customDrawer-logout">
          <View style={styles.parentItem}>
            <Text style={styles.title}>{"Log out"}</Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View
      style={[styles.drawerContainer, { paddingTop: insets.top}]}
      //  forceInset={{ top: "always", horizontal: "never", vertical: 'never' }}
       contentInsetAdjustmentBehavior="never"
    >

      {mainDrawer
        ? renderMainDrawer()
        : renderFilteredItemsDrawer({
            onPress: (route) => {
              linkTo(route.path);
            },
          })}
      {renderBottomDrawer({
        onPress: async (route) => {
            linkTo(route.path);
        },
        mainDrawer
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    height: 100,
    flexDirection: "row",
    paddingVertical: 28,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 100,
    height: 75,
  },
  drawerContainer: {
    flex: 1,
    justifyContent: "space-between",
    paddingBottom: 0,
  },
  container: {
    flex: 1,
    zIndex: 1000,
    justifyContent: "space-between",
  },
  centered: {
    alignItems: "center",
  },
  parentItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 25,
  },
  item_wrapp: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: "#F0F0F0",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: 'SofiaPro',
    fontSize: 16,
    lineHeight: 16,
  },
  inner_header: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 33,
    paddingLeft: 46,
    marginBottom: 50,
  },
  backButtonRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  backButtonText: {
    fontSize: 13,
    lineHeight: 13,
    marginLeft: 10,
    color: "#fff",
  },

  bottomDrawer: {
    backgroundColor: "#042B59",
    justifyContent: "flex-start",
    paddingLeft: 50,
    paddingTop: 30,
    paddingBottom: 50,
    // position: 'absolute',
    bottom:0,
    width: '100%'
  },
  bottomDrawerItem: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    fontFamily: 'SofiaPro',
    lineHeight: 16,
    color: "#fff",
    },
  mainDrawerItems: {
    // flex: 1,
    paddingHorizontal: 50,

  },
  drawer_header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingTop: 18,
    marginBottom: 50,
  },
  drawer_user: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FCB912",
    marginRight: 18,
  },
  user_decor: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#fff",
    position: "absolute",
    left: 2,
    top: 1,
    zIndex: -1,
  },
  header_info: {
    flexDirection: "row",
    alignItems: "center",
  },
  login_links: {
    flexDirection: "row",
    alignItems: "center",
  },
  login_text: {
    fontSize: 13,
    lineHeight: 13,
    color: "#fff",
    fontFamily: 'SofiaPro'
  },
  drawer_close: {
    width: 40,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  user_img: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  icon_menu: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2F5075",
    marginRight: 15,
  },
  child_icon: {
    width: 30,
    height: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2F5075",
    marginBottom: 25,
    marginLeft: 20,
  },
});

export default CustomDrawerContent;
