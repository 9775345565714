import { StyleSheet, View, Pressable } from "react-native";
import Icon from "./CustomIcon";

export default function Bookmark({
  size,
  color = "#000",
  empty = false,
  onPress,
}) {
  return (
    <Pressable onPress={onPress}>
      <View style={styles.bookmark_box}>
        <Icon name="bookmark-empty-dark" size={size} color={color} />
        {empty && (
          <View style={styles.bookmark_after}>
            <Icon name="bookmark-full" size={size - 3} color="#FCB912" />
          </View>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  bookmark_after: {
    width: "90%",
    position: "absolute",
    top: 1,
    left: "10%",
    zIndex: -1,
  },
});
