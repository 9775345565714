import { StyleSheet, View } from "react-native";
import SavedRandomBlock from "../../components/SavedRandomBlock";
import { TopicHeader } from "../../components/TopicHeader";
import PodcastList from "../../components/PodcastList";
import { useLayoutEffect } from 'react';

export default function SeriesBase({
  data: course = {},
  isSavedCourse,
  onSaveCourse,
  isAuthenticated,
  slug,
  backLinkTo = "/podcasts/courses",
  navigation
}) {
  
  useLayoutEffect(() => {
    navigation.setOptions({
      title:  `${course?.title ? `${course.title} - ` : ''}The Podcast Fellowship`
    })
  },[course])

  return (
    <>
      <TopicHeader {...course} backLinkTo={backLinkTo} />
      <View style={styles.container}>
        <SavedRandomBlock
          authenticated={isAuthenticated}
          saved={isSavedCourse(course?.id)}
          onPress={() => onSaveCourse()}
          btnText="Save course"
        />
        <PodcastList params={{ course_slug: [slug] }} podcastSubTitle = 'topic'></PodcastList>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
  items_wrapp: {
    marginTop: 15,
    paddingBottom: 80,
  },
});
