import { StyleSheet, Text, View } from "react-native";
import InputMask from "react-input-mask";

export default function CustomInput({
  label,
  value,
  onChange,
  error,
  onBlur,
  placeholder = "",
  ...rest
}) {
  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <View>
        <InputMask
          {...rest}
          style={{ ...styles.input, borderColor: error? 'red' : 'black' } }
          mask="99/99/9999"
          placeholder={placeholder}
          name={"name"}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <View style={styles.shadow}></View>
      </View>
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },
  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#000000",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },

  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#000000",
    height: 45,
    paddingHorizontal: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
    marginBottom: 4,
    borderStyle: 'solid'
  },

  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: 0,
    zIndex: -1,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    marginTop: 5,
    marginLeft: '5%',
  },
});
