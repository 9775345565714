import { StyleSheet, ScrollView, View, SafeAreaView, PixelRatio } from "react-native";
import FeaturedPodcasts from "../components/FeaturedPodcasts";
import FeaturedTopic from "../components/FeaturedTopic";
import FeaturedSpeaker from "../components/FeaturedSpeaker";
import { size } from "lodash";
import { useFeaturedAppPage } from "@podcasts/hooks";
import { useLinkTo } from "@react-navigation/native";
import TabsHeader from "../components/TabsHeader";
import ApplyNowCtaItem from "../components/ApplyNowCtaItem";
import RefereAFriendCta from '../components/RefereAFriendCta';

export default function FeaturedPage() {
  const linkTo = useLinkTo();
  const {
    topic,
    course,
    speaker,
    podcasts,
    isSavedPodcast,
    isSavedCourse,
    isSavedTopic,
    isSavedSpeaker,
    onSaveSpeaker,
    onSavePodcast,
    onSaveTopic,
    onSaveCourse,
    saved_podcasts_ids,
    isAuthenticated,
  } = useFeaturedAppPage();

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <ScrollView>
        <View style={styles.container}>
          {size(podcasts) > 0 && (
            <FeaturedPodcasts
              items={podcasts}
              saved_ids={saved_podcasts_ids}
              authenticated={isAuthenticated}
              onSavePodcast={onSavePodcast}
              isSavedPodcast={isSavedPodcast}
            />
          )}
          {topic && (
            <FeaturedTopic
              {...topic}
              podcastSubTitle={"speaker"}
              subTitle={topic?.title}
              authenticated={isAuthenticated}
              onFeatureSave={() => onSaveTopic()}
              onSavePodcast={onSavePodcast}
              saved={isSavedTopic(topic?.id)}
              podcastsIds={saved_podcasts_ids}
              title={"Featured Topic"}
              onBannerPress={() => {
                linkTo(`/podcasts/topics/${topic.slug}`);
              }}
              onViewAllPress={() => {
                linkTo("/podcasts/topics");
              }}
            />
          )}
             {speaker && (
            <FeaturedSpeaker
              {...speaker}
              subTitle={`${speaker?.title} ${speaker?.name}`}
              authenticated={isAuthenticated}
              onFeatureSave={() => onSaveSpeaker()}
              saved={isSavedSpeaker(speaker?.id)}
              onSavePodcast={onSavePodcast}
              podcastsIds={saved_podcasts_ids}
              title={"Featured Speaker"}
              onBannerPress={() => {
                linkTo(`/podcasts/speakers/${speaker.slug}`);
              }}
              onViewAllPress={() => {
                linkTo("/podcasts/speakers");
              }}
            />
          )}
          {course && (
            <FeaturedTopic
              {...course}
              podcastSubTitle={"topic"}
              subTitle={course?.title}
              authenticated={isAuthenticated}
              onFeatureSave={() => onSaveCourse()}
              saved={isSavedCourse(course?.id)}
              podcastsIds={saved_podcasts_ids}
              title={"Featured Course"}
              onSavePodcast={onSavePodcast}
              onBannerPress={() => {
                linkTo(`/podcasts/courses/${course.slug}`);
              }}
              onViewAllPress={() => {
                linkTo("/podcasts/courses");
              }}
            />
          )}
          
            <View style={{ marginHorizontal: 20 }}>
              { isAuthenticated ? <RefereAFriendCta/> :  <ApplyNowCtaItem /> }
            </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },

  block_user: {
    width: "100%",
    paddingHorizontal: 20,
  },

  block_podcasts: {
    width: "100%",
    marginTop: 25,
    paddingLeft: 20,
  },

  block_topics: {
    width: "100%",
    marginTop: 20,
    paddingLeft: 20,
  },

  block_speakers: {
    width: "100%",
    marginTop: 20,
    paddingLeft: 20,
  },
});
