import { StyleSheet, View, Text, } from "react-native";
import { Formik } from "formik";
import { useState } from 'react';
import * as yup from "yup";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useCallback, useEffect } from "react";
import BackButton from "../../components/BackButton";
import CustomSelect from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import { useRequestPayoutMutation } from "@podcasts/hooks";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CustomCheckBox from '../../components/CustomCheckBox';
import { useSelector, useDispatch } from "react-redux";
import { dashboardSelector } from "@podcasts/store/dashboard"
import {
  getPodcast,
  getRequestPayOut,
  getRecentlyViewed,
} from "@podcasts/store/dashboard"
import { userSelector } from "@podcasts/store/user";


export default function RequestPayoutPage({
  navigation,
  route: {
    params: { slug },
  },
}) {
  const {
    recently_viewed: { data = [] },
    podcast,
    total_payout_request
  } = useSelector(dashboardSelector);
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  const [showExternalLink, setShowExternalLink] = useState(false);
  const payoutMutation = useRequestPayoutMutation();
  const todayDate = new Date()
  const theDayBefore  = new Date(todayDate.setDate(todayDate.getDate() - 1));
  const [requestedMoreThanAllowed, setRequestedMoreThanAllowed] = useState(false);
 
  useEffect(() => {
    if(slug) {
      dispatch(getPodcast(slug));
      dispatch(getRequestPayOut(slug));
    }
  },[slug]);

  const schema = yup.object().shape({
    rating: yup.number().required().required("Required").nullable(),
    review: yup.string().required().required("Required").nullable(),
    date_learned: yup.date().typeError("Invalid format").required("Required").nullable(),
    is_public: yup.boolean(),
    external_podcast_title: yup.string().nullable()
    .when([], {
      is: () =>  showExternalLink,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
  external_podcast_url: yup.string().nullable()
    .when([], {
      is: () => showExternalLink,
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
  social_event: yup.boolean().nullable(),
  social_event_description: yup.string().nullable()
  });

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => {
        return (
          <BackButton
            {...props}
            backColor={"white"}
            backLinkTo={`/podcasts/${slug}`}
          />
        );
      },
    });
    dispatch(getRecentlyViewed());

  }, [slug]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        await payoutMutation.mutateAsync(values);

        toast.show("Your payout has been requested", {
          placement: "bottom",
          type: "normal",
          offsetBottom: 150,
        });

        navigation.navigate({
          name: "PodcastPage",
          params: { slug, refresh: true },
        });

      } catch (e) {
        if (e.response.data.message === "You have already requested 4 payments in the past 4 weeks. Please indicate your reason for requesting another payment.") {
          toast.show(e.response.data.message, {
            placement: "bottom",
            type: "warning",
            // duration: 500000,
            normalColor: '#e2b203',
            offsetBottom: 150,
          });
          setRequestedMoreThanAllowed(true);
        } else {
          toast.show(e.response.data.message, {
            placement: "bottom",
            type: "danger",
            offsetBottom: 150,
          });
        }
        if (e.response.status === 401) {
          navigation.navigate({
            name: "Login",
            params: { refresh: true },
          });
        }
      }
    },
    [podcast, payoutMutation, slug]
  );

  return (
    <>
      <KeyboardAwareScrollView extraScrollHeight={20} style={styles.container}>
        <Text style={[styles.text, styles.title]}>Request a payout</Text>
        { !showExternalLink && <Text style={[styles.text, styles.paragraph]}>
         {podcast?.title}
        </Text> }
        {podcast?.is_payout_request_sent ? (
              <Text style={[styles.text, styles.paragraph]}>You already requested a payment for this podcast</Text>
            ) : (
        <Formik
          enableReinitialize
          initialValues={{
            review: "",
            rating: null,
            podcast_id: showExternalLink ? null :  podcast?.id,
            date_learned: null,
            external_podcast_title: null,
            external_podcast_url: null,
            is_public: false,
            social_event: null,
            social_event_description: null
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
          }) => (
            <View >
              { !showExternalLink && <View style={styles.form_control}>
                <CustomSelect
                    options={data.map((p) => ({
                      value: p.slug,
                      label: p.title,
                    }))}
                  onChange={(value) => {
                    if (value) {
                      dispatch(getPodcast(value));
                    }
                  }}
                  placeholder={{ label: "Choose a different podcast", value: null }}
                  onBlur={() => { }}
                  label={""}
                />
              </View> }
            {  user.approved_for_external_podcasts ? <View style={styles.form_control}>
                <CustomCheckBox 
                    bg={'#fcb912'}
                    labelColor="white"
                    onChange={(value) => { setShowExternalLink(value)} }
                    onBlur={() => { }}
                    value={showExternalLink}
                    label="External Podcast"
                />
              </View> : <></>}
              { showExternalLink && <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("external_podcast_title")}
                  onBlur={handleBlur("external_podcast_title")}
                  value={values.external_podcast_title}
                  placeholder="Please enter the title"
                  error={errors.external_podcast_title && touched.external_podcast_title ? "Required" : null}
                />
              </View>}
            { showExternalLink && <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("external_podcast_url")}
                  onBlur={handleBlur("external_podcast_url")}
                  value={values.external_podcast_url}
                  placeholder="Please enter the url"
                  error={errors.external_podcast_url && touched.external_podcast_url ? "Required" : null}
                />
              </View>}
              <Text style={[styles.text, styles.title, styles.label]}>
                What date did you review this podcast with your mentor?
              </Text>
              <View style={styles.form_control}>
                <DatePicker
                  onChange={(value) => {
                    setFieldValue("date_learned", value);
                  }}
                  maximumDate={theDayBefore}
                  onBlur={handleBlur("date_learned")}
                  value={values.date_learned}
                  error={
                    errors.date_learned && touched.date_learned
                      ? errors.date_learned
                      : null
                  }
                  placeholder={"mm / dd / yyyy"}
                />
                <Text style={{ marginLeft: 20, marginTop: 5, color: 'white', fontSize: 12 }}>Your mentor will be notified of your payment request.</Text>
              </View>
              <Text style={[styles.text, styles.title,styles.label]}>
                What did you think of this podcast?
              </Text>
              <View style={styles.form_control}>
                <CustomSelect
                  options={[
                    {
                      label: "1",
                      value: 1,
                    },
                    {
                      label: "2",
                      value: 2,
                    },
                    {
                      label: "3",
                      value: 3,
                    },
                    {
                      label: "4",
                      value: 4,
                    },
                    {
                      label: "5",
                      value: 5,
                    },
                  ]}
                  onChange={(d) => {
                    if(d) {
                      setFieldValue("rating", Number(d));
                    } else {
                      setFieldValue("rating", d);
                    }
                  }}
                  placeholder={{ label: "Select", color: "red", value: null }}
                  onBlur={handleBlur("rating")}
                  value={values.rating}
                  error={errors.rating && touched.rating ? errors.rating : null}
                  label={""}
                />
                <Text style={[styles.text, styles.hint]}>
                  (1) = Didn’t love it, (5) = Loved it!
                </Text>
              </View>
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("review")}
                  onBlur={handleBlur("review")}
                  value={values.review}
                  minHeight={175}
                  placeholder="Write any comments you have..."
                  multiline={true}
                  numberOfLines={8}
                  error={errors.review && touched.review ? "Required" : null}
                />
              </View>
              <View></View>

              <View></View>
              {requestedMoreThanAllowed && <View>
                <CustomInput
                  onChangeText={handleChange("request_reason")}
                  onBlur={handleBlur("request_reason")}
                  value={values.request_reason}
                  placeholder="Please enter the reason *"
                  error={errors.request_reason && touched.request_reason ? "Required" : null}
                />
                </View>}
                <View style={styles.form_control}>
                  <CustomCheckBox 
                      bg={'#fff'}
                      labelColor="white"
                      onChange={(value) => setFieldValue("is_public", value)}
                      onBlur={() => { }}
                      value={values.is_public}
                      label="Display my comment on the website. This will be reviewed by a Podcast Admin."
                  />
                </View>
              {(total_payout_request > 0 && total_payout_request % 5 === 0) && (<>
                <Text style={[styles.text, styles.title,styles.label]}>
                  Did you attend an in-person Jewish event/learning session in the last 4 weeks?*
                </Text>
                <View style={styles.form_column}>
                <CustomCheckBox 
                        bg={'#fff'}
                        labelColor="white"
                        onChange={() => setFieldValue("social_event", true)}
                        onBlur={() => { }}
                        value={values.social_event}
                        label="Yes"
                        style={styles.radio_custom}
                    />
                    <CustomCheckBox 
                        bg={'#fff'}
                        labelColor="white"
                        onChange={() => setFieldValue("social_event", false)}
                        onBlur={() => { }}
                        value={values.social_event === false}
                        label="No"
                        style={styles.radio_custom}
                    />
                </View>
                {values.social_event !== null && 
                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("social_event_description")}
                    onBlur={handleBlur("social_event_description")}
                    value={values.social_event_description}
                    minHeight={175}
                    placeholder={`${values.social_event ? "Please elaborate and share your thoughts on the event." : "Please explain/request an excuse."}`}
                    multiline={true}
                    numberOfLines={8}
                    error={errors.social_event_description && touched.social_event_description ? "Required" : null}
                  />
                </View>
                }
              </>)
              }
              <View style={styles.submit}>
                <CustomButton
                  name={"Submit"}
                  color="white"
                  disabled={isSubmitting}
                  onPress={handleSubmit}
                />
              </View>
            </View>
          )}
        </Formik>)}
      </KeyboardAwareScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  text: {
    color: "#fff",
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 25,
    fontFamily: 'SofiaPro'
  },
  label: {
   fontFamily: 'Poppins_700Bold',
   fontSize: 20
  },
  title: {
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 15,
    fontFamily: "Poppins_700Bold",
  },
  container: {
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    paddingHorizontal: 20,
    paddingTop: 35,
    flex: 1,
    backgroundColor: "#042B59",
  },
  form_control: {
    width: "100%",
    marginBottom: 25,
  },
  form_column: {
    width: "100%",
    marginBottom: 25,
    flexDirection: 'row',
    columnGap: 20
  },
  radio_custom: {
    width: 'auto'
  },
  form: {
    paddingTop: 10,
    flex: 1,
  },
  hint: {
    fontSize: 12,
    lineHeight: 23,
    marginTop: 5,
    fontFamily: 'SofiaPro'
  },
  submit: {
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 100,
    marginBottom: 100
  },
});
