import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import CustomCheckBox from "../../components/CustomCheckBox";
import { thirdStepInitialValue } from "@podcasts/store/application";
import CustomRadioButton from "../../components/CustomRadioButton";
import { useSelector } from "react-redux";
import { thirdApplicationStepSchema } from "@podcasts/schemas";
import { PoppinsBoldText } from "../../components/PopinsText";
import CustomSelect from "../../components/CustomSelect";
import { referralMethods } from "@podcasts/constants";
import Title from "../../components/Title";

const radioButtons = [
  {
    id: "1",
    label: "Student",
    value: "Student",
  },
  {
    id: "2",
    label: "Professional",
    value: "Professional",
    selected: true,
  },
];

export default function ThirdStep({ onPrev, onNext }) {
  const initialValues = useSelector(thirdStepInitialValue);

  return (
    <View style={styles.login}>
      <View style={styles.step_number}>
        <Text style={styles.step_number_text}>3</Text>
      </View>
      <Title
        style={{ maxWidth: 279, marginBottom: 22 }}
        source={require("../../assets/titles/application_process_3.jpg")}
      />

      {/* <PoppinsBoldText style={styles.step_title}>
          Are you a student or
        </PoppinsBoldText>
         <PoppinsBoldText style={styles.pf_title}>
          a professional?
        </PoppinsBoldText> */}
      <Formik
        initialValues={{
          ...initialValues,
          position: initialValues.position || "Student",
          accept: false,
        }}
        validationSchema={thirdApplicationStepSchema}
        onSubmit={(values) => {
          onNext(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <View style={styles.form}>
            <View>
              <CustomRadioButton
                layout="row"
                label={"I am a... *"}
                value={values.position}
                options={radioButtons}
                error={
                  errors.position && touched.position ? errors.position : null
                }
                onChange={(value) => setFieldValue("position", value)}
              />
            </View>
            {values.position === "Professional" ? (
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("current_position")}
                  onBlur={handleBlur("current_position")}
                  value={values.current_position}
                  error={
                    errors.current_position && touched.current_position
                      ? errors.current_position
                      : null
                  }
                  label={"Current Position *"}
                />
              </View>
            ) : null}
            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("school")}
                onBlur={handleBlur("school")}
                value={values.school}
                error={errors.school && touched.school ? errors.school : null}
                label={"Where do you go to school? *"}
              />
            </View>
            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("city")}
                onBlur={handleBlur("city")}
                value={values.city}
                error={errors.city && touched.city ? errors.city : null}
                label={"City (Current Residence) "}
              />
            </View>

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("state")}
                onBlur={handleBlur("state")}
                value={values.state}
                error={errors.state && touched.state ? errors.state : null}
                label={"State (Current Residence)*"}
              />
            </View>

            {/* <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("internship")}
                onBlur={handleBlur("internship")}
                value={values.internship}
                error={
                  errors.internship && touched.internship
                    ? errors.internship
                    : null
                }
                label={"How did you hear about this internship? *"}
              />
            </View> */}
            <View style={styles.form_control}>
              <CustomSelect
                options={referralMethods}
                onChange={(v) => {
                  setFieldValue("referral_method", v || null);
                }}
                onBlur={handleBlur("referral_method")}
                value={values.referral_method}
                error={errors.referral_method ? errors.referral_method : null}
                label={"Whom can we thank for referring you to The Podcast Fellowship? *"}
                placeholder={{
                  label: "Please select:",
                  color: "#7E7E7E",
                  fontSize: 15,
                }}
              />
            </View>

            {(values.referral_method === "friend" || values.referral_method === "educator" )&& (
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("referral_name")}
                  onBlur={handleBlur("referral_name")}
                  value={values.referral_name}
                  error={
                    errors.referral_name && touched.referral_name
                      ? errors.referral_name
                      : null
                  }
                  label={`Name of ${values.referral_method.toLowerCase()}:`}
                />
              </View>
            )}

            {values.referral_method === "advertisement" && (
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("referral_name")}
                  onBlur={handleBlur("referral_name")}
                  value={values.other_referral_method}
                  error={
                    errors.referral_name && touched.referral_name
                      ? errors.referral_name
                      : null
                  }
                  label='Where did you see the ad?'
                />
              </View>
            )}

            {values.referral_method === "other" && (
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("other_referral_method")}
                  onBlur={handleBlur("other_referral_method")}
                  value={values.other_referral_method}
                  error={
                    errors.other_referral_method &&
                    touched.other_referral_method
                      ? errors.other_referral_method
                      : null
                  }
                  label={"Please tell us how you heard about the Podcast Fellowship:"}
                />
              </View>
            )}

            <View style={styles.form_control}>
              <CustomInput
                onChangeText={handleChange("other_details")}
                onBlur={handleBlur("other_details")}
                value={values.other_details}
                error={
                  errors.other_details && touched.other_details
                    ? errors.other_details
                    : null
                }
                label={"Other details you would like to share with us"}
              />
            </View>
            <View style={styles.form_control}>
              <CustomCheckBox
                onChange={(value) => {
                  setFieldValue("accept", value);
                }}
                onBlur={handleBlur("accept")}
                value={values.accept}
                labelColor="black"
                error={errors.accept && touched.accept ? errors.accept : null}
                label="With programs in over 30 countries, Olami and its funding partners sometimes use independent verification firms to assure that programs are attended and impactful. If I am accepted into this program I understand that I might be contacted by a verification firm for quality control purposes."
              />
            </View>

            <View style={styles.next_step}>
              <CustomButton
                decorator
                name={"Next step"}
                color="blue"
                onPress={handleSubmit}
              />
            </View>

            <View style={styles.prev_step}>
              <TouchableOpacity onPress={onPrev}>
                <Text style={styles.prev_step_text}>Previous step</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 50,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontWeight: "bold",
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: "100%",
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  pf_title: {
    color: "#FCB912",
    fontSize: 26,
    marginBottom: 22,
  },
  next_step: {
    marginTop: 40,
  },
  prev_step: {
    marginTop: 40,
  },
  prev_step_text: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 18,
  },
});
