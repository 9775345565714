import React from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'

export const Carousel = (props) => {
  const { items, style } = props;
  const key = React.useMemo(() => Math.random().toString(36).slice(2),[items.length])
  const itemsPerInterval = props.itemsPerInterval === undefined
    ? 1
    : props.itemsPerInterval;

  const [interval, setInterval] = React.useState(1);
  const [intervals, setIntervals] = React.useState(1);
  const [width, setWidth] = React.useState(0);

  const init = (width) => {
    // initialise width
    setWidth(width);
    // initialise total intervals
    const totalItems = items.length;
    setIntervals(Math.ceil(totalItems / itemsPerInterval));
  }

  const getInterval = (offset) => {
    for (let i = 1; i <= intervals; i++) {
      if (offset+1 < (width / intervals) * i) {
        return i;
      }
      if (i == intervals) {
        return i;
      }
    }
  }


  return (
    <View key={key} style={styles.container}>
      <ScrollView
        horizontal={true}
        contentContainerStyle={{ ...styles.scrollView, width: `${100 * intervals}%`, paddingBottom: 10 }}
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={(w, h) => init(w)}
        onScroll={data => {
          setWidth(data.nativeEvent.contentSize.width);
          setInterval(getInterval(data.nativeEvent.contentOffset.x));
        }}
        scrollEventThrottle={200}
        pagingEnabled
        decelerationRate="fast"
      >
        { props.children}
      </ScrollView>
      { items.length > 1 && <View style={styles.indicatorContainer}>
        { items.map((_,i) => <View key={i} style={[styles.indicatorItem,{ backgroundColor:  interval === (i + 1) ? '#fcb912' : '#ededed', height: interval === (i + 1) ?  3 : 1}]}/>)} 
      </View>}
    </View>
  )
}

export default Carousel;


const styles = StyleSheet.create({
  indicatorContainer: {
    flexDirection: 'row', 
    height: 3, 
    width: '100%', 
    paddingRight: 20
  },
  indicatorItem: {
    borderRadius: 3, 
    flex: 1
  }
})
