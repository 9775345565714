import { useTopicAppPage } from "@podcasts/hooks";
import TopicBase from "../Topics/TopicBase";

const SavedTopicPage = ({
  route: {
    params: { slug },
  },
  navigation
}) => {
  const hook = useTopicAppPage(slug);

  return <TopicBase {...hook} navigation={navigation} slug={slug} backLinkTo={"/podcasts/library/topics"} />;
};

export default SavedTopicPage;
