import {
  StyleSheet,
  View,
  FlatList,
} from "react-native";
import PlayRandom from "../../components/icons/PlayRandom";
import { useRecentlyViewedApp } from "@podcasts/hooks";
import { PoppinsBoldText } from "../../components/PopinsText";
import PodcastListItem from "../../components/PodcastListItem";
import { useLinkTo } from "@react-navigation/native";

export default function RecentlyViewedPage({ navigation }) {
  const linkTo = useLinkTo();

  const {
    data: { data = [] },
    onSavePodcast,
    isSavedPodcast,
    onNextPage,
  } = useRecentlyViewedApp();

  return (
    <View style={styles.container}>
      <View style={styles.page_header}>
        <PoppinsBoldText style={styles.page_title}>
          Recently viewed
        </PoppinsBoldText>
      </View>
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <PodcastListItem
          key={item.id}
          index={index}
          onPress={() => {
            linkTo(`/podcasts/${item.slug}`);
          }}
          onSave={() => onSavePodcast({...item, id: item.podcast_id})}
          isAuthenticated={true}
          saved={isSavedPodcast(item.podcast_id)}
            item={item}
        />)}
        onEndReachedThreshold={0.9}
        onEndReached={onNextPage}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  items_wrapp: {
    paddingBottom: 30,
  },
  page_header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    marginTop: 25,
    marginBottom: 15,
  },
  page_title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
  },
});
