import { StyleSheet, Text, Pressable, View, Dimensions, Platform } from "react-native";
import PodcastList from "../../components/PodcastList";
import { useState, useLayoutEffect } from "react";
import { useSearchPage } from "@podcasts/hooks";
import { useIsFocused } from "@react-navigation/native";
import Icon2 from "../../components/icons/CustomIcon2";
import SearchFiltersModal from "./SearchFiltersModal";
import SearchInput from "../../components/SearchInput";

const windowWidth = Dimensions.get('window').width;

export default function SearchPage({ navigation }) {
  const { filters,
          onSearchKeyChange,
          toggleFilter } = useSearchPage();
    
  const [visible, setVisible] = useState(false);
  const isFocused = useIsFocused();


  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: (props) => <View style={{  width: windowWidth - (Platform.OS === 'web' ? 91 : 86), marginLeft: 8 }}>
        <SearchInput onKeyChange={onSearchKeyChange} search={filters.search} />
        </View>,
    })
  },[])

  return (
    <>
      <View style={styles.filter_wrapp}>
        <Pressable
          style={styles.filter_link}
          onPress={() => {
            setVisible(true);
          }}
        >
          <Text style={styles.link_text}>Filters</Text>
          <Icon2 name="chevron-select" size={8} color="#000" />
        </Pressable>
      </View>
      {isFocused && (
        <PodcastList search params={{ ...filters, per_page: 15 }} noResult></PodcastList>
      )}
      <SearchFiltersModal
        toggleFilter={toggleFilter}
        filters={filters}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
      />
    </>
  );
}

const styles = StyleSheet.create({
  filter_wrapp: {
    alignItems: "flex-end",
  },
  filter_link: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 17,
    paddingHorizontal: 20,
  },
  link_text: {
    paddingRight: 6,
  },
});
