import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  ImageBackground,
  useWindowDimensions,
} from "react-native";
import Icon from "./icons/CustomIcon";
import Star from "./icons/Star";
import CirclePlay from "./icons/CirclePlay";
import { get } from "lodash";
import { PoppinsBoldText } from "../components/PopinsText";
import { LinearGradient } from 'expo-linear-gradient';

function PodcastWeb({ item, onPress, saved, onSave, authenticated }) {
  const { width } = useWindowDimensions();
  return (
    <Pressable
      onPress={onPress}
      style={[styles.podcast, { width: width - 20 }]}
    >
      <View style={styles.block}>
        <ImageBackground
          source={{ uri: get(item, "topics[0].image", item.image) }}
          resizeMode="cover"
          style={styles.image}
        >
     <LinearGradient
          colors={[ 'rgba(2, 28, 60, .5)','transparent']}  style={styles.gradient}>
          <Text style={[styles.podcast_name, { fontFamily: 'Poppins_600SemiBold'}]}>
              {item.title}
            </Text>
            <Text style={styles.podcast_author}>
              {item.speaker?.fname} {item.speaker?.lname}
            </Text>
            </LinearGradient>
          <View style={styles.bottom_box}>
            <View style={styles.user_level_box}>
              <Icon name="rocket" size={15} color="#F51D00" />
              <View style={styles.user_level_text}>
                <Text style={styles.level_label}>Level:</Text>
                <Text style={styles.level}>{item.level}</Text>
              </View>
            </View>
            <View style={styles.actions}>
              {authenticated && (
                <Pressable>
                  <Star
                    full={saved}
                    color="white"
                    onPress={onSave}
                    size={20}
                  ></Star>
                </Pressable>
              )}
              <View style={{ marginLeft: 15 }}>
                <CirclePlay color="#fff" />
              </View>
            </View>
          </View>
        </ImageBackground>
        <View style={styles.shadow}></View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  podcast: {
    height: 175,
    paddingRight: 20,
  },
  block: {
    flex: 1,
  },
  image: {
    flex: 1,
    overflow: "hidden",
    borderRadius: 20,
    paddingBottom: 15,
    justifyContent: "space-between",
    backgroundColor: "grey",
  },
  gradient: {
    paddingTop: 20,
    paddingHorizontal: 15,
    flex: 1
  },
  podcast_name: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    lineHeight: 23,
    marginBottom: 5,
  },

  podcast_author: {
    color: "white",
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "left",
    lineHeight: 16,
    fontFamily: 'SofiaPro'
  },

  shadow: {
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: -4,
    zIndex: -1,
  },
  bottom_box: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,

  },
  user_level_box: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 8,
    alignItems: "center",
    backgroundColor: "#FFF8E8",
    borderRadius: 5,
  },
  user_level_text: {
    flexDirection: "row",
    alignItems: "center",
  },
  level_label: {
    fontSize: 12,
    lineHeight: 12,
    marginLeft: 7,
    marginRight: 5,
    fontFamily: 'SofiaProBold'
  },
  level: {
    fontSize: 12,
    lineHeight: 12,
    fontFamily: 'SofiaPro'
  },
  actions: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default React.memo(PodcastWeb);
