import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { PoppinsBoldText } from "./PopinsText";
import CustomButton from "./CustomButton";
import { useLinkTo } from "@react-navigation/native";

const RefereAFriendCta = ({ headphones_img = true }) => {
  const linkTo = useLinkTo();

  return (
    <View style={styles.container}>
      {headphones_img && (
        <Image
          style={{ width: 145, height: 129, marginBottom: 30 }}
          source={require("../assets/refer-cta.png")}
        />
      )}
      <Image
        style={{ width: 119, height: 16, marginBottom: 15 }}
        source={require("../assets/cta_icon.png")}
      />
      <PoppinsBoldText style={styles.desc}>
        Refer a friend and receive a $15 referral bonus for every friend that joins the program!
      </PoppinsBoldText>
      <CustomButton
        name={"Refer a friend"}
        color="white"
        onPress={() => {
          linkTo(`/profile/refer`);
        }}
      />
    </View>
  );
};

export default RefereAFriendCta;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#021C3C",
    borderRadius: 15,
    paddingHorizontal: 20,
    paddingVertical: 28,
    alignItems: "center",
    flexDirection: "column",
    marginVertical: 20,
  },
  desc: {
    color: "white",
    textAlign: "center",
    marginBottom: 25,
  },
  already_have: {
    color: "white",
    textAlign: "center",
    marginTop: 25,
    marginBottom: 15,
    fontFamily: "SofiaPro",
    fontSize: 13,
  },
  login: {
    color: "white",
    textAlign: "center",
    fontSize: 15,
    fontFamily: "SofiaProMedium",
  },
});
