import {
  StyleSheet,
  Text,
  View,
  Pressable,
} from "react-native";
import Icon from "./icons/CustomIcon";

export default function MenuItem({
  icon,
  sizeIcon,
  count,
  name,
  onPress,
  message,
  color = "black",
  chevron = true,
  subInfo,
  fontFamily = 'SofiaProMedium',
  style = {}
}) {
  return (
    <Pressable style={[styles.menu_item, style]} onPress={onPress}>
      <View style={[styles.item_inner]}>
        <View style={styles.item_icon_box}>
          <Icon
            name={icon}
            size={sizeIcon}
            color={color === "white" ? "#fff" : "#000"}
          />
        </View>
        <View style={[styles.item_text]}>
          <Text
            style={[
              styles.item_name,
              styles[color],
              { fontFamily: fontFamily },
            ]}
          >
            {name}
          </Text>
          {subInfo && (
            <Text style={[styles.sub_info, styles[color]]}>
              Requested on {subInfo}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.item_inner}>
        {(count || Number.isInteger(count)) ? <Text style={styles.item_count}>{count}</Text> : <></>}

        {message && (
          <View style={styles.item_message}>
            <Text style={styles.message_text}>{message}</Text>
          </View>
        )}

        <View style={styles.link}>
          {chevron && (
            <View style={styles.chevron_box}>
              <Icon name="chevron-right-dark" size={12} color="#000" />
            </View>
          )}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  menu_item: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  item_inner: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  item_name: {
    fontSize: 14,
    lineHeight: 14,
    fontFamily: 'SofiaProMedium'
  },
  item_text: {
    marginLeft: 11,
    position: "relative",
    paddingTop: 4,
    fontFamily: 'SofiaPro'
  },
  black: {
    color: "#000",
  },
  white: {
    color: "#fff",
  },
  item_count: {
    fontSize: 14,
    lineHeight: 30,
    color: "#000",
    fontFamily: 'SofiaProMedium'
  },
  chevron_box: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF8E8",
    borderRadius: 15,
  },
  link: {
    width: 40,
    height: 30,
    alignItems: "flex-end",
  },
  item_message: {
    height: 20,
    minWidth: 20,
    borderRadius: 10,
    backgroundColor: "#F51D00",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 6,
    fontFamily: 'SofiaPro'
  },
  message_text: {
    color: "#fff",
    fontSize: 11,
    fontFamily: 'SofiaPro'
    // fontWeight: 'bold'
  },
  sub_info: {
    fontSize: 12,
    lineHeight: 12,
    fontStyle: "italic",
    marginTop: 6,
    fontFamily: 'SofiaPro'
  },
  item_icon_box: {
    minWidth: 20,
    minHeight: 20,
    justifyContent: "center",
    fontFamily: 'SofiaPro'
  },
});
