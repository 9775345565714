import { StyleSheet, View, Pressable } from "react-native";
import Icon from "./CustomIcon";

export default function FilterIcon({ checked = false }) {
  return (
    <View style={[styles.filter_icon_box, checked && styles.active]}>
      <Icon name="filter-dark" size={18} color={checked ? "#fff" : "#000"} />
    </View>
  );
}

const styles = StyleSheet.create({
  filter_icon_box: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#000",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#FCB912",
    borderColor: "#FCB912",
  },
});
