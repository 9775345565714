import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function Mic({ size, color = "#000", fullColor = "#FCB912" }) {
  return (
    <View style={styles.mic_box}>
      <Icon name="mic-empty-dark" size={size} color={color} />
      <View style={styles.mic_after}>
        <Icon name="mic-full" size={size - 19} color={fullColor} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mic_after: {
    position: "absolute",
    top: 10,
    left: 5,
    zIndex: -1,
  },
});
