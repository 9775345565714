import { StyleSheet, Text, View } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import Icon2 from "./icons/CustomIcon2";

export default function CustomSelect({
  label,
  value,
  onChange,
  error,
  onBlur,
  options = [],
  itemKey = 1,
  placeholder,
}) {
  return (
    <View style={styles.custominput}>
      {label ? <Text style={styles.text_name}>{label}</Text> : <></>}
      <View style={styles.input}>
        <View
          style={{
            backgroundColor: "white",
            height: 45,
            position: "absolute",
            paddingBottom: 10,
            borderRadius: 10,
            width: "100%",
          }}
        >
          <RNPickerSelect
          useNativeAndroidPickerStyle={false}
            itemKey={itemKey}
            value={value}
            placeholder={placeholder || { label: "", color: "red" }}
            style={error ? pickerErrorStyles : value ? pickerSelectSelectedStyles : pickerSelectStyles}
            items={options}
            onValueChange={onChange}
            Icon={() => {
              return (
                <Icon2
                  name="chevron-select"
                  size={11}
                  color="#000"
                  style={{
                    backgroundColor: "white",
                    top: 17,
                    right: 10,
                    position: "absolute",
                  }}
                />
              );
            }}
          />
        </View>
        <View style={styles.shadow}></View>
      </View>
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}



const common = {
  inputIOS: {
    backgroundColor: "white",
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    borderRadius: 10,
    fontFamily: "SofiaPro",
  },
  inputAndroid: {
    backgroundColor: "white",
    height: 45,
    paddingLeft: 10,
    borderWidth: 1,
    fontFamily: "SofiaPro",

    borderRadius: 10
  },
  inputWeb: {
    height: 45,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    appearance: "none",
    fontSize: 14,
    boxSizing: "border-box",
    borderWidth: 1,
    borderColor: 'black',
    color: '#7E7E7E',
    borderRadius: 10,
    fontFamily: "SofiaPro",
  }
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },

  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },
  input: {
    position: "relative",
    height: 45,
  },
  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: -5,
    zIndex: -1,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",

  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    marginTop: 5,
    marginLeft: '5%',
  },
});

const pickerSelectStyles = StyleSheet.create({...common});

const pickerSelectSelectedStyles = StyleSheet.create({
  inputIOS: {
   ...common.inputIOS,
   color: 'black'
  },
  inputAndroid: {
    ...common.inputAndroid,
    color: 'black'
  },
  inputWeb: {
    ...common.inputWeb,
    color: 'black'
  }
});


const pickerErrorStyles = StyleSheet.create({
  inputIOS: {
   ...common.inputIOS,
   borderColor: "red",
  },
  inputAndroid: {
    ...common.inputAndroid,
    borderColor: "red",
  },
  inputWeb: {
    ...common.inputWeb,
    borderColor: "red",
  }
});
