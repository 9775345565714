import { StyleSheet, View, SafeAreaView } from "react-native";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";
import { useLinkTo } from "@react-navigation/native";
import { useSpeakersAppPage } from "@podcasts/hooks";
import TabsHeader from "../../components/TabsHeader";
import TopicsList from "../../components/TopicsList";
import FlatListWithCta from "../../components/FlatListWithCta";

export default function SpeakersPage() {
  const linkTo = useLinkTo();
  const {
    data: { data: list },
    order,
    isSavedSpeaker,
    onNextPage,
    toggleOrder,
    onSaveSpeaker,
    isAuthenticated,
  } = useSpeakersAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "lname",
  });


  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <View style={styles.container}>
        <View style={styles.page_header}>
          <PageHeaderSort
            title="Speakers"
            onPress={() => toggleOrder()}
            order={order}
          />
        </View>
        <FlatListWithCta
          data={list}
          extraData={list}
          isAuthenticated={isAuthenticated}
          numColumns={2}
          renderItem={({ item, index }) => (
            <TopicsList
              {...item}
              totalCount={list.length}
              title={`${item.title} ${item.name}`}
              authenticated={isAuthenticated}
              key={index}
              index={index}
              saved={isSavedSpeaker(item.id)}
              style={styles.items_wrapp}
              onPress={() => {
                linkTo(`/podcasts/speakers/${item.slug}`);
              }}
              onSave={() => onSaveSpeaker(item)}
            />
          )}
          style={{ paddingHorizontal: 20 }}
          onEndReachedThreshold={0.8}
          onEndReached={onNextPage}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  items_wrapp: {},
  items_descr: {
    paddingHorizontal: 10,
    marginBottom: 20,
    fontSize: 12,
    lineHeight: 18,
    color: "#000",
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20,
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
});
