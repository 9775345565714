import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import PodcastPage from "../pages/Podcast/PodcastPage";
import PodcastTopicPage from "../pages/Topics/PodcastTopicPage";
import YourNotes from "../pages/Podcast/YourNotes";
import RequestPayoutPage from "../pages/Podcast/RequestPayoutPage";
import BackButton from "../components/BackButton";
import PodcastSpeakerPage from "../pages/Speakers/PodcastSpeakerPage";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Player } from "../pages/Podcast/PlayerContext";
import { View, Platform } from 'react-native';
import HeaderRight from "../components/HeaderRight";

const Tab = createBottomTabNavigator();

const Stack = createNativeStackNavigator();

export const PodcastPageOptions = ({ navigation }) => ({
  headerStyle: {
    backgroundColor: "#042B59",
    borderBottomWidth: 0,
  },
  title: "",
  headerBackTitle: () => <></>,
  headerLeft: (props) => (
    <BackButton
      backColor="white"
      {...props}
      navigation={navigation}
      replace={"TabsPages"}
    />
  ),
});

const TestStack = () => {
  return (
    <Tab.Navigator tabBar={() => <Player />} screenOptions={{}}>
      <Tab.Screen
        name="PodcastPage"
        component={PodcastPage}
        options={{
          tabBarLabel: "Home",
          headerShown: false,
        }}
      />
      <Tab.Screen
        name="YourNotes"
        component={YourNotes}
        options={{
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};

// const PodcastNavigation = ({ navigation }) => {
//   return (<Tab.Navigator
//     tabBar={() => <Player/>}
//       screenOptions={{}}>
//           <Tab.Screen name="PodcastPage" component={PodcastPage} options={{
//            tabBarLabel: "Home",
//            headerShown: false
//          }}
//       />
//          <Tab.Screen name="YourNotes" component={YourNotes}
//            options={{
//             headerShown: false
//           }}
//       />
//       </Tab.Navigator>);
// };

const PodcastNavigation = ({ navigation }) => {
  return (
    <Stack.Navigator
      screenOptions={{
        contentStyle: {},
      }}
    >
      <Stack.Screen
        name="PodcastStack"
        component={TestStack}
        options={{ 
          headerShown: false     
         }}
      />
      <Stack.Screen
        name="PodcastSpeaker"
        options={{
          headerStyle: {},
          title: "",
          headerShadowVisible: false,
          headerBackTitleVisible: false,
          headerRight:() => <View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'web' ? -4 : 0}}><HeaderRight /></View>,

          // headerRight:() => <View style={{ marginHorizontal: Platform.OS === 'web' ? 20 : 0, marginTop: Platform.OS === 'web' ? -4 : 0}}><HeaderRight /></View>,
        }}
        component={PodcastSpeakerPage}
      />

      <Stack.Screen
        name="PodcastTopic"
        options={{
          headerShown: false,
        }}
        component={PodcastTopicPage}
      />

      <Stack.Screen
        name="RequestPayoutPage"
        options={{
          title: "",
          headerStyle: {
            backgroundColor: "#042B59",
          },
          headerShadowVisible: false,
          headerBackTitleVisible: false,
        }}
        component={RequestPayoutPage}
      />
    </Stack.Navigator>
  );
};

export default PodcastNavigation;
