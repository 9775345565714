import { StyleSheet, Text, View, TextInput } from "react-native";

export default function CustomInput({
  label,
  value,
  onChangeText,
  error,
  onBlur,
  placeholder = "",
  multiline = false,
  numberOfLines = 3,
  minHeight = 45,
  ...rest
}) {
  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <View>
        <TextInput
          {...rest}
          style={[
            multiline ? styles.textarea : styles.input,
            { borderColor: error && "red" },
            { minHeight, fontFamily: "SofiaPro", maxHeight: minHeight },
          ]}
          onChangeText={onChangeText}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          multiline={multiline}
          numberOfLines={10}
          placeholderTextColor={'#7E7E7E'}
        />
        <View style={styles.shadow}></View>
      </View>
      {error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },

  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },

  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    height: 45,
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
  },

  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: -1.5,
    zIndex: -1,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    fontFamily: 'SofiaPro',
    marginLeft: '5%',
    marginTop: 5
  },
});
