import * as React from "react";
import { StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, Pressable } from "react-native";
import UserLevel from "../../components/UserLevel";
import MenuItem from "../../components/MenuItem";
import Share from "../../components/Share";

import SpeakerIconBlock from "../../components/SpeakerIconBlock";
import { get, map } from "lodash";
import { useLinkTo } from "@react-navigation/native";
import { useMemo } from "react";
import { usePodcastAppPage, useRequestPayment } from "@podcasts/hooks";
import { Header } from "@react-navigation/elements";
import { PlayerImpContext } from "./PlayerContext";
import BackButton from "../../components/BackButton";

const menu = [
  {
    icon: "user-light",
    sizeIcon: 20,
    name: "View speaker",
    action: "view_speaker",
    auth_required: false,
  },
  {
    icon: "folder-light",
    sizeIcon: 17,
    name: "View topic",
    path: "",
    action: "view_topic",
    auth_required: false,
  },
  {
    icon: "note-dark",
    sizeIcon: 18,
    name: "Your notes",
    action: "your_notes",
    auth_required: true,
  },
];

const requestMenuItem = {
  icon: "payment-light",
  sizeIcon: 18,
  name: "Request a payout",
  subInfo: "01/12/22",
  path: "RequestPayoutPage",
  action: "request",
  auth_required: true,
};

const shareMenuItem = {
  icon: "share-light",
  sizeIcon: 18,
  name: "Share",
  path: "",
  auth_required: false,
}

export default function PodcastPage({ route, navigation }) {
  const linkTo = useLinkTo();
  const {
    data: podcast,
    isSavedPodcast,
    togglePodcastLibrary,
    isAuthenticated,
    refetch,
  } = usePodcastAppPage(route.params?.slug);
  const { canRequestPayment } = useRequestPayment()
  const list = useMemo(
    () => menu.filter((item) => (item.auth_required ? isAuthenticated : true)),
    [isAuthenticated]
  );
  const PlayerApi = React.useContext(PlayerImpContext);

  React.useEffect(() => {
    if (route.params?.refresh) {
      refetch();
    }
  }, [route.params]);

  React.useEffect(() => {
    if (podcast) {
      PlayerApi?.current?.play?.(podcast);
    }
  }, [podcast]);
   
  // React.useEffect(() => {
  //   if (podcast) {
  //     console.log("play")
  //     PlayerApi?.current?.play?.(podcast);
  //   }
  //   return () => {
  //     PlayerApi?.current?.stop?.();
  //   }
  // },[podcast])

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if(podcast) {
        PlayerApi?.current?.play?.(podcast);
      }
    });
    // return () => {
    //   PlayerApi?.current?.stop?.();
    //   return unsubscribe;
    // }
   
  }, [navigation,podcast]);


  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: "space-between",
        backgroundColor: "#042B59",
      }}
    >
     <StatusBar barStyle="light-content" backgroundColor="#042B59" />
      <Header
        headerShadowVisible={false}
        headerBackTitleVisible={false}
        headerStyle={{
          height: 60,
          backgroundColor: "#042B59",
        }}
        layout={{ height: 100, width: 400 }}
        headerLeft={(props) => (<Pressable style={{ marginHorizontal: 18}} onPress={() => { PlayerApi?.current?.stop?.();}}>
        <BackButton
            backColor="white"
            {...props}
            navigation={navigation}
            replace={"TabsPages"}
          />
        </Pressable>
  
        )}
      />
      <ScrollView>
        <View style={{ paddingHorizontal: 20 }}>
          <UserLevel level={podcast?.level} />
          <Text style={[styles.text, styles.paragraph]}>
            {map(get(podcast, "topics", []), "title").join(",")}
          </Text>
          <Text style={[styles.text, styles.title]}>{podcast?.title}</Text>
          <SpeakerIconBlock {...podcast?.speaker} />
          <View style={styles.single_menu}>
            {isAuthenticated && (
              <MenuItem
                onPress={() => togglePodcastLibrary(podcast)}
                key={"save"}
                chevron={false}
                name={"Save"}
                sizeIcon={20}
                style={{ marginBottom: 23 }}
                icon={
                  isSavedPodcast(podcast?.id) ? "star-full" : "star-empty-light"
                }
                color="white"
              />
            )}

            {list.map((item, i) => (
              <MenuItem
                onPress={() => {
                  if (item.action === "view_speaker") {
                    linkTo(`/podcasts/${podcast.slug}/speaker`);
                    PlayerApi?.current?.stop?.();
                  }
                  if (item.action === "your_notes") {
                    linkTo(`/podcasts/${podcast.slug}/notes`);
                  }

                  if (item.action === "view_topic") {
                    linkTo(`/podcasts/${podcast.slug}/topic`);
                    PlayerApi?.current?.stop?.();
                  }
                }}
                key={i}
                chevron={false}
                color="white"
                {...item}
              />
            ))}



              <Share podcast={podcast}/>

            {canRequestPayment && (
              <MenuItem
                onPress={() => {
                  if (!podcast.requested_date) {
                    linkTo(`/podcasts/${podcast.slug}/payout`);
                  }
                }}
                {...requestMenuItem}
                subInfo={podcast?.requested_date}
                chevron={false}
                color="white"
              />
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  player: {
    height: 100,
    width: "100%",
    border: "none",
    display: "flex",
  },
  container: {
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
    // paddingHorizontal: 20,
    // paddingBottom: 130,
  },
  text: {
    color: "#fff",
  },
  paragraph: {
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 10,
    fontFamily: 'SofiaPro'
  },
  title: {
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 15,
    fontFamily: "Poppins_700Bold",
  },
  single_menu: {
    marginTop: 30,
  },
});
