import { Header } from "@react-navigation/elements";
import { Image, Pressable, Platform, View } from "react-native";
import HeaderRight from "../components/HeaderRight";
import { useLinkTo } from "@react-navigation/native";

const TabsHeader = ({ backgroundColor = "white" }) => {
  const linkTo = useLinkTo();

  return (
    <Header
      headerShadowVisible={false}
      headerBackTitleVisible={false}
      headerStyle={{
        height: 60,
        backgroundColor,
      }}
      layout={{ height: 100, width: 400 }}
      headerRight={() => <View style={{ marginRight: 20}}><HeaderRight /></View>}
      headerLeft={() => (
        <Pressable
          onPress={() => {
              linkTo("/");
          }}
        >
          <Image
            source={require("../assets/logo.png")}
            style={{ width: 120, height: 30, resizeMode: "contain" }}
          />
        </Pressable>
      )}
    />
  );
};
export default TabsHeader;
