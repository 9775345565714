import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function Topics({
  size,
  color = "#000",
  fullColor = "#FCB912",
}) {
  return (
    <View style={styles.topics_box}>
      <Icon name="topics" size={size} color={color} />
      <View style={styles.topics_after_left}>
        <Icon name="topics-full" size={size - 14} color={fullColor} />
      </View>
      <View style={styles.topics_after_right}>
        <Icon name="topics-full" size={size - 14} color={fullColor} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  topics_after_left: {
    position: "absolute",
    top: 1,
    left: 0,
    zIndex: -1,
  },
  topics_after_right: {
    position: "absolute",
    bottom: 1,
    right: 1,
    zIndex: -1,
  },
});
