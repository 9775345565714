import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import FeaturedPage from "../pages/FeaturedPage";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { screenOptions } from "./MainScreenOptions";
import SpeakersStackbNavigator from "./SpeakersTabNavigator";
import TopicsTabNavigator from "./TopicsTabNavigator";
import SeriesTabNavigator from "./SeriesTabNavigator";
import LibraryTabNavigator from "./LibraryTabNavigator";
import SearchNavigator from "./SearchNavigator";
import ProfileNavigator from "./ProfileNavigator";
import PodcastNavigation from "./PodcastNavigation";

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const HomePageStack = ({ navigation }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="FeaturedPage"
        component={FeaturedPage}
        options={{ headerShown: false, title: "Featured - The Podcast Fellowship" }}
      />
    </Stack.Navigator>
  );
};

const Tabs = () => {
  return (
    <Tab.Navigator screenOptions={screenOptions}>
      <Tab.Screen
        name="HomeTab"
        component={HomePageStack}
        options={{ tabBarLabel: "Featured", headerShown: false}}
      />
      <Tab.Screen
        name="SpeakersTab"
        component={SpeakersStackbNavigator}
        options={{ headerShown: false, tabBarLabel: "Speakers" }}
      />
      <Tab.Screen
        name="TopicsTab"
        component={TopicsTabNavigator}
        options={{ headerShown: false, tabBarLabel: "Topics" }}
      />
      <Tab.Screen
        name="SeriesTab"
        component={SeriesTabNavigator}
        options={{ headerShown: false, tabBarLabel: "Courses" }}
      />
      <Tab.Screen
        name="LibraryTab"
        component={LibraryTabNavigator}
        options={{ headerShown: false, tabBarLabel: "Library" }}
      />
    </Tab.Navigator>
  );
};

const TabNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ contentStyle: { backgroundColor: "#042B59" } }}
    >
      <Stack.Screen
        name="TabsPages"
        options={{ headerShown: false }}
        component={Tabs}
      />
      <Stack.Screen
        name="SearchPages"
        component={SearchNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PodcastPages"
        component={PodcastNavigation}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ProfilePages"
        component={ProfileNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default TabNavigator;
