import React from "react";
import { View, Image, StyleSheet } from "react-native";
import HeaderRight from "../components/HeaderRight";
// import logo from '../../../assets/';
import Icon from "../components/icons/CustomIcon";
import Mic from "../components/icons/Mic";
import Topics from "../components/icons/Topics";
import Series from "../components/icons/Series";
import SvgIcon from "../components/svg/icon";

import Library from "../components/icons/Library";
import TabsHeader from "../components/TabsHeader";

export const screenOptions = ({ route }) => ({
  tabBarIcon: ({ color, focused }) => TabIcons(route, color, focused),
  tabBarActiveTintColor: "#000000",
  tabBarInactiveTintColor: "#707070",
  tabBarLabelStyle: {
    fontFamily: "SofiaPro",
  },
  tabBarStyle: {
    backgroundColor: "#FFF8E8",
    height: 90,
    paddingVertical: 5,
    paddingHorizontal: 20,
    paddingBottom: 15,
  },
  tabBarButton: ["ProfileTab"].includes(route.name)
    ? () => {
        return null;
      }
    : undefined,
  headerShown: false,
});

export const TabIcons = (route, color, focused) => {
  switch (route.name) {
    case "HomeTab":
      return (
        <View style={focused && styles.active}>
          <SvgIcon name={"FeaturedFull"} width="26" height="24"/>
        </View>
      );
    case "SpeakersTab":
      return (
        <View style={focused && styles.active}>
          <Mic color={color} size={27} />
        </View>
      );
    case "TopicsTab":
      return (
        <View style={focused && styles.active}>
          <Topics color={color} size={23} />
        </View>
      );
    case "SeriesTab":
      return (
        <View style={focused && styles.active}>
          <Series color={color} size={20} />
        </View>
      );
    case "LibraryTab":
      return (
        <View style={focused && styles.active}>
          <Library color={color} size={23} />
        </View>
      );
    case "SearchTab":
      return (
        <View style={focused && styles.active}>
          <Icon name={"search"} color={color} size={24} />
        </View>
      );
  }
  return (
    <View style={focused && styles.active}>
      <Icon name={""} color={color} size={24} />
    </View>
  );
};

function LogoTitle() {
  return (
    <Image
      source={require("../assets/logo.png")}
      style={{ width: 120, height: 30, resizeMode: "contain" }}
    />
  );
}

export const homePageOptions = ({ navigation }) => ({});

const styles = StyleSheet.create({
  active: {
    borderBottomColor: "#000",
    borderWidth: 1,
    height: 50,
    width: 50,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
  },
});
