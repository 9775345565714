import { Platform } from "react-native";
import { WebView } from "react-native-webview";

var regex_width = /width="\d+"/gi;

var regex_height = /height="\d+"/gi;

export default function EmbeddedPlayer({ __html, width, height, style, scale }) {
  if (Platform.OS === "web") {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: __html
            .replace(regex_width, `width="100%"`)
            .replace(regex_height, `height="${height}"`),
        }}
      />
    );
  } else {
    return (
      <WebView
        containerStyle={style || {}}
        scalesPageToFit={true}
        source={{
          html:
            "<style>body{background-color:#042B59; text-align: center; }span{background-color:#042B59!important;color:#fff!important}</style>" +
            `<head>
            <meta content="width=width, initial-scale=1, maximum-scale=${scale || 0.8}" name="viewport"></meta>
          </head>` + 
             __html
              .replace(regex_width, `width="${width || "100%"}"`)
              .replace(regex_height, `height="${height || 400}"`),
        }}
      />
    );
  }
}
