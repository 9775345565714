import TopicsList from "../../components/TopicsList";
import { StyleSheet, View, SafeAreaView } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { useTopicsAppPage } from "@podcasts/hooks";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";
import TabsHeader from "../../components/TabsHeader";
import FlatListWithCta from "../../components/FlatListWithCta";

export default function TopicsPage() {
  const linkTo = useLinkTo();
  const {
    data: { data: list },
    order,
    isSavedTopic,
    onNextPage,
    onSaveTopic,
    toggleOrder,
    isAuthenticated,
  } = useTopicsAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "title",
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <View style={styles.container}>
        <View style={styles.page_header}>
          <PageHeaderSort
            title="Topics"
            onPress={() => toggleOrder()}
            order={order}
          />
        </View>
        <FlatListWithCta
          data={list}
          isAuthenticated={isAuthenticated}
          renderItem={({ item, index }) => (
            <TopicsList
              {...item}
              index={index}
              totalCount={list.length}
              authenticated={isAuthenticated}
              saved={isSavedTopic(item.id)}
              onSave={() => onSaveTopic(item)}
              onPress={() => {
                linkTo(`/podcasts/topics/${item.slug}`);
              }}
            />
          )}
          numColumns={2}
          style={{ paddingHorizontal: 20 }}
          onEndReachedThreshold={0.8}
          onEndReached={onNextPage}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20
  },
  items_wrapp: {
    flex: 1,
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
});
