import { StyleSheet, View, SafeAreaView } from "react-native";
import PageHeaderSort from "../../components/PageHeaderSort/PageHeaderSort";
import TopicsList from "../../components/TopicsList";
import { useLinkTo } from "@react-navigation/native";
import { useCoursesAppPage } from "@podcasts/hooks";
import TabsHeader from "../../components/TabsHeader";
import FlatListWithCta from "../../components/FlatListWithCta";

export default function SeriesPage() {
  const linkTo = useLinkTo();
  const {
    data: { data },
    order,
    isSavedCourse,
    onNextPage,
    toggleOrder,
    onSaveCourse,
    isAuthenticated,
  } = useCoursesAppPage({
    per_page: 12,
    defaultOrder: "asc",
    order_by: "title",
  });


  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TabsHeader />
      <View style={styles.container}>
        <View style={styles.page_header}>
          <PageHeaderSort
            title="Courses"
            onPress={() => toggleOrder()}
            order={order}
          />
        </View>
        <FlatListWithCta
          data={data}
          isAuthenticated={isAuthenticated}
          renderItem={({ item, index }) => (
            <TopicsList
              {...item}
              totalCount={data.length}
              index={index}
              authenticated={isAuthenticated}
              saved={isSavedCourse(item.id)}
              onSave={() => onSaveCourse(item)}
              onPress={() => {
                linkTo(`/podcasts/courses/${item.slug}`);
              }}
            />
          )}
          style={{ paddingHorizontal: 20 }}
          numColumns={2}
          columnWrapperStyle={{ flexShrink: 1}}

          onEndReachedThreshold={0}
          onEndReached={onNextPage}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  page_header: {
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 20
  },
  items_wrapp: {
    flex: 1,
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
});
