import { ScrollView, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { mentorSelector } from "@podcasts/store/mentor";
import {
  prevStep,
  setFirstStep,
  setSecondStep,
  mentorSignUpSubmit
} from "@podcasts/store/mentor";
import  pick  from "lodash/pick";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import Success from "./Success";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import TabsHeader from "../../components/TabsHeader";
import { useEffect, useRef } from 'react';

function ApplicationProcessBase() {
  const dispatch = useDispatch();
  const { step, user, getUserSuccessMessage: successMessage } = useSelector(mentorSelector);
  const scrollRef = useRef(null)

  const commonProp = {
    onPrev: () => {
      dispatch(prevStep());
    },
  };

  useEffect(() => {
    if([2,3].includes(step)) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      scrollRef.current.scrollToPosition(0, 0)
    }
  },[step])

  const firsStepInitialValue = pick(user, [
    "email",
    "fname",
    "lname",
    "phone",
    "gender",
    "dob",
    "calendly_url"
  ]);
  const secondStepInitialValue = pick(user, [
    "country",
    "school",
    "city",
    "state",
    "bio"
  ]);

  const renderSteps = (n) => {
    switch (n) {
      case 1:
        return (
          <FirstStep
            initialValues={firsStepInitialValue}
            onNext={(values) => {
              dispatch(setFirstStep(values));
            }}
          />
        );
      case 2:
        return (
          <SecondStep
            initialValues={secondStepInitialValue}
            onNext={(values) => {
              dispatch(mentorSignUpSubmit(values));
            }}
            onPrev={(values) => {
              dispatch(setSecondStep(values));
              dispatch(prevStep());
            }}
          />
        );
      case 3:
        return <Success />;
    }
  };

  return <KeyboardAwareScrollView  extraScrollHeight={30} ref={scrollRef} style={{ paddingHorizontal: 20}}>{renderSteps(step)}</KeyboardAwareScrollView>;
}
export const MentorApplication = (props) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ApplicationProcessBase {...props} />
    </SafeAreaView>
  );
};

export const MentorWebApplication = (props) => {
  return (
    <SafeAreaView >
      <TabsHeader />
      <ApplicationProcessBase {...props} />
    </SafeAreaView>
  );
};
