import { endLoading, startLoading } from "@podcasts/store/api";
import { useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  SafeAreaView
} from "react-native";
import { WebView } from 'react-native-webview';
import { useDispatch } from "react-redux";

export default function TriviaPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoading());
  }, []);
  return (
    <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.about_us}>
            <View style={styles.title_box}>
            <Text style={styles.title}> WhatsApp</Text>
            <Text style={[styles.title, styles.orange]}>Trivia</Text>
            </View> 
        </View>
        {(Platform.OS === 'web') ? <iframe onLoad={() => {dispatch(endLoading())}} src='https://docs.google.com/forms/d/e/1FAIpQLScITtGfo62dfhn-HfwQoFsCkoIaMBcALb3T8D1yeKWkPx8u9Q/viewform?embedded=true' style={styles.web}/> :
          <WebView contentMode="mobile" onLoad={() => {dispatch(endLoading())}} scalesPageToFit={false} containerStyle={{ flex: 1 }} source={{uri: 'https://docs.google.com/forms/d/e/1FAIpQLScITtGfo62dfhn-HfwQoFsCkoIaMBcALb3T8D1yeKWkPx8u9Q/viewform?embedded=true' }} style={styles.web}/>  
        }
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  droidSafeArea: {
    paddingTop: Platform.OS === "android" ? 35 : 0,
  },
  about_us: {
    // flex: 1,
    alignItems: "center",
    width: "90%",
    alignSelf: "center",
    paddingVertical: 20,
    maxWidth: 600,
    lineHeight: 24,
  },
  web: {
    // marginTop: 20,
    // maxHeight: 200,
    flex: 1
  },
  title_box: {
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "center",
  },
  text_center: {
    textAlign: "center",
  },
  title: {
    fontSize: 26,
    lineHeight: 39,
    color: "#021c3c",
    fontWeight: "bold",
  },
  orange: {
    color: "#fcb912",
  },
  text: {
    lineHeight: 24,
    fontFamily: 'SofiaPro'
  },
  items: {
    paddingBottom: 40,
  },
  item: {
    marginTop: 35,
  },
  item_ico: {
    width: 75,
    height: 75,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff8e8",
    borderRadius: 10,
  },
  item_title: {
    fontSize: 20,
    lineHeight: 30,
    color: "#021c3c",
    marginTop: 25,
    marginBottom: 10,
    fontFamily: 'Poppins_700Bold'
  },
});
