import RenderHtml, { defaultSystemFonts } from "react-native-render-html";
import { View } from "react-native";

const systemFonts = [...defaultSystemFonts, 'SofiaPro','SofiaProBold','SofiaProRegularItalic','SofiaProMedium'];


const tagsStyles = {
  body:{
    fontFamily: 'SofiaPro',
  },
  ul: {
    paddingLeft: 15,
    margin: 0
  },
  ol: {
    paddingLeft: 15,
    margin: 0
   },
  li: {
    padding: 0,
    margin: 0,
    lineHeight: 18
  },
  p: {
    padding: 0,
    lineHeight: 14,
    margin: 3,
    fontFamily: 'SofiaPro',
  },
  strong: {
    fontFamily: 'SofiaProMedium'
  },
  '.ql-size-large': {
    color: 'red'
  },
  em: {
    fontFamily: 'SofiaProRegularItalic'
  }
};

const classesStyles = {
  'ql-size-large': {
    fontSize: 16,
    lineHeight: 16
  },
  'ql-size-huge': {
    fontSize: 20,
    lineHeight: 20
  },
  'ql-size-small': {
    fontSize: 12,
    lineHeight: 12
  }
}


function onElement(element) {

  if(element.tagName === 'em' && element.parent.name) {
    console.log(element.tagName)
    console.log(element.parent.name)
  }

}

const domVisitors = {
  onElement: onElement
};

export default NativeRenderHtml = ({ html, width, color = "#fff" }) => (
  <View>
    <RenderHtml
      contentWidth={width}
      tagsStyles={tagsStyles}
      source={{ html: `
      <div style="color: ${color};">${html}</div>` }}
      systemFonts={systemFonts}
      // domVisitors={domVisitors}
      classesStyles={classesStyles}
    />
  </View>
);
