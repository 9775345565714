import { StyleSheet, Pressable, View } from "react-native";
import SavedIndicator from "./SavedIndicator";
import BlueButton from "./BlueButton";
import PlayRandom from "./icons/PlayRandom";

export default function SavedRandomBlock({
  authenticated,
  saved,
  onPress,
  btnText,
}) {
  return (
    <View style={styles.actions_box}>
      <View>
        {authenticated && (
          <>
            {saved ? (
              <Pressable onPress={onPress}>
                <SavedIndicator text="Saved" />
              </Pressable>
            ) : (
              <BlueButton
                onPress={onPress}
                color="white"
                paddingH={15}
                paddingV={4}
                icon="bookmark-empty-blue"
                size={12}
                text={btnText}
              />
            )}
          </>
        )}
      </View>
      {/* <Pressable>
        <PlayRandom />
      </Pressable> */}
    </View>
  );
}

const styles = StyleSheet.create({
  actions_box: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
  },
});
