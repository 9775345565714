import { Platform, View } from "react-native";

const Iframe = (props) => {
  let _Frame;
  if (Platform.OS === "web") {
    _Frame = require("./WebIframe.js").default;
  } else {
    _Frame = require("./NativeIframe.js").default;
  }
  return (
    <View>
      <_Frame {...props}></_Frame>
    </View>
  );
};
export default Iframe;
