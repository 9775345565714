import { usePodcastTopicPage } from "@podcasts/hooks";
import TopicBase from "./TopicBase";

const PodcastTopicPage = ({
  route: {
    params: { slug }
  },
  navigation
}) => {
  const hook = usePodcastTopicPage(slug);

  return <TopicBase {...hook} slug={hook?.data?.slug} backLinkTo={`/podcasts/${slug}`} navigation={navigation} />;
};

export default PodcastTopicPage;
