import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import CustomRadioButton from "../../components/CustomRadioButton";
import { secondStepInitialValue } from "@podcasts/store/application";
import { useSelector } from "react-redux";
import { secondApplicationStepSchema } from "@podcasts/schemas";
import { PoppinsBoldText } from "../../components/PopinsText";
import Title from '../../components/Title';

const radioButtons = [
  {
    id: "1",
    label: "Yes",
    value: true,
  },
  {
    id: "2",
    label: "No",
    value: false,
    selected: true,
  },
];

export default function SecondStep({ onPrev, onNext }) {
  const initialValues = useSelector(secondStepInitialValue);

  return (
      <View style={styles.login}>
        <View style={styles.step_number}>
          <Text style={styles.step_number_text}>2</Text>
        </View>
        <Title source={ require('../../assets/titles/application_process_2.jpg')}/>

        {/* <PoppinsBoldText style={styles.step_title}>
           Tell us about your
        </PoppinsBoldText>
         <PoppinsBoldText style={styles.pf_title}>
          religious beliefs
        </PoppinsBoldText> */}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={secondApplicationStepSchema}
          onSubmit={(values) => {
            onNext(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <View style={styles.form}>
              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("religion_born")}
                  onBlur={handleBlur("religion_born")}
                  value={values?.religion_born}
                  error={
                    errors?.religion_born && touched.religion_born
                      ? errors.religion_born
                      : null
                  }
                  label={"What religion were you born into? *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("religion_identify_with")}
                  onBlur={handleBlur("religion_identify_with")}
                  value={values.religion_identify_with}
                  error={
                    errors.religion_identify_with &&
                    touched.religion_identify_with
                      ? errors.religion_identify_with
                      : null
                  }
                  label={"What religion do you identify with today? *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("father_religion")}
                  onBlur={handleBlur("father_religion")}
                  value={values.father_religion}
                  error={
                    errors.father_religion && touched.father_religion
                      ? errors.father_religion
                      : null
                  }
                  label={"What religion was your father born into? *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("mother_religion")}
                  onBlur={handleBlur("mother_religion")}
                  value={values.mother_religion}
                  error={
                    errors.mother_religion && touched.mother_religion
                      ? errors.mother_religion
                      : null
                  }
                  label={"What religion was your mother born into? *"}
                />
              </View>

              <View style={styles.form_control}>
                <CustomInput
                  onChangeText={handleChange("jewish_story")}
                  onBlur={handleBlur("jewish_story")}
                  value={values.jewish_story}
                  minHeight={90}
                  numberOfLines={8}
                  error={
                    errors.jewish_story && touched.jewish_story
                      ? errors.jewish_story
                      : null
                  }
                  label={"In 1-2 sentences share your Jewish story. *"}
                  multiline={true}
                />
              </View>

              <View style={styles.form_control}>
                <CustomRadioButton
                  layout="row"
                  value={values.jewish_education}
                  label={"Have you ever had any formal Jewish education? *"}
                  options={radioButtons}
                  error={
                    errors.jewish_education && touched.jewish_education
                      ? errors.jewish_education
                      : null
                  }
                  onChange={(value) => setFieldValue("jewish_education", value)}
                ></CustomRadioButton>
              </View>
              {values?.jewish_education && (
                <View style={styles.form_control}>
                  <CustomInput
                    onChangeText={handleChange("jewish_education_description")}
                    onBlur={handleBlur("jewish_education_description")}
                    value={values.jewish_education_description}
                    label={"If so, please describe:"}
                    multiline={true}
                    minHeight={90}
                    numberOfLines={8}
                  />
                </View>
              )}
              <View style={styles.next_step}>
                <CustomButton
                  decorator
                  name={"Next step"}
                  color="blue"
                  onPress={handleSubmit}
                />
              </View>

              <View style={styles.prev_step}>
                <TouchableOpacity onPress={onPrev}>
                  <Text style={styles.prev_step_text}>Previous step</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Formik>
      </View>
  );
}

const styles = StyleSheet.create({
  login: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 55,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 18,
    color: "#000",
    fontWeight: "normal",
  },
  step_title: {
    fontWeight: "bold",
    fontSize: 26,
    color: "#021C3C",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  pf_title: {
    color: '#FCB912',
    fontSize: 26,
  },
  step_subtitle: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
  },
  form: {
    width: "100%",
  },
  form_control: {
    width: "100%",
    marginTop: 20,
  },
  next_step: {
    marginTop: 40,
  },
  prev_step: {
    marginTop: 40,
  },
  prev_step_text: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 18,
    fontFamily: "SofiaPro"
  },
  
});
