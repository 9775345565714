import { StyleSheet, View, Text, Image, SafeAreaView, Platform } from "react-native";
import Icon from "../../components/icons/CustomIcon";
import SavedRandomBlock from "../../components/SavedRandomBlock";
import PodcastList from "../../components/PodcastList";
import { PoppinsBoldText } from "../../components/PopinsText";
import { useLayoutEffect } from 'react';

export default function SpeakerBase(props) {
  const {
    data: speaker = {},
    isSavedSpeaker,
    onSaveSpeaker,
    isAuthenticated,
    slug,
    navigation
  } = props;

  useLayoutEffect(() => {
    if(speaker) {
      navigation.setOptions({
        headerTitleStyle: { display: 'none'},
        title:  Platform.OS === 'web' ? `${speaker?.title ? `${speaker.title} ${speaker.name} - ` : ''}The Podcast Fellowship` : ''
      })
    }
  },[speaker])

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.page_header}>
        <View style={styles.top_box}>
          <View style={styles.avatar_wrapp}>
            <View style={styles.avatar_box}>
              {speaker?.image ? (
                <Image source={{ uri: speaker?.image }} style={styles.avatar} />
              ) : (
                <Icon size={24} color="#fff" name="mic-empty-dark" />
              )}
            </View>
            <View style={styles.avatar_after}></View>
          </View>
          <View style={styles.info}>
            <PoppinsBoldText style={styles.name_speaker}>
              {speaker?.title} {speaker?.fname} {speaker?.lname}
            </PoppinsBoldText>
            <Text style={styles.podcast}>
              {speaker?.podcasts_count} podcast
              {speaker?.podcasts_count > 1 && "s"}
            </Text>
          </View>
        </View>
      </View>
      <SavedRandomBlock
        authenticated={isAuthenticated}
        saved={isSavedSpeaker(speaker?.id)}
        onPress={() => onSaveSpeaker()}
        btnText="Save speaker"
      />
      {slug && <PodcastList params={{ speaker_slug: [slug] }}></PodcastList>}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },
  player: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 2,
  },
  page_header: {
    marginTop: 16,
    paddingHorizontal: 20,
    marginBottom: 15,
  },
  top_box: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar_wrapp: {
    position: "relative",
    marginRight: 18,
  },
  avatar_box: {
    width: 44,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 22,
    backgroundColor: "#042B59",
    overflow: "hidden",
  },
  avatar: {
    width: "100%",
    height: "100%",
  },
  avatar_after: {
    position: "absolute",
    width: 44,
    height: 44,
    borderRadius: 22,
    borderWidth: 1,
    borderColor: "#000",
    left: 2,
    top: 2,
    zIndex: -1,
  },
  name_speaker: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
    marginBottom: 4,
  },
  podcast: {
    color: "#707070",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'SofiaPro'
  },
  items_wrapp: {
    marginTop: 15,
    paddingBottom: 100,
  },
});
