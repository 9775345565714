import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
} from "react-native";
import Bookmark from "./icons/Bookmark";
import { LinearGradient } from 'expo-linear-gradient';
import FeaturedItem from './FeaturedItem';

export default function FeaturedTopic({
  image,
  saved = false,
  onFeatureSave,
  authenticated,
  podcastSubTitle,
  subTitle,
  ...rest
}) {
  return (
      <FeaturedItem {...rest} subTitle={podcastSubTitle} authenticated={authenticated}>
        <View style={{ position: 'relative'}}>

          <ImageBackground
            source={{ uri: image }}
            resizeMode="cover"
            style={styles.topic_header_img}
          >
        <LinearGradient
          colors={['transparent', 'rgba(2, 28, 60, .5)']}
          style={styles.gradient}>
            <Text style={styles.topic_header_text}>{subTitle}</Text>
            {authenticated && (
              <Bookmark
                size={20}
                color="#fff"
                empty={saved}
                onPress={() => onFeatureSave()}
              />
            )}
                 </LinearGradient>
          </ImageBackground>
          
          <View style={styles.orange_decor}></View>
        </View>
        </FeaturedItem>
  );
}

const styles = StyleSheet.create({
  orange_decor: {
    width: "94%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "3%",
    bottom: -4,
    zIndex: -1,
  },
  topic_header_img: {
    flexGrow: 1,
    height: 100,
    overflow: "hidden",
    borderRadius: 10,
    backgroundColor: "grey",
  },
  topic_header: {
    paddingHorizontal: 20,
    marginBottom: 9,
  },
  topic_header_text: {
    fontSize: 16,
    lineHeight: 21,
    color: "#fff",
    fontFamily: "Poppins_600SemiBold",
  },
  view_all: {
    flexDirection: "row",
    alignItems: "center",
  },
  gradient: { 
    height: 65, 
    width: '100%',
    position: 'absolute', 
    left: 0, 
    bottom: 0,
    zIndex: 2,
    paddingHorizontal: 15,
    paddingTop: 20,
    paddingBottom: 15,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between" }
});
