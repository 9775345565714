import { StyleSheet, ImageBackground, View, Text } from "react-native";
import { Header } from "@react-navigation/elements";
import HeaderRight from "../components/HeaderRight";
import BackButton from "../components/BackButton";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { PoppinsBoldText } from "../components/PopinsText";

export function TopicHeader({ image, podcasts_count, title, backLinkTo }) {
  const insets = useSafeAreaInsets();
  return (
    <ImageBackground
      source={{ uri: image }}
      resizeMode="cover"
      style={[styles.page_header, { paddingTop: insets.top }]}
    >
      <Header
        headerStyle={{ height: 60, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        layout={{ height: 100, width: 400 }}
        headerRight={() => <View style={{ marginRight: 20}}><HeaderRight searchIconColor={"white"} /></View>}
        headerLeft={() => (<View style={{ marginHorizontal: 18}}>
          <BackButton backLinkTo={backLinkTo} backColor="white" skipCheck/>
        </View>
        )}
      />

      <View style={styles.topic_info}>
        <PoppinsBoldText style={styles.topic_category}>{title}</PoppinsBoldText>
        <Text style={styles.topic_podcast}>
          {podcasts_count} podcast{podcasts_count > 1 && "s"}
        </Text>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  page_header: {
    height: 275,
    marginBottom: 20,
  },
  topic_bg: {
    width: "100%",
    height: "100%",
  },
  topic_info: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 25,
    justifyContent: "flex-end",
  },
  topic_category: {
    fontSize: 20,
    lineHeight: 27,
    fontWeight: "bold",
    marginBottom: 4,
    color: "#fff",
  },
  topic_podcast: {
    fontSize: 12,
    lineHeight: 14,
    color: "#fff",
    fontFamily: 'SofiaPro'
  },
});
