import { StyleSheet, View, Text, Image } from "react-native";
import Icon from "./icons/CustomIcon";

export default function SpeakerIconBlock({ fname, lname, image, title }) {
  return (
    <View style={styles.speaker_box}>
      <View style={styles.avatar_wrapp}>
        <View style={styles.avatar_box}>
          {image ? (
            <Image source={{ uri: image }} style={styles.avatar} />
          ) : (
            <Icon size={20} color="#000" name="mic-empty-dark" />
          )}
        </View>
        <View style={styles.avatar_after}></View>
      </View>
      <View style={styles.info}>
        <Text style={styles.name_speaker}>
          {title} {fname} {lname}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  speaker_box: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar_wrapp: {
    position: "relative",
    marginRight: 18,
  },
  avatar_box: {
    width: 34,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 17,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  avatar: {
    width: "100%",
    height: "100%",
  },
  avatar_after: {
    position: "absolute",
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: "#FCB912",
    left: 2,
    top: 2,
    zIndex: -1,
  },
  name_speaker: {
    color: "#fff",
    fontSize: 12,
    lineHeight: 16,
    fontFamily: "SofiaPro",
  },
});
