import {
  StyleSheet,
  Text,
  View,
  Platform,
  SafeAreaView,
  ImageBackground,
} from "react-native";
import CustomButton from "../components/CustomButton";
import { useLinkTo } from "@react-navigation/native";
import { PoppinsBoldText } from "../components/PopinsText";
import { LinearGradient } from 'expo-linear-gradient';

export default function GetStartedPage({ navigation }) {
  const linkTo = useLinkTo();
  return (
    <SafeAreaView style={styles.droidSafeArea}>
      <View style={styles.getstarted}>
        <View style={styles.getstarted_img_wrapp}>
          <ImageBackground  style={{ height: '100%', width: '100%'}} imageStyle={styles.image_bg} resizeMode="contain" source={require('../assets/start_page_image.png')}>
          <LinearGradient colors={['transparent','#FFFFFF']}  style={styles.gradient}/> 
        </ImageBackground>
        </View>
        <View style={styles.text_container}>
          <PoppinsBoldText style={styles.text_yellow}>Listen.</PoppinsBoldText>
          <PoppinsBoldText style={styles.text_blue}>
            {" "}
            Share. Earn. (Repeat)
          </PoppinsBoldText>
        </View>

        <Text style={styles.text_apply}>
          Apply today to receive $15 for each podcast you listen to and review with your podcast mentor. After you have recieved a confirmation email you can start to explore, enjoy and discover our podcasts.
        </Text>

        <View style={{ width: "100%", marginTop: 15 }}>
          <View style={styles.button}>
            <CustomButton
              name={"Apply now"}
              color={"blue"}
              onPress={() => {
                linkTo("/application");
              }}
            />
          </View>

          <View style={styles.button}>
            <CustomButton
              name={"Mentor apply"}
              color={"blue"}
              onPress={() => {
                linkTo("/mentor-apply");
              }}
            />
          </View>

          <View style={styles.button}>
            <CustomButton
              name={"Login"}
              color={"white"}
              onPress={() => {
                linkTo("/login");
                // navigation.push("Login");
              }}
            />
          </View>
        </View>
        <Text
          style={styles.text_browse}
          onPress={() => {
            linkTo(`/podcasts/featured`);
          }}
        >
          Browse as a Guest
        </Text>
      </View>
      {/* </ScrollView> */}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  droidSafeArea: {
    flex: 1,
    paddingTop: Platform.OS === "android" ? 35 : 0,
  },

  getstarted: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
    alignSelf: "center",
    paddingBottom: 40,
    maxWidth: 600,
  },

  text_yellow: {
    fontSize: 22,
    textAlign: "center",
    color: "#FCB912",
  },
  text_blue: {
    fontSize: 22,
    textAlign: "center",
    color: '#021C3C'
  },
  text_container: {
    flexDirection: "row",
  },
  getstarted_img_wrapp: {
    flexDirection: "row",
    width: "100%",
    height: "50%",
    maxWidth: 500,
    borderRadius: 20,
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "flex-start",
    overflow: "hidden",
    marginTop: 10,
    marginBottom: 20,
  },
  image_bg: {
    // width : '100%',
    // height: '100%',
    position: "relative",
    marginBottom: 46,
  },
  gradient: {
    height: "40%",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  getstarted_img_col: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 136,
    marginRight: 10,
    alignSelf: "center",
  },
  main_image: {
    width: "100%",
    height: 136,
    marginBottom: 10,
  },

  text_apply: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    color: "#000000",
    marginTop: 15,
    fontFamily: "SofiaPro",  },

  text_skip: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "#143474",
    marginTop: 20,
  },

  button: {
    marginBottom: 15,
    width: "100%",
  },
  text_browse: {
    fontSize: 15,
    color: "#042B59",
    fontWeight: "500",
    fontFamily: 'SofiaProMedium'
  },
});
