import { StyleSheet, Text, View } from "react-native";

export default function SavedIndicator({ text }) {
  return (
    <View style={styles.saved_box}>
      <Text style={styles.saved_text}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  saved_box: {
    paddingVertical: 9,
    paddingHorizontal: 15,
    alignItems: "center",
    backgroundColor: "#042B59",
    borderRadius: 1000,
    minWidth: 120,
  },
  saved_text: {
    color: "#fff",
    fontSize: 12,
    lineHeight: 12,
    fontFamily: "SofiaProMedium",
  },
});
