import DateTimePickerModal from "react-native-modal-datetime-picker";
import { StyleSheet, Text, View, Pressable } from "react-native";
import { useState } from "react";
import moment from "moment";

export default function NativeDatePicker({
  error,
  label,
  value = new Date(),
  onChange,
  ...rest
}) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisible(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };

  const handleConfirm = (date) => {
    onChange(date);
    hideDatePicker();
  };

  return (
    <View style={styles.custominput}>
      {label && <Text style={styles.text_name}>{label}</Text>}
      <View style={{ position: 'relative'}}>
       <Pressable onPress={showDatePicker} style={{...styles.input, borderColor: error ? "red" : "black",}}>
        <Text style={{ color: value ? 'black' : '#7E7E7E' }}>{value ? moment(value).format("DD / MM / YY") : "mm / dd / yyyy"}</Text>
      </Pressable>
      <View style={styles.shadow}></View>
      </View>

      <DateTimePickerModal
        date={selectedDate}
        isVisible={datePickerVisible}
        mode="date"
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
        {...rest}
      />
      { error && <Text style={styles.text_error}>{error}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  custominput: {
    width: "100%",
    margin: 0,
  },
  textarea: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginBottom: 4,
    textAlignVertical: "top",
  },

  input: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "black",
    height: 45,
    width: "100%",
    paddingHorizontal: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
    marginBottom: 4,
    // alignItems: 'center',
    justifyContent: "center",
  },
  shadow: {
    position: "absolute",
    width: "90%",
    height: 20,
    backgroundColor: "#F9BF34",
    alignSelf: "center",
    borderRadius: 10,
    position: "absolute",
    left: "5%",
    bottom: -1.5,
    zIndex: -1,
  },

  text_name: {
    marginBottom: 5,
    fontSize: 13,
    lineHeight: 23,
    fontWeight: "normal",
    fontFamily: "SofiaPro",
  },
  text_error: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: "normal",
    color: "red",
    marginTop: 5,
    marginLeft: '5%',
  },
});
