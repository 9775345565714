import { ImageBackground, Text, View, StyleSheet } from "react-native";

const HowItWorksItem = (props) => {
  const { text, number, img, style } = props;
  return (
    <View style={[style, styles.container]}>
        <Text style={styles.number}>{number}</Text>
      <ImageBackground style={styles.img_bg} resizeMode="cover" source={img}>
      </ImageBackground>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export default HowItWorksItem;

const styles = StyleSheet.create({
  container: { 
    alignItems: "center",
    position: 'relative'
   },
  img_bg: {
    height: 150,
    width: 253,
    backgroundColor: "#fff8e8",
    marginBottom: 20,
    borderRadius: 10,
    overflow: 'hidden',
    borderColor: 'black',
    borderWidth: 1
  },
  number: {
    width: 23,
    height: 23,
    backgroundColor: "white",
    borderRadius: 23 / 2,
    borderColor: "#021c3c",
    borderWidth: 1,
    marginLeft: -10,
    marginTop: -10,
    textAlign: "center",
    lineHeight: 22,
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    zIndex: 3
  },
  text: {
    fontSize: 15,
    lineHeight: 25,
    fontFamily: "SofiaPro",
    width: 253,
    textAlign: "center",
  },
});
