import { StyleSheet, View } from "react-native";
import Icon from "./CustomIcon";

export default function CircleDoneBold({ color = "#fff", bg = "#FCB912" }) {
  return (
    <View style={[styles.done_box, { backgroundColor: bg }]}>
      <Icon
        name="done-bold"
        size={7}
        color={color}
        style={{ fontWeight: "bold" }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  done_box: {
    width: 16,
    height: 16,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
});
