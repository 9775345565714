import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Platform
} from "react-native";
import CustomButton from "../../components/CustomButton";
import { useLinkTo } from "@react-navigation/native";
import { PoppinsBoldText } from "../../components/PopinsText";
import Title from '../../components/Title';

export default function Success() {
  const linkTo = useLinkTo();

  return (
    <ScrollView>
      <View style={styles.success}>
        <View style={styles.step_number}>
          <Text style={styles.step_number_text}>✓</Text>
        </View>
        <Title style={{ marginBottom: 20 }}source={ require('../../assets/titles/application_process_5.jpg')}/>

       {/* <View>
          <PoppinsBoldText style={styles.step_title}>
            Thank you for
          </PoppinsBoldText>
          <PoppinsBoldText style={styles.pf_title}>
            your application
          </PoppinsBoldText>
       </View> */}

        <Text style={styles.success_text}>
          While we do our best to answer your queries quickly, it may take about
          10 hours to receive a response from us during peak hours.
        </Text>

        <Text style={styles.success_text}>
          In the meanwhile you can still browse our library of podcasts:
        </Text>

        <View style={styles.next_step}>
          <CustomButton
            decorator
            name={"View our podcasts"}
            color="blue"
            onPress={() => {
              linkTo(`/podcasts/featured`);
            }}
          />
        </View>

       { Platform.OS === 'web' &&  <View style={styles.prev_step}>
          <TouchableOpacity onPress={() => {  linkTo(`/`);}}>
            <Text style={styles.prev_step_text}>or go back to home</Text>
          </TouchableOpacity>
        </View> }
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  success: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    width: "84%",
    alignSelf: "center",
    paddingTop: 40,
    paddingBottom: 50,
  },
  step_number: {
    width: 56,
    height: 56,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 28,
    borderWidth: 1,
    borderColor: "#000",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 56,
  },
  step_number_text: {
    fontSize: 22,
    color: "#FCB912",
    fontWeight: "normal",
  },
  step_title: {
    fontWeight: "bold",
    fontSize: 26,
    color: "#000",
    textAlign: "center",
    lineHeight: 39,
    marginTop: 30,
  },
  success_text: {
    fontWeight: "normal",
    fontSize: 16,
    color: "#000",
    textAlign: "center",
    lineHeight: 26,
    marginTop: 15,
    marginBottom: 10,
  },
  next_step: {
    width: 270,
    marginTop: 15,
  },
  prev_step: {
    marginTop: 25,
  },
  prev_step_text: {
    textAlign: "center",
    fontSize: 18,
    lineHeight: 18,
    fontWeight: "500"
  },
  pf_title: {
    color: '#FCB912',
    fontSize: 26,
  },
});
