import { StyleSheet, View, Text, FlatList, useWindowDimensions } from "react-native";
import PodcastWeb from "./PodcastWeb";
import { useCallback } from "react";
import { useLinkTo } from "@react-navigation/native";
import { PoppinsBoldText } from "../components/PopinsText";
import { Carousel } from './Carousel';

const FeaturedPodcasts = ({
  items = [],
  saved_ids = [],
  onSavePodcast,
  authenticated,
}) => {
  const { width } = useWindowDimensions();

  const linkTo = useLinkTo();


  const isSaved = useCallback(
    (id) => {
      return saved_ids.includes(id);
    },
    [saved_ids]
  );


  return (
    <View style={{ paddingLeft: 20, height: 225 }}>
      <PoppinsBoldText style={styles.text_title}>
        Featured Podcasts
      </PoppinsBoldText>
      <Carousel items={items}>
        {items.map((item,index) =>  <View style={{ width: width - 20 }} key={index}><PodcastWeb
              saved={isSaved(item.id)}
              key={index}
              item={item}
              authenticated={authenticated}
              onSave={() => {
                onSavePodcast(item);
              }}
              onPress={() => {
                linkTo(`/podcasts/${item.slug}`);
              }}
            ></PodcastWeb></View>)}
      </Carousel>
    </View>
  );
};

export default FeaturedPodcasts;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    maxWidth: 600,
    alignSelf: "center",
  },

  block_user: {
    width: "100%",
    paddingHorizontal: 20,
  },

  block_podcasts: {
    width: "100%",
    marginTop: 25,
    paddingLeft: 20,
  },

  block_topics: {
    width: "100%",
    marginTop: 20,
    paddingLeft: 20,
  },

  block_speakers: {
    width: "100%",
    marginTop: 20,
    paddingLeft: 20,
  },
  text_title: {
    color: "#021C3C",
    fontSize: 16,
    lineHeight: 27,
    marginBottom: 10,
  },
});
