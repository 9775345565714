import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SearchPage from "../pages/Search/SearchPage";
// import SearchInput from "../components/SearchInput";
import BackButton from "../components/BackButton";
import { Text, Platform, View, Dimensions } from "react-native";

const windowWidth = Dimensions.get('window').width;

const Stack = createNativeStackNavigator();

export const homePageOptions = ({ navigation }) => ({
  headerStyle: {
    display: "flex",
    backgroundColor: "white",
    justifyContent: "flex-end",
    backgroundColor: "white",
  },
  headerTitle: (props) => <Text style={{}}></Text>,
  headerLeft: (props) => (
    <BackButton {...props} replace={"TabsPages"} navigation={navigation} />
  ),
  headerRight: (props) => <View style={{ width: windowWidth - Platform.OS === 'web' ? 393 : 400}}><SearchInput /></View>,
});

const SearchNavigator = ({ navigation }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SearchPage"
        component={SearchPage}
        options={{
          title: "",
          headerBackVisible: false,
          headerShadowVisible: false,
          headerBackTitleVisible: false,
          headerTitleStyle: {
          margin: 0
          },
          headerLeft: (props) => 
            <View style={{ marginLeft: Platform.OS === 'web' ? 20 : 0, marginTop: 5}}><BackButton {...props} backLinkTo={"/podcasts/featured"} /></View>
        }}
      />
    </Stack.Navigator>
  );
};

export default SearchNavigator;
