import { StyleSheet, Text, View, Image, TouchableOpacity, Pressable } from "react-native";
import Icon from "./icons/CustomIcon";

export default function CustomButton({
  color,
  onPress,
  name,
  disabled = false,
  customIcon = "",
  decorator,
  fontSize = 18
}) {
  return (
    <Pressable
      style={[styles.button, styles[disabled ? "grey" : color]]}
      disabled={disabled}
      onPress={onPress}
    >
      <Text style={[{ fontSize, fontFamily: "SofiaPro", textAlign: "center", marginRight: 16 }, styles[color]]}>
        {name}
      </Text>
      {customIcon ? (
        <View style={[styles.btn_ico, { borderWidth: 0 }]}>
          <Icon
            name={customIcon}
            size={30}
            color={color === "white" ? "#042B59" : "#fff"}
          />
        </View>
      ) : (
        <View style={[styles.btn_ico, , styles[color]]}>
          <Icon
            name="chevron-right-light"
            size={12}
            color={color === "white" ? "#042B59" : "#fff"}
          />
        </View>
      )}
       { decorator && <View style={styles.btn_decor}></View>}

    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    height: 55,
    width: "98%",
    borderColor: "#042B59",
    borderWidth: 1,
    alignSelf: "center",
    position: 'relative',
    paddingRight: 15
  },
  blue: {
    backgroundColor: "#042B59",
    color: "white",
    borderColor: "#fff",
  },
  white: {
    backgroundColor: "white",
    color: "#042B59",
    borderColor: "#042B59",
  },
  grey: {
    backgroundColor: "grey",
    color: "#042B59",
    borderColor: "#042B59",
  },
  btn_ico: {
    position: "absolute",
    right: 13,
    top: 12,
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 1,
  },
  btn_decor: {
    width: "100%",
    height: 55,
    borderColor: "#042B59",
    borderWidth: 1,
    borderRadius: 50,
    position: "absolute",
    right: -4,
    top: 4,
    zIndex: -1,
  },
});
