import { createIconSetFromIcoMoon } from "@expo/vector-icons";
import { View } from "react-native";

const Icon = createIconSetFromIcoMoon(
  require("../../resources/fonts/selection.json"),
  "IcoMoon",
  "icomoon.ttf"
);

export default Icon;
