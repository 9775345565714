import { StyleSheet, Text, Pressable, View, Platform } from "react-native";
import Icon2 from "../icons/CustomIcon2";
import { PoppinsBoldText } from "../PopinsText";
import Icon from "../../components/svg/icon";

export default function PageHeaderSort({ title, onPress, order }) {
  return (
    <View style={styles.header_wrapp}>
      <PoppinsBoldText style={styles.title}>{title}</PoppinsBoldText>

      <Pressable
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        onPress={onPress}
      >
        <Text style={styles.sort_text} >Sort </Text>

        <View style={[styles.arrow, { transform: [{ rotate: order === "desc" ?  "0deg"  : "180deg"}]}]}></View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  header_wrapp: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: "#021C3C",
    fontSize: 20,
    lineHeight: 27,
  },
  arrow: {
    width: 0,
    height: 0,
    // borderTopWidth: 5,
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
    borderLeftWidth: 5,
    borderRightColor: "transparent",
    borderRightWidth: 5,
    borderBottomColor: "#021C3C",
    borderBottomWidth: 7,
    marginTop: Platform.OS === 'web' ? 0 : 2

  },
  sort_text: {
    fontFamily: 'SofiaPro',
    color: '#021C3C'
    // fontWeight: "500",
    // fontSize: 13
  }
});
