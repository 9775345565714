import FeaturedItem from './FeaturedItem';
import BookmarkItem from './BookmarkItem';

  export default function FeaturedSpeaker({
    image,
    saved = false,
    onFeatureSave,
    authenticated,
    podcasts_count,
    fname,
    lname,
    subTitle,
    ...rest
  }) {
    return (
        <FeaturedItem {...rest} subTitle={'topic'}  authenticated={authenticated}>
            <BookmarkItem
              fname={fname} 
              lname={lname} 
              saved={saved}
              onSave={onFeatureSave}
              podcasts_count={rest.podcasts.length}
              image={image} 
              authenticated={authenticated}/>
          </FeaturedItem>
    );
}