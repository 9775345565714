import { Text } from 'react-native';
import { useLinkTo } from "@react-navigation/native";
import { useCallback } from "react";
import { usePodcastsLibrary } from "../hooks/index.app";
import { useSearch } from "@podcasts/hooks";
import PodcastListItem from "./PodcastListItem";
import FlatListWithCta from "../components/FlatListWithCta";

export default function PodcastList({ params = {}, noResult, podcastSubTitle = 'topic' }) {
  const linkTo = useLinkTo();
  const { isSavedPodcast, togglePodcastLibrary, isAuthenticated } =
    usePodcastsLibrary();
  const {
    data: { data: podcasts },
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    isFetching,
    isRefetching,
  } = useSearch({ ...params, page: 1 });

  const onSavePodcast = useCallback((item) => {
    togglePodcastLibrary(item);
  }, [isAuthenticated]);

  const onEndReached = useCallback(() => {
    if (!isFetchingPreviousPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingPreviousPage, isFetchingNextPage]);


  console.log(isAuthenticated,'isAuthenticated2')
  return (
    <FlatListWithCta
      data={podcasts}
      ListHeaderComponent={noResult && podcasts.length === 0 && !isFetching ? <Text style={{ flex: 1, textAlign: 'center', padding: 20, fontFamily: 'Poppins_500Medium'}}>No Results. Try a new search</Text> : <></>}
      isAuthenticated={isAuthenticated}
      renderItem={({ item, index }) => (
        <PodcastListItem
          index={index}
          subTitle={podcastSubTitle}
          isAuthenticated={isAuthenticated}
          key={index}
          onPress={() => {
            linkTo(`/podcasts/${item.slug}`);
          }}
          onSave={() => onSavePodcast(item)}
          saved={isSavedPodcast(item.id)}
          item={item}
        />
      )}
      ListFooterComponentStyle={{ marginHorizontal: 20 }}
      onEndReachedThreshold={0.9}
      onEndReached={onEndReached}
    />
  );
}
